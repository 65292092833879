import { useRef, useState } from "react"
import {
  AlertDialog,
  AlertDialogLabel,
  AlertDialogDescription,
} from "@reach/alert-dialog"
import { func, shape, string, bool } from "prop-types"
import { Button } from "@planningcenter/doxy-web"
import { Icon } from "source/shared/components"
import { Elements } from "@stripe/react-stripe-js"
import { useStripeConfig } from "source/payments/hooks"
import { PaymentElementLoading } from "./PaymentElementLoading"
import { AddPaymentMethodForm } from "./AddPaymentMethodForm"
import { api } from "source/registrations/api/client"
import { PAYMENT_URLS } from "source/payments/utils"

export const AddPaymentMethodDialog = ({
  onCancel,
  onConfirm,
  onSuccess,
  stripe: stripeProps,
  orgId,
}) => {
  const cancelRef = useRef(null)

  const [addPaymentMethodError, setAddPaymentMethodError] = useState(null)
  const [paymentMethodSaving, setPaymentMethodSaving] = useState(null)

  const {
    stripe,
    stripeOptions,
    paymentElementOptions,
    loading: stripeLoading,
  } = useStripeConfig(stripeProps)

  const submitPaymentMethodToStripe = async (elements) => {
    if (!stripe || !elements) return
    setAddPaymentMethodError(null)
    setPaymentMethodSaving(true)

    try {
      const { setupIntent } = await stripe.confirmSetup({
        elements,
        redirect: "if_required",
        confirmParams: {
          payment_method_data: {
            allow_redisplay: "always",
            metadata: {
              planning_center_source_app: "Church Center",
              planning_center_organization_id: orgId,
            },
          },
        },
      })
      setAddPaymentMethodError(null)
      try {
        await updateCustomerPaymentMethod(setupIntent.id)
        onSuccess()
        onConfirm()
      } catch {
        setAddPaymentMethodError(
          "We encountered a problem while attempting to save your payment method.",
        )
      }
    } catch {
      setAddPaymentMethodError(
        "Stripe encountered a problem while attempting to save your payment method.",
      )
    } finally {
      setPaymentMethodSaving(false)
    }
  }

  const updateCustomerPaymentMethod = async (
    stripe_setup_intent_identifier,
  ) => {
    await api.post(PAYMENT_URLS.STORE_PAYMENT_METHOD, {
      data: {
        type: "PaymentMethod",
        attributes: {
          stripe_setup_intent_identifier,
        },
      },
    })
  }

  return (
    <AlertDialog leastDestructiveRef={cancelRef}>
      <AlertDialogLabel>
        <div className="d-f jc-sb ai-c">
          <span>Add payment method</span>{" "}
          <Button
            text={<Icon className="fs-3 c-tint3" symbol="general#x" />}
            ref={cancelRef}
            variant="naked"
            size="sm"
            onClick={onCancel}
          />
        </div>
      </AlertDialogLabel>
      <AlertDialogDescription>
        {stripeLoading ? (
          <PaymentElementLoading />
        ) : (
          <Elements stripe={stripe} options={stripeOptions}>
            <AddPaymentMethodForm
              paymentElementOptions={paymentElementOptions}
              onSubmit={submitPaymentMethodToStripe}
              saving={paymentMethodSaving}
              error={addPaymentMethodError}
            />
          </Elements>
        )}
      </AlertDialogDescription>
    </AlertDialog>
  )
}

AddPaymentMethodDialog.propTypes = {
  onCancel: func,
  onConfirm: func.isRequired,
  onSuccess: func.isRequired,
  stripe: shape({
    publishable_key: string.isRequired,
    account_identifier: string.isRequired,
    test_mode: bool.isRequired,
  }).isRequired,
  orgId: string.isRequired,
}
