import { bool } from "prop-types"
import { Link } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"
import { Heading } from "@planningcenter/doxy-web"
import { MyProfileLinks } from "source/myChurchCenter/Profile/MyProfileLinks"
import { useApiRead } from "source/shared/SessionApiResource"
import { Card } from "source/shared/components"
import { useFlipperFeatureEnabled } from "source/shared/flipperFeatures"

ActivityAndInvolvement.propTypes = {
  hasGiving: bool.isRequired,
  hasGroups: bool.isRequired,
  hasRegistrations: bool.isRequired,
}

export default function ActivityAndInvolvement({
  hasGiving,
  hasGroups,
  hasRegistrations,
}) {
  const ROLLOUT_my_notes = useFlipperFeatureEnabled("ROLLOUT_my_notes")

  const { data: totalNoteCount } = useUserNoteCount()
  const { data: channels } = useChannels()
  const hasChannelWithNotesEnabled = channels?.data?.some(
    (channel) => channel.attributes.enabled_formats.sermon_notes,
  )
  const shouldShowMyNotes =
    ROLLOUT_my_notes && (totalNoteCount > 0 || hasChannelWithNotesEnabled)

  if (!hasGiving && !hasRegistrations && !hasGroups && !shouldShowMyNotes)
    return null

  function generateLinks() {
    const registrationLinks = hasRegistrations
      ? [
          {
            to: "/registrations/profile",
            children: "My registrations",
            icon: "registrations",
          },
        ]
      : []

    const givingLinks = hasGiving
      ? [
          {
            to: "/giving/profile",
            children: "My giving",
            icon: "giving",
          },
        ]
      : []

    const myNotesLinks = shouldShowMyNotes
      ? [
          {
            to: "/me/notes",
            children: "My notes",
            icon: "signups",
          },
        ]
      : []

    return [...givingLinks, ...registrationLinks, ...myNotesLinks]
  }

  return (
    <div>
      <div className="d-f ai-c jc-sb mb-1">
        <Heading level={2} text="Activity & involvement" />
      </div>
      {hasGroups && <Groups />}
      <MyProfileLinks links={generateLinks()} />
    </div>
  )
}

function Groups() {
  const groups = useApiRead(
    "/groups/v2/me/groups?fields[Group]=name,header_image,church_center_web_my_group_url",
  ).data

  if (!groups || groups.length === 0) return <NoGroupsMessage />

  return (
    <div
      className="d-g mb-2"
      css={{
        gridTemplateColumns: "repeat(auto-fill,minmax(180px,1fr))",
        gridGap: "16px",
      }}
    >
      {groups.map((group) => (
        <Card
          key={group.id}
          link={group.attributes.church_center_web_my_group_url}
          poster={group.attributes.header_image.medium}
          posterAltText={`Logo for ${group.attributes.name}`}
          heading={group.attributes.name}
        />
      ))}
    </div>
  )
}

function NoGroupsMessage() {
  return (
    <div className="action-drawer mb-1">
      Groups you join will appear here for easy access
      <br />
      <Link to="/groups">
        <button className="md-btn secondary-btn btn mt-2">
          Explore Groups
        </button>
      </Link>
    </div>
  )
}

function useUserNoteCount() {
  return useQuery({
    queryKey: ["/publishing/v2/notes", { per_page: 0 }],
    throwOnError: false,
    refetchOnWindowFocus: false,
    select: (data) => data.meta.total_count,
  })
}

function useChannels() {
  return useQuery({
    queryKey: [
      "/publishing/v2/channels",
      { "fields[Channel]": "enabled_formats" },
    ],
    throwOnError: false,
    refetchOnWindowFocus: false,
  })
}
