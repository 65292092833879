import { Navigate, useParams } from "react-router-dom"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import { useSuspenseQuery } from "@tanstack/react-query"
import { mergeResourceIncludedIntoRelationships } from "source/publishing/sermons/shared/resource"

const ACTIVE_EPISODE = "active"

export function LatestEpisode() {
  const { channelId } = useParams()
  const { data: channel } = useChannel(channelId)

  const activeEpisodeTime = channel.relationships?.episode_times?.data.find(
    (episodeTime) => episodeTime.attributes.current_state === ACTIVE_EPISODE,
  )

  if (activeEpisodeTime) {
    const liveEpisode = channel.relationships.current_episode.data

    return (
      <Navigate
        to={`/episodes/${liveEpisode.id}?episode_time_id=${activeEpisodeTime.id}`}
        replace
      />
    )
  } else if (channel.relationships?.featured_episodes?.data.length > 0) {
    // The API already returns them sorted on published_to_library_at
    const mostRecentlyPublishedEpisodeId =
      channel.relationships?.featured_episodes.data[0]?.id

    return (
      <Navigate to={`/episodes/${mostRecentlyPublishedEpisodeId}`} replace />
    )
  } else {
    return <Navigate to={`/channels/${channelId}`} replace />
  }
}

function useChannel(id) {
  const url = `/publishing/v2/channels/${id}`
  const params = {
    include: "current_episode,current_episode.episode_times,featured_episodes",
  }

  return useSuspenseQuery({
    queryFn: () => {
      return sessionApiClient.get(url, params).catch((error) => error)
    },
    queryKey: ["publishing", "channel", id, params],
    select: (response) => mergeResourceIncludedIntoRelationships(response),
  })
}
