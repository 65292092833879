import { useContext, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { isChurchCenterApp } from "source/Layout"
import { WebBootContext } from "source/publishing/WebBoot"

declare global {
  interface Window {
    ccaVersion: string
  }
}

const EXCLUDED_PATH_PREFIXES = ["/unproxy", "/giving", "/people", "/pages/home"]

export function useChurchCenterPageAnalytics() {
  const { pathname } = useLocation()
  const {
    abilities,
    analyticsUrl: analyticsBaseUrl,
    currentPerson,
  } = useContext(WebBootContext)
  const track_analytics = abilities.publishing?.track_analytics || "none"
  const currentPersonId = currentPerson.id

  useEffect(() => {
    if (!analyticsBaseUrl || track_analytics === "none") return

    const isExcludedPath = EXCLUDED_PATH_PREFIXES.some((prefix) =>
      pathname.startsWith(prefix),
    )
    if (isExcludedPath) return

    const isAppWithTracking = window.ccaVersion

    if (isChurchCenterApp() && isAppWithTracking) return

    const analyticsUrl = new URL(analyticsBaseUrl)
    analyticsUrl.searchParams.append("h", window.location.hostname)
    analyticsUrl.searchParams.append("p", pathname)
    analyticsUrl.searchParams.append("s", isChurchCenterApp() ? "app" : "web")

    if (document.referrer) {
      analyticsUrl.searchParams.append("r", document.referrer)
    }

    if (currentPersonId !== "anonymous") {
      analyticsUrl.searchParams.append("v", `acid-${currentPersonId}`)
    }

    if (track_analytics === "all" || pathname === "/home") {
      fetch(analyticsUrl.toString(), { method: "POST" })
    }
  }, [analyticsBaseUrl, currentPersonId, pathname, track_analytics])

  return null
}
