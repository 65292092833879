import {
  Modal,
  useCloseModal,
  useOpenModal,
} from "source/shared/components/Modal"
import { bool, object, shape } from "prop-types"
import { useEffect, useState } from "react"
import { api } from "source/registrations/api/client"

const CancelRegistrationModal = ({
  registration,
  isCancelation = false,
  inputFocusRef,
}) => {
  const closeModal = useCloseModal()
  const openModal = useOpenModal()
  const [errorMessage, setErrorMessage] = useState("")

  useEffect(() => {
    if (isCancelation) {
      openModal()
    }
  }, [isCancelation, openModal])

  const handleCancel = () => {
    api
      .post(
        `/registrations/v2/registrations/${registration.id}/cancel_registration`,
      )
      .then(() => {
        registration.invalidate()
        closeModal()
      })
      .catch((error) => {
        setErrorMessage(error)
      })
  }

  return (
    <Modal.Show>
      <Modal.Header>Cancel registration</Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to cancel your registration?</p>
        {errorMessage && (
          <div className="mt-2" style={{ color: "var(--error-alert--text)" }}>
            {errorMessage}
          </div>
        )}
        <Modal.Footer>
          <Modal.Close>Nevermind</Modal.Close>
          <button className="btn" onClick={handleCancel} ref={inputFocusRef}>
            Yes, cancel it
          </button>
        </Modal.Footer>
      </Modal.Body>
    </Modal.Show>
  )
}

CancelRegistrationModal.propTypes = {
  registration: object,
  isCancelation: bool,
  inputFocusRef: shape({ current: object }),
}

export default CancelRegistrationModal
