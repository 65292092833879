import { useCallback, useEffect } from "react"

type BridgeEvent = { data: string | object; event: string }
type BridgeComponentMessage = BridgeEvent & { component: string }

declare global {
  interface Window {
    nativeBridge?: {
      supportedComponents: string[]
      eventTarget: {
        addEventListener: (
          callback: (e: BridgeComponentMessage) => void,
        ) => void
        removeEventListener: (
          callback: (e: BridgeComponentMessage) => void,
        ) => void
      }
    }
    ReactNativeWebView?: {
      postMessage: (data: string) => void
    }
  }
}

export default function useReactNativeBridgeComponent({
  component,
  onReply,
}: {
  component: string
  onReply?: (event: BridgeEvent) => void
}) {
  const supported = window.nativeBridge?.supportedComponents.includes(component)

  const postMessage = useCallback(
    (event: string, data: string | object) => {
      if (!supported) return
      window.ReactNativeWebView?.postMessage(
        JSON.stringify({
          component,
          data,
          event,
          type: "bridgeComponent",
        }),
      )
    },
    [component, supported],
  )

  const handleReplyMessage = useCallback(
    (event: BridgeComponentMessage) => {
      if (event.component === component && onReply) {
        onReply({ data: event.data, event: event.event })
      }
    },
    [component, onReply],
  )

  useEffect(() => {
    if (supported) {
      window.nativeBridge?.eventTarget.addEventListener(handleReplyMessage)
      return () =>
        window.nativeBridge?.eventTarget.removeEventListener(handleReplyMessage)
    }
  }, [handleReplyMessage, supported])

  return { postMessage, supported }
}
