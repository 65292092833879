export const DELETED_EPISODE = {
  included: [],
  data: {
    attributes: {
      art: {
        type: "File",
        id: "",
        attributes: {
          name: "",
          signed_identifier: "",
          source: "",
          variants: {
            original: "",
            original_ratio_small: "",
            small: "",
            medium: "",
            large: "",
          },
        },
      },
      church_center_url: "",
      description: "",
      library_art_url: "",
      library_audio_url: "",
      library_streaming_service: "",
      library_video_thumbnail_url: "",
      library_video_url: "",
      published_live_at: "",
      published_to_library: false,
      published_to_library_at: "",
      sermon_audio: {
        type: "File",
        id: "",
        attributes: {
          name: "",
          signed_identifier: "",
          source: "",
          variants: {
            name: "",
            signed_identifier: "",
            source: "",
          },
        },
      },
      stream_type: "",
      streaming_service: "",
      title: "Deleted Episode",
      video_thumbnail_url: "",
      video_url: "",
    },
    type: "Episode",
    links: {},
    relationships: {},
    id: "-1",
  },
  meta: {},
}
