import { useContext } from "react"
import { bool } from "source/shared/prop_types"
import {
  CurrentEventContext,
  CurrentGroupContext,
} from "source/groups/my/groups"
import { Link, useNavigate } from "react-router-dom"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import { date } from "@churchcenter/datetime-fmt"
import { EventDatetimeDisplay } from "source/shared/DatetimeFmt"
import moment from "moment"
import TabView from "source/groups/my/groups/TabView"
import { Menu, MenuButton, MenuList, MenuItem } from "@reach/menu-button"
import { Icon } from "source/shared/components"
import { LocationDetails } from "source/groups/components/LocationDetails"
import MyRsvpForm from "source/groups/my/groups/events/MyRsvpForm"
import RsvpsTable from "source/groups/my/groups/events/RsvpsTable"
import { TakeAttendanceButton } from "source/groups/components/TakeAttendanceButton"
import { CalendarSubscriptionButton } from "source/groups/components/CalendarSubscriptionButton"
import EventNotes from "source/groups/my/groups/events/EventNotes"
import { Heading, Subheading } from "@planningcenter/doxy-web"

export default function EventsShow() {
  const {
    event,
    location,
    my_rsvp,
    base_path: eventPath,
  } = useContext(CurrentEventContext)
  const { rsvpResponseAttributes } = useContext(CurrentGroupContext)

  const {
    starts_at,
    ends_at,
    canceled_at,
    name,
    description,
    abilities,
    location_type_preference,
    virtual_location_url,
    attendance_recording_status,
    attendance_description,
    calendar_subscription_links,
    calendar_subscription_url,
    church_center_my_event_url,
  } = event.attributes

  const [shortMonthName, dayNumber] = date(starts_at, {
    style: "abbreviated",
  }).split(" ")
  const eventIsCanceled = canceled_at && moment(canceled_at).isBefore(moment())
  const showMenu = abilities.can_manage
  const attendanceSubmitted = ["processing", "complete"].includes(
    attendance_recording_status,
  )

  const showMyRsvp = abilities.my_rsvp.can_manage && !attendanceSubmitted
  const showRsvpsTable = abilities.rsvps.can_index && !attendanceSubmitted

  return (
    <TabView showActions={false}>
      <header className="d-f fd-c fd-r@xs mb-3 pt-1 ai-c ai-fs@xs g-2">
        <div className="event-detail__cal">
          <div className="ta-c event-cal">
            <div className="event-cal__month">{shortMonthName}</div>
            <div className="event-cal__day">{dayNumber}</div>
          </div>
        </div>
        <div className="f-1 ta-c ta-l@xs">
          <div className="d-f ai-c jc-c jc-fs@xs">
            <Heading level={1} size={2} text={name} />
            {eventIsCanceled && (
              <div className="badge danger-badge ml-1">Canceled</div>
            )}
          </div>
          <div className="mb-4p c-tint2">
            <EventDatetimeDisplay startsAt={starts_at} endsAt={ends_at} />
          </div>
          <div className="d-f fd-c fd-r@xs ai-c jc-c jc-fs@xs g-1 mt-2 mt-1@xs">
            {showMenu && <OptionsMenu isCanceled={eventIsCanceled} />}
            <CalendarSubscriptionButton
              links={calendar_subscription_links}
              subscriptionType="occurrence"
              iCalUrl={calendar_subscription_url}
            >
              Add to my calendar
            </CalendarSubscriptionButton>
            <TakeAttendanceButton
              myEventUrl={church_center_my_event_url}
              status={attendance_recording_status}
            />
          </div>
        </div>
      </header>

      {showMyRsvp && (
        <MyRsvpForm
          rsvp={my_rsvp}
          rsvpResponseAttributes={rsvpResponseAttributes}
          eventApiPath={event.links.self}
        />
      )}

      {attendance_description && (
        <div className="mb-3">
          <div className="d-f ai-c jc-sb mb-1">
            <Subheading level={2} text="Attendance" />
            {attendance_recording_status === "complete" && (
              <Link
                to={`${eventPath}/attendance`}
                className="minor-btn secondary-btn btn"
              >
                Update
              </Link>
            )}
          </div>
          <p className="mb-1 fs-4 c-tint1">{attendance_description}</p>
        </div>
      )}

      {showRsvpsTable && <RsvpsTable />}

      {abilities.notes.can_read && (
        <div className="mb-3">
          <EventNotes />
        </div>
      )}

      {description && (
        <div className="mb-3 d-f fd-c g-1">
          <Subheading level={2} text="Details" />
          <div data-preformatted-content="heading-scale:legacy-church-center">
            <div dangerouslySetInnerHTML={{ __html: description }} />
          </div>
        </div>
      )}

      <div className="mb-3 w-33%@md">
        <LocationDetails
          preference={location_type_preference}
          virtual={{ url: virtual_location_url }}
          physical={location.attributes}
        />
      </div>
    </TabView>
  )
}

function OptionsMenu({ isCanceled }) {
  const navigate = useNavigate()
  const { base_path, event, refetchEvent } = useContext(CurrentEventContext)

  function restoreEvent() {
    sessionApiClient
      .post(`${event.links.self}/uncancel`)
      .then(() => refetchEvent())
  }

  return (
    <Menu>
      <MenuButton className="minor-btn secondary-btn btn">
        Options
        <span className="fs-6 pl-4p dropdown-trigger__icon">
          <Icon symbol="general#down-chevron" />
        </span>
      </MenuButton>
      <MenuList>
        {event.attributes.abilities.can_edit ? (
          <MenuItem onSelect={() => navigate(`${base_path}/edit`)}>
            Edit
          </MenuItem>
        ) : null}
        {isCanceled ? (
          <MenuItem onSelect={() => restoreEvent()}>Restore</MenuItem>
        ) : (
          <MenuItem onSelect={() => navigate(`${base_path}/cancel`)}>
            Cancel
          </MenuItem>
        )}
      </MenuList>
    </Menu>
  )
}
OptionsMenu.propTypes = {
  isCanceled: bool,
}
