import concat from "lodash/concat"
import dig from "lodash/get"
import transform from "lodash/transform"

// Child resources are available in the `included` object, outside of the resource itself,
// but it's easier to work with them if they are merged into the `relationships` object instead.
// This function takes a payload that looks roughtly like this (some irrelevant properties omitted):
//
//     {
//       "data": {
//         "type": "Episode",
//         "id": "698836599",
//         "attributes": {
//           "description": null,
//           "host": null,
//           "published_live_at": null,
//           "title": "My Episode",
//           "video_url": null
//         },
//         "relationships": {
//           "episode_times": {
//             "data": [
//               {
//                 "type": "EpisodeTime",
//                 "id": "8"
//               }
//             ]
//           }
//         }
//       },
//       "included": [
//         {
//           "type": "EpisodeTime",
//           "id": "8",
//           "attributes": {
//             "starts_at": "2020-05-27T23:58:00Z",
//             "video_url": null
//           },
//           "links": {
//             "self": "http://api.pco.test/publishing/v2/episodes/698836599/episode_times/8"
//           }
//         }
//       ]
//     }
//
// ...and returns a copy that looks like this:
//
//     {
//       "type": "Episode",
//       "id": "698836599",
//       "attributes": {
//         "description": null,
//         "host": null,
//         "published_live_at": null,
//         "title": "My Episode",
//         "video_url": null
//       },
//       "relationships": {
//         "episode_times": {
//           "data": [
//             {
//               "type": "EpisodeTime",
//               "id": "8",
//               "attributes": {
//                 "starts_at": "2020-05-27T23:58:00Z",
//                 "video_url": null
//               },
//               "links": {
//                 "self": "http://api.pco.test/publishing/v2/episodes/698836599/episode_times/8"
//               }
//             }
//           ]
//         }
//       }
//     }
//
export function mergeResourceIncludedIntoRelationships(payload) {
  const includedByTypeAndId = transform(
    payload.included,
    (result, resource) => {
      // eslint-disable-next-line @typescript-eslint/no-extra-semi
      ;(result[resource.type] || (result[resource.type] = {}))[resource.id] =
        resource
    },
    {},
  )

  return {
    ...payload.data,
    relationships: transform(
      payload.data.relationships,
      (result, relation, key) => {
        if (!relation.data) return
        if (relation.data.forEach) {
          // one-to-many
          result[key] = {
            ...relation,
            data: relation.data.map((relationship) => ({
              ...relationship,
              ...dig(
                includedByTypeAndId,
                `${relationship.type}.${relationship.id}`,
              ),
            })),
          }
        } else {
          // one-to-one
          const { type, id } = relation.data
          result[key] = {
            ...relation,
            data: {
              ...relation.data,
              ...dig(includedByTypeAndId, `${type}.${id}`),
            },
          }
        }
      },
      {},
    ),
  }
}

// As a correlary to the above function, splitting `relationships` data back into the
// `included` object is necessary for passing child resources to the API.
//
// You must pass an array of relationKeysToExtract (array of strings) because some
// relationships should remain on the resource.
export function extractResourceRelationshipsToIncluded(
  resource,
  relationKeysToExtract,
) {
  let included = []
  const rewrittenResource = {
    ...resource,
    relationships: transform(
      resource.relationships,
      (result, relation, key) => {
        if (relationKeysToExtract.indexOf(key) >= 0) {
          included = concat(included, relation.data)
        } else {
          result[key] = relation
        }
      },
      {},
    ),
  }
  return {
    data: rewrittenResource,
    included,
  }
}
