import { useContext, useEffect, useMemo } from "react"
import { Calendar, momentLocalizer } from "react-big-calendar"
import { useNavigate } from "react-router-dom"
import moment from "moment"
import { MonthViewStyles, MonthViewOverlayStyles } from "./MonthViewStyles"
import { bool, func, object, shape, string } from "prop-types"
import { useCurrentOrganizationTimeZone } from "../hooks/useCurrentOrganizationTimeZone"
import { Spinner, Icon } from "source/shared/components"

import { WideLayout } from "source/Layout"
import { useEmbedded } from "source/calendar/hooks/useEmbedded"
import { find } from "lodash"
import { useMomentInTimeZone } from "source/calendar/hooks/useMomentInTimeZone"
import sortEvents from "source/calendar/utils/sort_events"
import { WebBootContext } from "source/publishing/WebBoot"
import { useChurchCenterConfiguration } from "../hooks/useChurchCenterConfiguration"

const Toolbar = ({ loadingEvents, props }) => {
  const {
    localizer: { messages },
    label,
    onNavigate,
  } = props

  return (
    <div className="rbc-toolbar">
      <span className="rbc-btn-group">
        <button type="button" onClick={() => onNavigate("TODAY")}>
          {messages.today}
        </button>
        <button
          type="button"
          aria-label="previous month"
          onClick={() => onNavigate("PREV")}
        >
          {messages.previous}
        </button>
        <button
          type="button"
          aria-label="next month"
          onClick={() => onNavigate("NEXT")}
        >
          {messages.next}
        </button>
      </span>

      <div className="rbc-toolbar-label d-f">
        {label}{" "}
        <span className="d-f ai-c ml-1">
          <Spinner alt="Loading Events" opacity={loadingEvents ? 1 : 0} />
        </span>
      </div>
    </div>
  )
}

Toolbar.displayName = "Toolbar"
Toolbar.propTypes = {
  loadingEvents: bool,
  props: shape({
    label: string,
    localizer: shape({
      messages: object,
    }),
    onNavigate: func,
  }),
}

const EventWrapper = (context) => {
  const {
    currentOrganization: {
      attributes: { datetime_fmt_configuration },
    },
  } = useContext(WebBootContext)

  const { embedded } = useEmbedded()

  const getTitleAccessor = (e) => {
    const timeFormat = datetime_fmt_configuration.hour12
      ? moment(e.start).format("mm") === "00"
        ? "ha"
        : "h:mma"
      : "HH:mm"

    return e.allDay ? (
      e.title
    ) : (
      <>
        {e.featured ? (
          <Icon
            symbol="general#closed-star"
            style={{ color: "#F5BE3D", marginRight: 2 }}
          />
        ) : null}
        <span className="fw-400">{moment(e.start).format(timeFormat)}</span>{" "}
        {e.title}
      </>
    )
  }
  const navigate = useNavigate()
  const onSelectEvent = (event) => {
    const eventUrl = `/calendar/event/${event.id}`

    return embedded ? window.open(eventUrl, "_blank") : navigate(eventUrl)
  }

  const event = context.event
  let classes = ["rbc-event"]
  if (event.allDay || event.multiDayEvent) {
    classes.push("rbc-event-allday")
  }
  return (
    <div
      className={classes.join(" ")}
      onClick={() => {
        onSelectEvent(event)
      }}
    >
      <div className="rbc-event-content" title={event.title}>
        <div>{getTitleAccessor(event)}</div>
      </div>
    </div>
  )
}

const MonthView = ({ events, loadingEvents, onMonthChange, viewingMonth }) => {
  const orgTz = useCurrentOrganizationTimeZone()
  useEffect(() => {
    moment.tz.setDefault(orgTz)

    return () => {
      moment.tz.setDefault() // resets to default
    }
  }, [])

  const { weekStartsOnInt } = useChurchCenterConfiguration()

  const localizer = useMemo(() => {
    // Start week on Sunday or Monday
    moment.updateLocale("en", {
      week: {
        dow: weekStartsOnInt,
      },
    })
    const localizer = momentLocalizer(moment)

    localizer.sortEvents = function ({ evtA, evtB }) {
      return sortEvents({ evtA, evtB })
    }
    return localizer
  }, [weekStartsOnInt])

  const components = useMemo(
    () => ({
      eventWrapper: EventWrapper, // used by each view (Month, Day, Week)
      toolbar: (props) => <Toolbar {...{ loadingEvents, props }} />,
    }),
    [loadingEvents],
  )

  const momentInTZ = useMomentInTimeZone()

  const getEventsForCalendar = () => {
    // `events` is a hash of arrays of events, keyed by date, with duplicate entries
    // for multi-day events. We need to flatten this into a single array of events,
    // with multi-day events deduplicated.
    const singleDayEvents = []
    const multiDayEvents = []
    const sortedDaysInMonth = Object.keys(events).sort()

    Object.values(events).forEach((day) => {
      day.forEach((event) => {
        const transformedEvent = {
          start: event.startsAt,
          end: event.endsAt,
          title: event.name,
          allDay: event.allDayEvent,
          id: event.id,
          multiDayEvent: event.multiDayEvent,
          registrationUrl: event.registrationUrl,
          featured: event.featured,
        }

        if (transformedEvent.multiDayEvent) {
          const earliestOccurrence =
            event.startsAt === event.initialStartsAt ||
            event.startsAt == sortedDaysInMonth[0]
          const alreadyAdded = find(multiDayEvents, {
            id: transformedEvent.id,
          })

          if (earliestOccurrence && !alreadyAdded)
            multiDayEvents.push(transformedEvent)
        } else {
          singleDayEvents.push(transformedEvent)
        }
      })
    })

    return [...singleDayEvents, ...multiDayEvents]
  }

  return (
    <WideLayout>
      <MonthViewOverlayStyles />
      <Calendar
        events={getEventsForCalendar()}
        date={viewingMonth}
        defaultView="month"
        localizer={localizer}
        messages={{
          previous: <Icon symbol="general#left-chevron" />,
          next: <Icon symbol="general#right-chevron" />,
        }}
        onNavigate={(date) => {
          onMonthChange(momentInTZ(moment(date)))
        }}
        popup
        views={["month"]}
        css={MonthViewStyles}
        components={components}
      />
    </WideLayout>
  )
}

MonthView.propTypes = {
  events: object.isRequired,
  onMonthChange: func.isRequired,
  viewingMonth: string.isRequired,
}

export default MonthView
