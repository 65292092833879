import { Navigate, useParams } from "react-router-dom"
import { useSuspenseQuery } from "@tanstack/react-query"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import { mergeResourceIncludedIntoRelationships } from "source/publishing/sermons/shared/resource"
import { EpisodeTimesAPIRecord } from "source/publishing/sermons/shared/types"

const ACTIVE_EPISODE = "active"

export function LatestNotes() {
  const { channelId } = useParams()
  const { data: channel } = useChannel(channelId as string)

  const activeEpisodeTime = channel.relationships?.episode_times?.data.find(
    (episodeTime: EpisodeTimesAPIRecord) =>
      episodeTime.attributes.current_state === ACTIVE_EPISODE,
  )
  const activeEpisode = channel.relationships?.current_episode?.data
  const hasActiveEpisodeNote = activeEpisode?.relationships?.note_template?.data

  // The API already returns them sorted on published_to_library_at
  const mostRecentlyPublishedEpisode =
    channel.relationships?.featured_episodes?.data[0]
  const mostRecentlyPublishedEpisodeHasNote =
    !!mostRecentlyPublishedEpisode?.relationships?.note_template?.data

  if (activeEpisodeTime && hasActiveEpisodeNote) {
    return <Navigate to={`/episodes/${activeEpisode.id}/notes`} replace />
  } else if (
    mostRecentlyPublishedEpisode &&
    mostRecentlyPublishedEpisodeHasNote
  ) {
    return (
      <Navigate
        to={`/episodes/${mostRecentlyPublishedEpisode.id}/notes`}
        replace
      />
    )
  } else {
    return <Navigate to={`/channels/${channelId}`} replace />
  }
}

function useChannel(id: string) {
  const url = `/publishing/v2/channels/${id}`
  const params = {
    include: [
      "current_episode",
      "current_episode.episode_times",
      "current_episode.note_template",
      "featured_episodes",
      "featured_episodes.note_template",
    ].join(","),
  }

  return useSuspenseQuery({
    queryFn: () => {
      return sessionApiClient.get(url, params).catch((error: Error) => error)
    },
    queryKey: ["publishing", "channel", id, params],
    select: (response) => mergeResourceIncludedIntoRelationships(response),
  })
}
