import { useState, useCallback, useEffect } from "react"
import PropTypes from "prop-types"
import { isEmpty } from "lodash"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import { useQuery } from "@tanstack/react-query"
import { css } from "@emotion/react"
import Messaging from "./Messaging"
import SubscriberCounter from "./SubscriberCounter"
import VideoPlayer from "./VideoPlayer"
import { useNavigate } from "react-router-dom"
import classNames from "classnames"
import moment from "moment"
import Countdown from "./Countdown"
import EmbeddedPlayer from "./EmbeddedPlayer"
import {
  BREAKPOINTS,
  useWindowDimensions,
} from "source/shared/hooks/useWindowDimensions"
import { useInterval } from "source/shared/hooks/useInterval"
import Resources, { EpisodeResourcesLink } from "./Resources"
import { isChurchCenterApp } from "source/Layout"
import { useFlipperFeatureEnabled } from "source/shared/flipperFeatures"
import { episodePath } from "../shared/routes"
import { urlForResource } from "../shared/utils"
import { Notes, NotesFromEpisodeApi } from "./Notes"

import "./styles.css"

const TAB_DISCUSSION = "discussion"
const TAB_RESOURCES = "resources"
const TAB_NOTES = "notes"

LiveEpisode.propTypes = {
  joltChannel: PropTypes.object,
  episode: PropTypes.object,
  episodeTime: PropTypes.object,
  episodeTimes: PropTypes.array,
  channel: PropTypes.object,
  featuredResource: PropTypes.object,
  episodeResources: PropTypes.array,
  subscribersCount: PropTypes.number,
}

export default function LiveEpisode({
  episode,
  episodeTime,
  episodeTimes,
  channel,
  joltChannel,
  featuredResource,
  episodeResources,
}) {
  const [activeTab, setActiveTab] = useState(TAB_RESOURCES)
  const [subscribersCount, setSubscribersCount] = useState()
  const isCCA = isChurchCenterApp()

  const userIsWatchingLive = !!episodeTime // if there's an EpisodeTime specified, we're watching it live. Otherwise, we're watching it in the Library.

  const { notesEnabled } = useNotesTabEnabled(episode, channel)
  const chatIsEnabled =
    userIsWatchingLive &&
    (channel.attributes.group_chat_enabled ||
      channel.attributes.general_chat_enabled)
  const { width: windowWidth } = useWindowDimensions()
  const showNotesTab = notesEnabled && !isCCA && windowWidth >= BREAKPOINTS.LG

  useEffect(() => {
    if (windowWidth < BREAKPOINTS.LG && activeTab === TAB_NOTES) {
      setActiveTab(TAB_RESOURCES)
    }
  }, [activeTab, windowWidth])

  useEffect(() => {
    if (!chatIsEnabled && activeTab === TAB_DISCUSSION) {
      setActiveTab(TAB_RESOURCES)
    }
  }, [activeTab, chatIsEnabled])

  useEffect(() => {
    if (isCCA) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "stopAudio",
        }),
      )
    }
  }, [isCCA])

  useEffect(() => {
    if (joltChannel) {
      const event = `jolt.subscriptionStats`
      return joltChannel.bind(event, (message) =>
        setSubscribersCount(JSON.parse(message.data).subscribers),
      )
    }
  }, [joltChannel])

  return (
    <div css={styles.videoChatGridContainer}>
      <div className="video-block" style={{ position: "relative" }}>
        <SubscriberCounter
          subscribersCount={subscribersCount}
          className="sub-counter"
        />
        <VideoContainer
          episode={episode}
          episodeTimes={episodeTimes}
          episodeTime={episodeTime}
          joltChannel={joltChannel}
        />
      </div>
      <div className="sermons__messaging chat-block f-1 fw-w">
        <div className="action-item-row d-f jc-sb">
          <div
            className="tab-items"
            role="tablist"
            aria-label="Episode Chat and Info"
          >
            <button
              role="tab"
              aria-selected="true"
              aria-controls="panel-2"
              id="tab-2"
              className={classNames("h4 tab fs-4 mr-3 set-focus-invisible", {
                "tab--active": activeTab === TAB_RESOURCES,
              })}
              onClick={() => setActiveTab(TAB_RESOURCES)}
            >
              Info
            </button>
            {chatIsEnabled && (
              <button
                role="tab"
                aria-selected="true"
                aria-controls="panel-1"
                id="tab-1"
                tabIndex="0"
                className={classNames("h4 tab fs-4 mr-3 set-focus-invisible", {
                  "tab--active": activeTab === TAB_DISCUSSION,
                })}
                onClick={() => setActiveTab(TAB_DISCUSSION)}
              >
                Chat
              </button>
            )}
            {showNotesTab && (
              <button
                role="tab"
                aria-selected="true"
                aria-controls="panel-3"
                id="tab-3"
                tabIndex="0"
                className={classNames("h4 tab fs-4 mr-3 set-focus-invisible", {
                  "tab--active": activeTab === TAB_NOTES,
                })}
                onClick={() => setActiveTab(TAB_NOTES)}
              >
                Notes
              </button>
            )}
          </div>
          {featuredResource && (
            <a
              href={urlForResource(featuredResource)}
              rel="noopener noreferrer"
              className="compact-btn secondary-btn btn"
            >
              {featuredResource.attributes.title}
            </a>
          )}
        </div>
        {activeTab === TAB_DISCUSSION && chatIsEnabled && (
          <Messaging
            episode={episode}
            episodeTime={episodeTime}
            groupChatEnabled={channel.attributes.group_chat_enabled}
            generalChatEnabled={channel.attributes.general_chat_enabled}
          />
        )}

        {activeTab === TAB_RESOURCES && (
          <div
            id="panel-2"
            role="tabpanel"
            tabIndex="0"
            aria-labelledby="tab-2"
            className="p-2 set-focus-invisible tab-widget-wrapper"
          >
            <div className="tab-widget-content f-1" style={{ padding: "8px" }}>
              <Resources
                episodeDescription={episode.attributes.description}
                episodeResources={episodeResources}
                notesToggleButton={
                  notesEnabled &&
                  !showNotesTab && (
                    <EpisodeResourcesLink
                      icon="accounts#editor"
                      title="Take notes"
                      url={`${episodePath(episode)}/notes?return_to_episode=true`}
                    />
                  )
                }
              />
            </div>
          </div>
        )}

        {notesEnabled && activeTab === TAB_NOTES && (
          <div
            id="panel-3"
            role="tabpanel"
            tabIndex="0"
            aria-labelledby="tab-3"
            className="set-focus-invisible tab-widget-wrapper live-episode-notes-panel"
          >
            <div className="tab-widget-content f-1">
              <Notes.Suspense>
                <NotesFromEpisodeApi episodeId={episode.id} />
              </Notes.Suspense>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

VideoContainer.propTypes = {
  episode: PropTypes.object.isRequired,
  episodeTimes: PropTypes.array,
  episodeTime: PropTypes.object,
  joltChannel: PropTypes.object,
}

const POLL_INTERVAL_IN_MILLISECONDS = 10000

function VideoContainer({
  episode,
  episodeTimes,
  episodeTime: origEpisodeTime,
  joltChannel,
}) {
  const navigate = useNavigate()
  const [episodeTime, setEpisodeTime] = useState(origEpisodeTime)

  const fetchEpisodeTime = useCallback(() => {
    if (!episodeTime) return
    sessionApiClient.get(episodeTime.links.self).then(({ data }) => {
      setEpisodeTime(data)
    })
  }, [episodeTime, setEpisodeTime])

  // TODO: find a way to get this information over the wire without polling
  useInterval(fetchEpisodeTime, POLL_INTERVAL_IN_MILLISECONDS)

  const publishedToLibrary = episode.attributes.published_to_library
  let videoUrl
  let streamingService
  let videoEmbedCode
  if (episodeTime) {
    // The user chose an EpisodeTime by clicking a "Watch Live" button
    // url: http://carlsbad.churchcenter.com/episodes/1?episode_time_id=2
    if (isEpisodeTimeOver(episodeTime) && publishedToLibrary) {
      // EpisodeTime was over long ago and this Episode is in the library,
      // so redirect to the permalink
      navigate(`/episodes/${episode.id}`, { replace: true })
    } else {
      // EpisodeTime is active (or over), so that's good
      videoUrl = episodeTime.attributes.video_url
      streamingService = episodeTime.attributes.streaming_service
      videoEmbedCode = episodeTime.attributes.video_embed_code
    }
  } else if (publishedToLibrary) {
    // The user got here via the Library permalink
    // url: http://carlsbad.churchcenter.com/episodes/1
    // ...and it's published in the library, so show them that.
    videoUrl = episode.attributes.library_video_url
    streamingService = episode.attributes.library_streaming_service
  } else {
    // If all else fails, just show them _something_.
    if (episode.attributes.video_url) {
      videoUrl = episode.attributes.video_url
      streamingService = episode.attributes.streaming_service
    } else if (episode.attributes.library_video_url) {
      videoUrl = episode.attributes.library_video_url
      streamingService = episode.attributes.library_streaming_service
    }
    videoEmbedCode = episodeTimes
      .map((t) => t.attributes.video_embed_code)
      .find((t) => t)
  }

  if (episodeTime?.attributes.current_timestamp < 0) {
    const startTime = moment().add(
      Math.abs(episodeTime.attributes.current_timestamp),
      "seconds",
    )
    return (
      <Countdown
        startTime={startTime}
        onFinished={() => setTimeout(fetchEpisodeTime, 500)}
      />
    )
  }

  if (videoEmbedCode) {
    return (
      <EmbeddedPlayer
        episodeTime={episodeTime}
        videoEmbedCode={videoEmbedCode}
        joltChannel={joltChannel}
      />
    )
  }

  const formerEnumString = "scheduled_event"
  const futureEnumString = "prerecorded"

  if (videoUrl) {
    return (
      <VideoPlayer
        url={videoUrl}
        streamingService={streamingService}
        showControls={!episodeTime}
        enforceSync={
          episodeTime &&
          [formerEnumString, futureEnumString].includes(
            episode.attributes.stream_type,
          )
        }
        episodeTime={episodeTime}
        joltChannel={joltChannel}
      />
    )
  }

  return (
    <div css={styles.videoContainer}>
      <div
        className="d-f jc-c ai-c"
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          right: "0",
          bottom: "0",
        }}
      >
        <span className="no-video-url">The video URL could not be found.</span>
      </div>
    </div>
  )
}

function isEpisodeTimeOver(episodeTime) {
  return (
    episodeTime?.attributes.ends_at &&
    moment(episodeTime.attributes.ends_at)
      .add(1, "hour")
      .isSameOrBefore(moment())
  )
}

const styles = {
  videoChatGridContainer: css`
    display: flex;
    flex-direction: column;
    flex: 1;
    @media (min-width: 960px) {
      display: grid;
      grid-template-columns: 1.3fr 0.7fr;
      grid-template-rows: 1fr;
      gap: 24px 24px;
      grid-template-areas: ". .";
    }

    .sub-counter {
      position: absolute;
      top: 12px;
      right: 12px;
    }

    .action-item-row {
      padding: 0 0.75rem;
      width: 100%;
      align-items: center;
      @media (max-width: 959px) {
        box-shadow: 0 1px 0 var(--color-tint9);
      }
      @media (min-width: 960px) {
        padding-right: 0;
        padding-left: 0;
        height: 32px;
        align-items: flex-end;
        border-bottom: 0;
      }

      > .btn {
        @media (min-width: 960px) {
          margin-bottom: 12px;
        }
      }

      .tab {
        cursor: pointer;
        color: var(--color-tint3);
        background: transparent;
        text-transform: capitalize;
        border: 0;
        border-bottom: 4px solid transparent;
        border-radius: 0;
        display: inline-block;
        padding: 12px 0 12px 0;

        @media (min-width: 960px) {
          padding-top: 0;
          font-size: 16px;
        }
      }
      /* TODO: Remove once a fix is provided.
      This temporarily gives minions specificity over the ongoing work in dark mode.
      An issue was raised with R&F about minions not needing greater specificity than a compatible theming system */

      .tab--active {
        /* TODO: */
        color: var(--color-tint1);
        border-color: var(--color-tint4);
      }
    }

    .chat-block {
      position: relative;
      @media (max-width: 959px) {
        display: flex;
      }
    }

    .video-embed {
      /* need these styles to maintain aspect ratio for responsive views */
      position: relative;
      padding-bottom: 56.25%;
      height: 0px;
      overflow: hidden;
    }

    .video-embed iframe {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
    }
  `,
  videoContainer: css`
    position: relative;
    padding-bottom: 56.25%; /*16:9*/
    height: 0;
    overflow: hidden;
    border-radius: 5px;

    .no-video-url {
      background: #ef5350;
      color: var(--color-tint10);
      padding: 8px 16px;
      border-radius: 3px;
    }
  `,
}

function useNotesTabEnabled(episode, channel) {
  const flipperFeatureEnabled = useFlipperFeatureEnabled("ROLLOUT_sermon_notes")
  const sermonNotesEnabledForChannel =
    channel.attributes.enabled_formats.sermon_notes ?? false // This nullish check can be removed once the ROLLOUT_sermon_notes feature flag is removed

  const sermonNotesEnabled =
    flipperFeatureEnabled && sermonNotesEnabledForChannel

  const { isLoading: noteLoading, data: noteId } = useQuery({
    enabled: sermonNotesEnabled,
    queryFn: () =>
      sessionApiClient.get(`/publishing/v2/episodes/${episode.id}/note`, {
        "fields[Note]": "id",
      }),
    queryKey: ["episode", episode.id, "note", "id"],
    retry: false,
    select: ({ data }) => data.id,
    staleTime: Infinity,
  })
  const { isLoading: noteTemplateLoading, data: noteTemplateId } = useQuery({
    enabled: sermonNotesEnabled,
    queryFn: () =>
      sessionApiClient.get(
        `/publishing/v2/episodes/${episode.id}/note_template`,
        { "fields[NoteTemplate]": "id" },
      ),
    queryKey: ["episode", episode.id, "noteTemplate", "id"],
    retry: false,
    select: ({ data }) => data.id,
    staleTime: Infinity,
  })
  const episodeHasNotesEnabled = !isEmpty(noteTemplateId)
  const userHasNotes = !isEmpty(noteId)

  return {
    loading: sermonNotesEnabled && (noteLoading || noteTemplateLoading),
    notesEnabled:
      sermonNotesEnabled && (episodeHasNotesEnabled || userHasNotes),
  }
}
