import { useRef } from "react"
import { string, func, bool } from "prop-types"
import {
  AlertDialog,
  AlertDialogLabel,
  AlertDialogDescription,
} from "@reach/alert-dialog"
import { Button } from "@planningcenter/doxy-web"

export const DeleteConfirmationDialog = ({
  message,
  paymentMethodDeletable,
  onCancel,
  onConfirm,
}) => {
  const cancelRef = useRef(null)

  return (
    <AlertDialog leastDestructiveRef={cancelRef}>
      <AlertDialogLabel>Delete payment method</AlertDialogLabel>
      <AlertDialogDescription>
        <p>{message}</p>
        <div className="d-f jc-fe mt-3 g-1">
          <Button
            text="Cancel"
            variant="naked"
            onClick={onCancel}
            ref={cancelRef}
          />
          <Button
            text="Delete"
            variant="destroy"
            onClick={onConfirm}
            disabled={!paymentMethodDeletable}
          />
        </div>
      </AlertDialogDescription>
    </AlertDialog>
  )
}

DeleteConfirmationDialog.propTypes = {
  message: string,
  paymentMethodDeletable: bool,
  onCancel: func,
  onConfirm: func,
}
