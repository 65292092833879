import { useState, useRef } from "react"
import {
  AlertDialog,
  AlertDialogLabel,
  AlertDialogDescription,
} from "@reach/alert-dialog"
import { Button } from "@planningcenter/doxy-web"
import { string, func, bool } from "prop-types"
import { paymentMethodProps } from "source/payments/payment-method-prop-types"
import { VerifyAmounts } from "./VerifyAmounts"
import { VerifyDescriptorCode } from "./VerifyDescriptorCode"
import { PAYMENT_URLS } from "source/payments/utils"
import { api } from "source/registrations/api/client"
import { AlertMessage } from "source/shared/components"

export const VerifyAccountDialog = ({
  orgName,
  paymentMethod,
  testMode,
  onSuccess,
  onConfirm,
  onCancel,
}) => {
  const { last4, verificationMethod } = paymentMethod
  const cancelRef = useRef(null)
  const [inputValid, setInputValid] = useState(false)

  const [descriptorCode, setDescriptorCode] = useState("")
  const [microdeposit1, setMicrodeposit1] = useState("")
  const [microdeposit2, setMicrodeposit2] = useState("")

  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState(null)

  const handleSubmitAccountVerification = async () => {
    const body = {
      data: {
        attributes:
          verificationMethod === "stripe_connections_microdeposit_amounts"
            ? { amounts: [microdeposit1, microdeposit2] }
            : { descriptor_code: descriptorCode },
      },
    }
    try {
      setError(null)
      setSubmitting(true)
      await api.post(PAYMENT_URLS.verfiyPaymentMethod(paymentMethod.id), body)
      onSuccess()
      onConfirm()
    } catch {
      setError(
        "There was a problem verifying your bank account. Please try again.",
      )
    } finally {
      setSubmitting(false)
    }
  }

  const verifyFormProps = {
    last4,
    orgName,
    testMode,
    setInputValid,
    onVerify: () => {},
  }

  const verifyFormLookup = {
    stripe_connections_microdeposit_amounts: (
      <VerifyAmounts
        {...verifyFormProps}
        microdeposit1={microdeposit1}
        microdeposit2={microdeposit2}
        setMicrodeposit1={setMicrodeposit1}
        setMicrodeposit2={setMicrodeposit2}
      />
    ),
    stripe_connections_microdeposit_descriptor_code: (
      <VerifyDescriptorCode
        {...verifyFormProps}
        descriptorCode={descriptorCode}
        setDescriptorCode={setDescriptorCode}
      />
    ),
  }

  return (
    <AlertDialog leastDestructiveRef={cancelRef}>
      <AlertDialogLabel>Enter deposit code</AlertDialogLabel>
      <AlertDialogDescription>
        {verifyFormLookup[verificationMethod]}
        {error && (
          <div className="mt-3">
            <AlertMessage type="error">{error}</AlertMessage>
          </div>
        )}
        <div className="d-f jc-fe mt-3 g-1">
          <Button
            text="Cancel"
            variant="naked"
            onClick={onCancel}
            ref={cancelRef}
          />
          <Button
            text="Verify account"
            variant="primary"
            disabled={!inputValid}
            isLoading={submitting}
            onClick={handleSubmitAccountVerification}
          />
        </div>
      </AlertDialogDescription>
    </AlertDialog>
  )
}

VerifyAccountDialog.propTypes = {
  orgName: string.isRequired,
  paymentMethod: paymentMethodProps.isRequired,
  testMode: bool,
  onSuccess: func.isRequired,
  onConfirm: func.isRequired,
  onCancel: func.isRequired,
}
