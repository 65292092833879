import { useState, useRef, useEffect } from "react"
import { bool, func, object, string } from "prop-types"
import classnames from "classnames"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import { Icon } from "source/shared/components"
import { useDebounce } from "source/shared/hooks/useDebounce"
import { useCountryOptions } from "./hooks/useCountryOptions"

CountryFlag.propTypes = {
  url: string.isRequired,
  lazy: bool,
}

function CountryFlag({ url, lazy = false }) {
  if (!url) return null

  return (
    <img
      alt=""
      height={16}
      loading={lazy ? "lazy" : undefined}
      src={url}
      style={{
        marginRight: 4,
        borderRadius: 3,
        border: "1px solid var(--color-tint6)",
      }}
      width={24}
    />
  )
}

CountryItem.propTypes = {
  country: object.isRequired,
  onSelect: func,
  highlighted: bool,
  selected: bool,
  htmlId: string,
}

function CountryItem({
  country,
  onSelect,
  highlighted,
  selected = false,
  htmlId,
}) {
  if (!country.attributes) return null

  const {
    id,
    attributes: { name, flag_url },
  } = country

  return (
    <li
      aria-label={name}
      aria-selected={selected}
      className="dropdown__item d-f ai-c g-1"
      data-selected={highlighted ? true : undefined}
      id={htmlId}
      onClick={() => onSelect(id)}
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === "Space") onSelect(id)
      }}
      role="option"
      value={id}
    >
      {selected ? (
        <Icon
          symbol="general#check"
          className="fs-5"
          aria-hidden
          style={{ minWidth: 12 }}
        />
      ) : (
        <div style={{ minWidth: 12 }} />
      )}
      <CountryFlag url={flag_url} lazy={true} />
      <span className={selected ? "fw-b" : undefined}>{name}</span>
    </li>
  )
}

CountrySelect.propTypes = {
  disabled: bool,
  id: string.isRequired,
  onChange: func.isRequired,
  value: string,
}

export function CountrySelect({ id, value, onChange, disabled = false }) {
  const allCountries = useCountryOptions()
  const [countries, setCountries] = useState(allCountries)
  const [currentCountry, setCurrentCountry] = useState(null)
  const [highlightedIndex, setHighlightedIndex] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState("")
  const debouncedSearchTerm = useDebounce(searchTerm, 100)

  const buttonRef = useRef(null)
  const dropdownRef = useRef(null)
  const htmlId = `country_select_${id}`
  const inputRef = useRef(null)

  useEffect(() => {
    if (!isOpen) return

    const onClickAway = (e) => {
      if (buttonRef.current.contains(e.target)) {
        return
      }
      if (!dropdownRef.current.contains(e.target)) {
        closeDropdown()
      }
    }
    document.addEventListener("click", onClickAway)
    return () => document.removeEventListener("click", onClickAway)
  }, [isOpen])

  // Update search results
  useEffect(() => {
    if (debouncedSearchTerm.length === 0) {
      setCountries(allCountries)
      setHighlightedIndex(null)
    } else {
      sessionApiClient
        .get("/people/v2/countries", {
          "where[search_term]": `${debouncedSearchTerm}%`,
        })
        .then(({ data }) => {
          setCountries(data)

          // When there's only one results, highlight that item
          // so the user can quickly select it by pressing <Enter>.
          setHighlightedIndex(data.length === 1 ? 0 : null)
        })
    }
  }, [debouncedSearchTerm, allCountries])

  // Update currently selected country
  useEffect(() => {
    const country = allCountries.filter((c) => c.id === value)
    if (country.length) setCurrentCountry(country[0])
  }, [allCountries, value])

  // Update currently highlighted item
  useEffect(() => {
    if (highlightedIndex === null) return
    if (highlightedIndex >= countries.length) setHighlightedIndex(0)
    if (highlightedIndex < 0) setHighlightedIndex(countries.length - 1)
    scrollIntoView()
  }, [highlightedIndex, countries])

  const handleToggle = () => {
    const target = isOpen ? buttonRef : inputRef
    setIsOpen((open) => !open)
    window.setTimeout(() => target?.current.focus(), 100)
  }
  const closeDropdown = () => {
    setIsOpen(false)
    setHighlightedIndex(null)
    setSearchTerm("")
  }

  const scrollIntoView = () => {
    const node = dropdownRef?.current.querySelector('[data-selected="true"]')
    const parent = dropdownRef.current.querySelector('[role="listbox"]')

    if (!node) return

    const nodeTop = node.offsetTop
    const nodeBottom = nodeTop + node.offsetHeight
    const parentTop = parent.scrollTop
    const parentBottom = parentTop + parent.offsetHeight

    if (nodeTop < parentTop) {
      // if the item is above the scrollable area
      parent.scrollTo({ top: nodeTop })
    } else if (nodeBottom >= parentBottom) {
      // if the item is below the scrollable area
      parent.scrollTo({ top: nodeBottom - parent.offsetHeight })
    }
  }

  const handleInputKeyPress = (e) => {
    e.stopPropagation()
    if (e.key === "Enter") {
      e.preventDefault()
      if (highlightedIndex !== null) {
        onChange(countries[highlightedIndex].id)
        closeDropdown()
        buttonRef.current.focus()
      }
    } else if (e.key === "ArrowDown") {
      e.preventDefault()
      setHighlightedIndex((index) => (index === null ? 0 : index + 1))
    } else if (e.key === "ArrowUp") {
      e.preventDefault()
      setHighlightedIndex((index) => index - 1)
    } else if (e.key === "Tab") {
      closeDropdown()
    } else if (e.key === "Escape") {
      if (searchTerm.length === 0) {
        closeDropdown()
        buttonRef.current.focus()
      }
    }
  }

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value)
  }

  return (
    <div className={classnames("dropdown", { "is-open": isOpen })} id={htmlId}>
      <label className="pb-0" htmlFor={htmlId}>
        <span className="screen-reader-text">Country</span>
        <button
          aria-expanded={isOpen}
          aria-haspopup="listbox"
          aria-label={
            currentCountry ? currentCountry.attributes.name : "Select country"
          }
          className="select d-if ai-c c-tint4"
          disabled={disabled}
          onClick={handleToggle}
          onKeyDown={(e) => {
            if (!isOpen && (e.key === "ArrowDown" || e.key === "ArrowUp")) {
              handleToggle()
              e.preventDefault()
            }
          }}
          ref={buttonRef}
          style={{
            borderBottomRightRadius: 0,
            borderTopRightRadius: 0,
            outlineOffset: -1,
            ...(disabled && {
              border: "1px solid var(--color-tint5)",
              backgroundColor: "var(--color-tint9)",
              color: "var(--color-tint4)",
            }),
          }}
          type="button"
        >
          {currentCountry ? (
            <CountryFlag url={currentCountry.attributes.flag_url} />
          ) : (
            "Country"
          )}
        </button>
      </label>
      {isOpen && (
        <div
          className="dropdown__content"
          ref={dropdownRef}
          style={{
            paddingBottom: 10,
            paddingLeft: 12,
            paddingRight: 12,
            paddingTop: 10,
            minWidth: 280,
          }}
        >
          <input
            aria-expanded={true}
            aria-controls={`${htmlId}_listbox`}
            aria-activedescendant={
              highlightedIndex !== null
                ? `${htmlId}_${highlightedIndex}`
                : undefined
            }
            className="sm-input"
            onChange={handleSearchTermChange}
            onKeyDown={handleInputKeyPress}
            placeholder="Search countries"
            ref={inputRef}
            role="combobox"
            style={{
              paddingLeft: 32,
              backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "8px center",
            }}
            type="search"
            value={searchTerm}
          />
          <ul
            role="listbox"
            id={`${htmlId}_listbox`}
            className="m-0 mt-1 p-0 p-r"
            style={{ maxHeight: "15rem", overflowY: "auto" }}
          >
            {countries?.length > 0 ? (
              <>
                {countries.map((country, index) => (
                  <CountryItem
                    country={country}
                    highlighted={index === highlightedIndex}
                    htmlId={`${htmlId}_${index}`}
                    key={country.id}
                    onSelect={(value) => {
                      onChange(value)
                      setIsOpen(false)
                    }}
                    selected={country.id === value}
                  />
                ))}
              </>
            ) : (
              <span className="d-b" style={{ margin: "0.5rem" }}>
                No countries found
              </span>
            )}
          </ul>
        </div>
      )}
    </div>
  )
}
