import { createRoot } from "react-dom/client"

export const launchAsyncModal = (Component, props) => {
  return new Promise((resolve) => {
    const dialogContainer = document.createElement("div")
    const dialogRoot = createRoot(dialogContainer)

    const cleanup = () => {
      dialogRoot.unmount(dialogContainer)
    }

    const handleCancel = () => {
      cleanup()
      resolve(false)
    }

    const handleConfirm = () => {
      cleanup()
      resolve(true)
    }

    dialogRoot.render(
      <Component
        {...props}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
      />,
      dialogContainer,
    )
  })
}
