import { useContext } from "react"
import _ from "lodash"
import { Helmet } from "react-helmet-async"
import { useSession } from "source/shared/hooks/useSession"
import { NarrowLayout } from "source/Layout"
import PendingSection from "./PendingSection"
import UpcomingSection from "./UpcomingSection"
import useMyChurchCenter from "source/myChurchCenter/hooks/useMyChurchCenter"
import { WebBootContext } from "source/publishing/WebBoot"
import { RespondsToBlockoutNotification } from "source/services/shared"

export function Schedule() {
  useSession(true)

  const { abilities: { services: servicesAbilities } = {} } =
    useContext(WebBootContext)

  const {
    data: { activityDates, pendingItems },
    refetch: refreshSchedule,
  } = useMyChurchCenter()

  const hasServicesAbilities = _.some(servicesAbilities)
  const hasPendingItems = _.some(pendingItems)

  return (
    <NarrowLayout>
      <Helmet title="Schedule" />
      <RespondsToBlockoutNotification />
      <PendingSection
        pendingItems={pendingItems}
        refreshSchedule={refreshSchedule}
        showOptionsDropdown={hasPendingItems}
        hasServicesAbilities={hasServicesAbilities}
      />
      <UpcomingSection
        activityDates={activityDates}
        showOptionsDropdown={!hasPendingItems}
        hasServicesAbilities={hasServicesAbilities}
      />
    </NarrowLayout>
  )
}
