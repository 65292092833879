import { Suspense } from "react"
import { Navigate, useLocation } from "react-router-dom"
import { arrayOf, bool, node, shape, string } from "prop-types"
import InfiniteScroll from "react-infinite-scroller"
import { Loading, Icon } from "source/shared/components"
import { useQRCode } from "source/shared/QRCode"
import { useSession } from "source/shared/hooks/useSession"
import { ApplicantAlerts } from "source/groups/components/ApplicantAlerts"
import {
  useInfiniteLoadingGroupTypes,
  useFirstPageOfGroupTypes,
} from "source/groups/hooks/useGroupTypes"
import { MyGroupsHeader } from "./components/MyGroupsHeader"
import {
  GroupTypeRow,
  GroupTypeWithoutGroupsRow,
} from "./components/GroupTypeRow"

export default function Entry() {
  useQRCode()

  const { state: locationState } = useLocation()
  const {
    meta: { authenticated },
  } = useSession()

  return (
    <>
      {locationState?.type && <ApplicantAlerts />}
      <Suspense fallback={<Loading />}>
        <MaybeRedirectToOnlyGroupTypePage>
          {authenticated && <MyGroupsHeader />}
          <GroupTypesList />
        </MaybeRedirectToOnlyGroupTypePage>
      </Suspense>
    </>
  )
}

function GroupTypesList() {
  const {
    records: groupTypes,
    hasMore,
    loadMore,
  } = useInfiniteLoadingGroupTypes()

  const hasGroups = groupTypes && groupTypes.length > 0

  return hasGroups ? (
    <GroupTypes groupTypes={groupTypes} hasMore={hasMore} loadMore={loadMore} />
  ) : (
    <NoPublishedGroups />
  )
}

function MaybeRedirectToOnlyGroupTypePage({ children }) {
  const {
    meta: { authenticated },
  } = useSession()

  const { data: groupTypes, meta } = useFirstPageOfGroupTypes()

  const totalGroupTypeCount = meta?.total_count || 0

  if (authenticated || totalGroupTypeCount !== 1) return children

  const { church_center_web_url } = groupTypes[0].attributes
  const groupTypePath = new URL(church_center_web_url).pathname

  return <Navigate to={groupTypePath} replace />
}
MaybeRedirectToOnlyGroupTypePage.propTypes = {
  children: node,
}

function NoPublishedGroups() {
  return (
    <div className="ta-c c-tint2 fs-2">
      <div className="mb-1">
        <Icon symbol="churchCenter#groups-o" />
      </div>
      We don&apos;t have any groups available to browse yet
    </div>
  )
}

function GroupTypes({ groupTypes, hasMore, loadMore }) {
  return (
    <InfiniteScroll
      hasMore={hasMore}
      loadMore={loadMore}
      loader={<Loading key="loading" />}
    >
      {groupTypes.map((groupType) => (
        <Suspense
          key={groupType.id}
          fallback={<GroupTypeWithoutGroupsRow groupType={groupType} />}
        >
          <GroupTypeRow groupType={groupType} />
        </Suspense>
      ))}
    </InfiniteScroll>
  )
}
GroupTypes.propTypes = {
  groupTypes: arrayOf(
    shape({
      id: string.isRequired,
      attributes: shape({
        name: string.isRequired,
        description: string.isRequired,
        church_center_web_url: string.isRequired,
      }).isRequired,
    }),
  ).isRequired,
  hasMore: bool.isRequired,
  loadMore: bool.isRequired,
}
