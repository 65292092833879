import { func, string } from "prop-types"

CancelButton.propTypes = {
  handleCancel: func.isRequired,
  customStyles: string,
}

export function CancelButton({ handleCancel, customStyles = "" }) {
  return (
    <button
      className={`compact-btn btn text-btn mr-2 ${customStyles}`}
      onClick={handleCancel}
      type="button"
    >
      Cancel
    </button>
  )
}
