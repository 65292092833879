import { bool, shape, string } from "prop-types"
import { Card } from "source/shared/components"
import { capitalize } from "lodash"

Group.propTypes = {
  group: shape({
    attributes: shape({
      name: string.isRequired,
      church_center_web_url: string.isRequired,
      header_image: shape({ medium: string.isRequired }).isRequired,
      enrollment_status: string.isRequired,
      schedule: string,
      members_are_confidential: bool.isRequired,
    }).isRequired,
  }),
}

export function Group({ group }) {
  const {
    church_center_web_url,
    header_image,
    name,
    schedule,
    enrollment_status,
    members_are_confidential,
  } = group.attributes
  return (
    <Card
      link={church_center_web_url}
      poster={header_image.medium}
      posterAltText={`Logo for ${name}`}
      heading={name}
      description={schedule}
      badges={[
        {
          label: capitalize(enrollment_status),
          theme: enrollment_status === "open" ? "success" : "default",
        },
        members_are_confidential && {
          label: "Members are confidential",
          theme: "confidential",
        },
      ]}
    />
  )
}
