import { useMemo, useState } from "react"
import { useParams, useLocation } from "react-router-dom"
import dayjs from "dayjs"
import { useApiRead } from "source/shared/SessionApiResource"
import { Library } from "source/publishing/sermons/Channel"
import {
  BreadcrumbNavigation,
  BreadcrumbPage,
  BreadcrumbDividerIcon,
} from "source/shared/components"
import useNotifyNativeLoaded from "source/shared/hooks/useNotifyNativeLoaded"
import { Error404 } from "source/shared/components/Error404"
import { useFlipperFeatureEnabled } from "source/shared/flipperFeatures"
import NativeHeader from "./shared/NativeHeader"
import { EpisodeFilterPanel } from "./shared/EpisodeFilterPanel"

export function EpisodeList() {
  const { channelId } = useParams()
  const location = useLocation()
  const response = useApiRead(`/publishing/v2/channels/${channelId}/`)
  const episodeFiltersEnabled = useFlipperFeatureEnabled(
    "ROLLOUT_episode_filters",
  )
  const [totalEpisodeCount, setTotalEpisodeCount] = useState()

  useNotifyNativeLoaded()

  const filters = useMemo(() => {
    const params = new URLSearchParams(location.search)
    return {
      date: params.get("date") || "",
      series: params.get("series") || "",
      speaker: params.get("speaker") || "",
      speaker_type: params.get("speaker_type") || "",
    }
  }, [location.search])

  const queryParams = {
    "where[channel_id]": channelId,
    filter: "published_to_library",
    order: "-published_to_library_at",
  }

  if (filters.series) {
    queryParams["where[series_id]"] = filters.series
  }

  if (filters.speaker) {
    queryParams["where[speaker_id]"] = filters.speaker
  }

  if (filters.speaker_type) {
    queryParams["where[speaker_type]"] = filters.speaker_type
  }

  if (filters.date) {
    const [year, month] = filters.date.split("-")
    const firstDay = dayjs(`${year}-${month || "01"}-01`)
      .startOf(month ? "month" : "year")
      .format("YYYY-MM-DD")
    const lastDay = dayjs(`${year}-${month || "12"}-01`)
      .endOf(month ? "month" : "year")
      .format("YYYY-MM-DD")
    queryParams["where[published_to_library_at][gte]"] = firstDay
    queryParams["where[published_to_library_at][lte]"] = lastDay
  }

  if (response.errors?.[0]?.status === "404") return <Error404 />

  const channel = response.data
  const libraryKey = `${filters.series || "all-series"}-${filters.speaker || "all-speakers"}-${filters.date || "all-dates"}`

  return (
    <div className="d-f fd-c g-2">
      <BreadcrumbNavigation>
        <BreadcrumbPage
          linkUrl={`/channels/${channelId}`}
          pageText={channel.attributes.name}
        />
        <BreadcrumbDividerIcon />
        <BreadcrumbPage pageText="View all" isActive />
      </BreadcrumbNavigation>
      <NativeHeader
        title={channel.attributes.name}
        rightType={"share"}
        shareUrl={`${channel.attributes.church_center_url}/episodes`}
      />
      {episodeFiltersEnabled && (
        <EpisodeFilterPanel
          channelId={channelId}
          episodeCount={totalEpisodeCount}
        />
      )}
      <Library
        key={libraryKey}
        queryParams={queryParams}
        onTotalCountChange={(count) => {
          setTotalEpisodeCount(count)
        }}
      />
    </div>
  )
}
