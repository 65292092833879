import classNames from "classnames"
import { func, bool } from "prop-types"
import { paymentMethodProps } from "../payment-method-prop-types"
import { PaymentMethodIcon, LastFour } from "source/payments/components"

export const PaymentMethodSelectorButton = ({
  paymentMethod: pm,
  onClick,
  selected,
}) => {
  const isCard = pm.methodSupertype === "card"

  return (
    <div
      role="button"
      className={classNames("paymentMethod--row", { selected: selected })}
      tabIndex={0}
      onClick={() => onClick()}
      onKeyDown={(e) => e.key === "Enter" && onClick()}
    >
      <PaymentMethodIcon brand={pm.brand} isCard={isCard} />
      <span className="fw-500">{pm.brand}</span>
      <LastFour last4={pm.last4} />
      {isCard && <span className="fs-4 c-tint2">{pm.expiration}</span>}
    </div>
  )
}
PaymentMethodSelectorButton.propTypes = {
  paymentMethod: paymentMethodProps.isRequired,
  onClick: func,
  selected: bool,
}
