import StripeWrapper from "source/registrations/StripeWrapper"
import StripeForm from "source/registrations/StripeForm"
import { Loading } from "source/shared/components"
import { Button } from "@planningcenter/doxy-web"
import ReviewPaymentType from "source/registrations/reservations/ReviewPaymentType"
import { useOrganization } from "source/registrations/hooks/useOrganization"
import { useState } from "react"
import { api } from "source/registrations/api/client"
import { usePaymentIntentMutation } from "source/registrations/hooks/usePaymentIntent"
import { usePaymentIntent } from "source/registrations/hooks/usePaymentIntent"
import { object, func } from "prop-types"
import { useJolt } from "source/shared/hooks/useJolt"
import PaymentProcessingProgressBar from "source/registrations/PaymentProcessingProgressBar"

const PaymentForm = ({
  registration,
  registrationInvoice,
  onOpenForm,
  onPaymentSave,
  onWrapperError,
  stripe,
}) => {
  const { balanceDue, balanceDueCents, minimumDueCents } = registrationInvoice
  const {
    data: {
      paymentIntegration: { isConnected, isTestMode },
    },
  } = useOrganization()
  const {
    allowCreditCardPayments,
    allowAchPayments,
    allowMinimumDeposits,
    currency,
  } = registration.event
  const { error: paymentIntentError, data: paymentIntent } = usePaymentIntent(
    registration.id,
    {
      retry: false,
    },
  )
  const mutation = usePaymentIntentMutation(registration.id, paymentIntent)
  const stillNeedMinimum = allowMinimumDeposits ? minimumDueCents > 0 : false

  const [error, setError] = useState(paymentIntentError?.detail)
  const [paymentType, setPaymentType] = useState("full")
  const [isProcessing, setIsProcessing] = useState(false)
  const [canMakePayment, setCanMakePayment] = useState(true)
  const [isCaptureProcessing, setIsCaptureProcessing] = useState(false)
  const [paymentComplete, setPaymentComplete] = useState(false)

  const mutatePaymentIntent = async (amount) => {
    try {
      await mutation.mutateAsync({ amount })
      setError(null)
      setCanMakePayment(true)
    } catch (error) {
      setError(error.detail)
      setCanMakePayment(false)
    }
  }

  const handleOtherAmountChange = async (value) => {
    let error = null
    const otherAmount = Number(value) * 100

    if (!value) {
      error = "Other amount can not be blank"
    } else if (otherAmount < 50) {
      error = `Other amount must be at least ${currency}0.50`
    } else if (otherAmount < minimumDueCents || otherAmount > balanceDueCents) {
      error = "Other amount must be between minimum and full amount"
    }

    if (error) {
      setError(error)
      setCanMakePayment(false)
    } else {
      setError(null)
      mutatePaymentIntent(otherAmount)
    }
  }

  useJolt(
    registration ? `${registration.links?.joltChannel}/subscribe` : null,
    registration?.joltChannel?.id,
    "registration.completed",

    () => {
      setPaymentComplete(true)
    },
  )

  const handlePaymentComplete = () => {
    setPaymentType("full")
    onOpenForm()
    setIsProcessing(false)
    setIsCaptureProcessing(false)
    setPaymentComplete(false)
    onPaymentSave()
  }

  const handlePaymentTypeChange = async (value) => {
    if (value === "other") setCanMakePayment(false)
    let newAmount = null

    if (value === "full") {
      newAmount = balanceDueCents
    } else if (value === "minimum") {
      newAmount = minimumDueCents
    }

    setPaymentType(value)

    if (newAmount) {
      mutatePaymentIntent(newAmount)
    }
  }

  const handlePaymentSave = (stripeResponse) => {
    const path = `/registrations/v2/registrations/${registration.id}/capture_payment`
    return api
      .post(path, {
        data: {
          attributes: {
            status: stripeResponse?.status,
            amount: stripeResponse?.amount,
            paymentIntentId: stripeResponse?.id,
            method: "creditCard",
          },
        },
      })
      .then(({ errors }) => {
        setPaymentType("full")
        onOpenForm()
        setIsProcessing(false)
        onPaymentSave()
        if (errors) {
          onWrapperError(errors[0])
        }
      })
  }

  const handleCancel = () => {
    setPaymentType("full")
    onOpenForm()
  }

  const handleIsProcessing = (is) => {
    setIsProcessing(is)
  }

  const paymentFormOverlayStyles = {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1,
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }

  return (
    <div>
      {allowMinimumDeposits && (
        <ReviewPaymentType
          onChange={handlePaymentTypeChange}
          onOtherAmountChange={handleOtherAmountChange}
          paymentType={paymentType}
          totalDueString={balanceDue}
          minimumDue={minimumDueCents}
          minimumDueString={registration.minimumDueString}
          isPaymentUpdating={mutation.isLoading}
          onCanMakePayment={setCanMakePayment}
          currencySymbol={currency}
          showMinimumOption={stillNeedMinimum}
          showLaterOption={false}
        />
      )}
      <StripeWrapper
        stripe={stripe}
        paymentIntent={paymentIntent}
        error={error || paymentIntentError}
      >
        {isConnected && (allowCreditCardPayments || allowAchPayments) && (
          <>
            {!stripe && !paymentIntent?.clientSecret ? (
              <Loading />
            ) : (
              <div className="mt-3 p-r">
                {isCaptureProcessing && (
                  <div css={paymentFormOverlayStyles}>
                    <PaymentProcessingProgressBar
                      paymentComplete={paymentComplete}
                      onPaymentComplete={handlePaymentComplete}
                    />
                  </div>
                )}
                <StripeForm
                  useStripeWebhooks={registration.useStripeWebhooks}
                  responsiblePerson={registration.responsiblePerson}
                  onPaymentSave={handlePaymentSave}
                  onIsProcessing={handleIsProcessing}
                  amount={paymentIntent?.amount}
                  onError={setError}
                  isTestMode={isTestMode}
                  onIsCaptureProcessing={setIsCaptureProcessing}
                />
              </div>
            )}
          </>
        )}
      </StripeWrapper>
      {stripe && paymentIntent?.clientSecret && (
        <div className="mt-2 d-f ai-c jc-sb fw-500">
          <Button onClick={handleCancel} text="Cancel" variant="outline" />
          <button
            className="btn"
            form="paymentForm"
            disabled={!canMakePayment || mutation.isLoading || isProcessing}
          >
            {isProcessing
              ? "Processing payment..."
              : `Pay ${
                  paymentIntent?.amount
                    ? `${paymentIntent.amountFormatted}`
                    : ""
                }`}
          </button>
        </div>
      )}
    </div>
  )
}

PaymentForm.propTypes = {
  registration: object,
  registrationInvoice: object,
  onOpenForm: func,
  onPaymentSave: func,
  stripe: object,
  onWrapperError: func,
}

export default PaymentForm
