import { useEffect, useState } from "react"
import { api } from "source/registrations/api/client"
import { PAYMENT_URLS } from "source/payments/utils"

export function usePaymentMethods() {
  const [loadingPaymentMethods, setLoadingPaymentMethods] = useState(false)
  const [paymentMethods, setPaymentMethods] = useState([])
  const [paymentMethodError, setPaymentMethodError] = useState(null)
  const [lastChanged, setLastChanged] = useState(null)

  useEffect(() => {
    const getPaymentMethods = async () => {
      setLoadingPaymentMethods(true)
      try {
        const { data } = await api.get(PAYMENT_URLS.PAYMENT_METHODS)
        setPaymentMethods(data)
        setPaymentMethodError(null)
      } catch (error) {
        setPaymentMethodError(error)
      } finally {
        setLoadingPaymentMethods(false)
      }
    }

    getPaymentMethods()
  }, [lastChanged])

  return {
    paymentMethods,
    loadingPaymentMethods,
    paymentMethodError,
    setLastChanged,
  }
}
