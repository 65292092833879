import { Suspense, useContext, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { id, number, oneOf, string } from "source/shared/prop_types"
import { CurrentGroupContext } from "source/groups/my/groups"
import {
  useApiRead,
  SessionApiResource,
} from "source/shared/SessionApiResource"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import { useCacheHydrate } from "source/shared/react-cache-but-its-a-context"
import TabView from "source/groups/my/groups/TabView"
import { Loading, ActionBar, AlertMessage } from "source/shared/components"
import { Date } from "source/shared/DatetimeFmt"
import spinner from "source/svg/spinner.svg"
import { pendingGroupApplicationsUrl } from "source/groups/my/groups/applications"
import { Heading } from "@planningcenter/doxy-web"
import PersonInfo from "source/groups/my/groups/PersonInfo"
import { Modal } from "source/shared/components/Modal"

function applicationApiUrl(group_id, application_id) {
  return `/groups/v2/me/groups/${group_id}/applications/${application_id}?include=person`
}

export default function ApplicationShow() {
  const { applicationId } = useParams()

  return (
    <TabView>
      <Suspense fallback={<Loading />}>
        <Application id={applicationId} />
      </Suspense>
    </TabView>
  )
}

const NOPE = "NOPE"
const REJECTING = "REJECTING"
const APPROVING = "APPROVING"

function refetchApplications(group) {
  return sessionApiClient
    .get(pendingGroupApplicationsUrl(group.id))
    .then(({ meta }) =>
      meta.total_count === 0
        ? `${group.base_path}/members`
        : `${group.base_path}/applications`,
    )
}

function Application({ id }) {
  const { reloadMemberships, ...group } = useContext(CurrentGroupContext)
  const response = useApiRead(applicationApiUrl(group.id, id))
  const navigate = useNavigate()
  const [inFlight, setInFlight] = useState(NOPE)
  const [sendCustomEmail, setSendCustomEmail] = useState(false)
  const [customWelcomeMessage, setCustomWelcomeMessage] = useState("")
  const reloadApplications = useCacheHydrate(
    SessionApiResource,
    pendingGroupApplicationsUrl(group.id),
  )

  const application = response.data
  const person = response.included.find(({ type, id }) => {
    const { data } = application.relationships.person
    return data.type === type && data.id === id
  })
  const requestInProgress = inFlight === APPROVING || inFlight === REJECTING

  const { avatar_url, email_address, name, phone_number } = person.attributes
  const { applied_at, approval_status, message } = application.attributes
  const alreadyHandled =
    approval_status === "approved" || approval_status === "rejected"

  return (
    <article key={application.id} className="d-f fd-c g-3">
      <ThisApplicationHasAlreadyBeenHandled
        name={name}
        status={approval_status}
      />
      <PersonInfo
        name={name}
        emailAddress={email_address}
        phoneNumber={phone_number}
      >
        <PersonInfo.HeaderRow>
          <PersonInfo.Avatar url={avatar_url} />
          <PersonInfo.HeaderColumn>
            <PersonInfo.Name />
            <PersonInfo.Leader />
          </PersonInfo.HeaderColumn>
        </PersonInfo.HeaderRow>
        <PersonInfo.Contact>
          <PersonInfo.EmailAddress />
          <PersonInfo.PhoneNumber />
        </PersonInfo.Contact>
      </PersonInfo>
      {message && (
        <section className="d-f fd-c g-1">
          <Heading level={2} size={3} text="Message" />
          <p className="m-0">{message}</p>
        </section>
      )}
      <section className="d-f fd-c g-1">
        <Heading level={2} size={3} text="Manage request" />

        <ActionBar.Pane>
          <ActionBar.Column>
            <ActionBar.Description>
              Requested on <Date start={applied_at} />
            </ActionBar.Description>
          </ActionBar.Column>
          <ActionBar.Action>
            <div className="d-f jc-sb ai-c g-1">
              <button
                className="btn secondary-btn minor-btn destroy-btn"
                disabled={requestInProgress || alreadyHandled}
                onClick={() => {
                  setInFlight(REJECTING)
                  return sessionApiClient
                    .post(application.links.reject)
                    .then(() => refetchApplications(group))
                    .then((nextPath) => navigate(nextPath))
                    .then(() => reloadApplications())
                }}
              >
                <Spinner
                  alt="Rejecting"
                  opacity={inFlight === REJECTING ? 1 : 0}
                />
                <span style={{ opacity: inFlight === REJECTING ? 0 : 1 }}>
                  Delete request
                </span>
              </button>
              <Modal>
                <Modal.Trigger
                  className="btn minor-btn"
                  dataAttributes={{
                    disabled: requestInProgress || alreadyHandled,
                  }}
                >
                  <Spinner
                    alt="Approving"
                    opacity={inFlight === APPROVING ? 1 : 0}
                  />
                  <span style={{ opacity: inFlight === APPROVING ? 0 : 1 }}>
                    Add to group
                  </span>
                </Modal.Trigger>
                <Modal.Show>
                  <Modal.Header>Adding {name} to the group</Modal.Header>
                  <Modal.Body>
                    <div>
                      <input
                        type="checkbox"
                        id="send-custom-email"
                        className="checkbox"
                        checked={sendCustomEmail}
                        onChange={() => setSendCustomEmail(!sendCustomEmail)}
                      />
                      <label
                        htmlFor="send-custom-email"
                        className="checkbox-label pb-0"
                      >
                        Include personal message in welcome email?
                      </label>
                    </div>
                    {sendCustomEmail && (
                      <div className="mt-2">
                        <textarea
                          className="w-100%"
                          placeholder="Custom message"
                          value={customWelcomeMessage}
                          onChange={(e) =>
                            setCustomWelcomeMessage(e.target.value)
                          }
                        />
                      </div>
                    )}
                    <Modal.Footer>
                      <Modal.Close>Cancel</Modal.Close>
                      <button
                        className="btn"
                        disabled={requestInProgress || alreadyHandled}
                        onClick={() => {
                          setInFlight(APPROVING)
                          return sessionApiClient
                            .post(application.links.approve, {
                              data: {
                                attributes: {
                                  custom_welcome_message: sendCustomEmail
                                    ? customWelcomeMessage
                                    : null,
                                },
                              },
                            })
                            .then(() => refetchApplications(group))
                            .then((nextPath) => navigate(nextPath))
                            .then(() => {
                              reloadMemberships()
                              reloadApplications()
                            })
                            .catch(() => {
                              setInFlight(NOPE)
                              alert("Something went wrong, please try again.")
                            })
                        }}
                      >
                        <Spinner
                          alt="Approving"
                          opacity={inFlight === APPROVING ? 1 : 0}
                        />
                        <span
                          style={{ opacity: inFlight === APPROVING ? 0 : 1 }}
                        >
                          Add to group
                        </span>
                      </button>
                    </Modal.Footer>
                  </Modal.Body>
                </Modal.Show>
              </Modal>
            </div>
          </ActionBar.Action>
        </ActionBar.Pane>
      </section>
    </article>
  )
}
Application.propTypes = {
  id: id.isRequired,
}

function Spinner({ alt, opacity }) {
  return (
    <img
      src={spinner}
      alt={alt}
      style={{ height: "1em", width: "1em", position: "absolute", opacity }}
    />
  )
}
Spinner.propTypes = {
  alt: string.isRequired,
  opacity: number.isRequired,
}

function ThisApplicationHasAlreadyBeenHandled({ name, status }) {
  if (status === "pending") return null

  return (
    <AlertMessage type="info" icon="general#info-circle">
      {name} has already been {status}
    </AlertMessage>
  )
}
ThisApplicationHasAlreadyBeenHandled.propTypes = {
  name: string.isRequired,
  status: oneOf(["approved", "pending", "rejected"]).isRequired,
}
