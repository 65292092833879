import { bool } from "prop-types"
import { useParams } from "react-router-dom"
import AuthenticatedConfirmation from "./AuthenticatedConfirmation"
import GuestConfirmation from "./GuestConfirmation"
import { useSession } from "source/shared/hooks/useSession"

const Confirmation = ({
  isNotificationShowing = false,
  isCancelation = false,
}) => {
  const { registrationId } = useParams()
  const {
    meta: { authenticated },
  } = useSession()

  return authenticated ? (
    <AuthenticatedConfirmation
      {...{ registrationId, isNotificationShowing, isCancelation }}
    />
  ) : (
    <GuestConfirmation />
  )
}

Confirmation.propTypes = {
  isNotificationShowing: bool,
  isCancelation: bool,
}

export default Confirmation
