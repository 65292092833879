import {
  useQuery,
  splitQueryParams,
  queryFn,
} from "source/registrations/utils/queryCache"

export const useOrganization = (options = {}) => {
  const path = "/payments/v2"
  const { params, queryOptions } = splitQueryParams(options)

  return useQuery({
    queryFn,
    queryKey: [path, params],
    ...queryOptions,
  })
}

export const useOrganizationFlag = (name, options) => {
  const { data } = useOrganization(options)
  return data?.productFeatures[name] || false
}
