import { string } from "prop-types"

export const LastFour = ({ last4, styles }) => (
  <div
    css={{
      fontSize: "14px",
      color: "var(--color-tint2)",
      fontFamily: "monospace",
      ...styles,
    }}
  >
    &#8226;&#8226;&#8226;&#8226;{last4.replace(/\*/g, "")}
  </div>
)
LastFour.propTypes = { last4: string.isRequired, styles: string }
