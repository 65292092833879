import React from "react"
import { css } from "@emotion/react"
import { Badge, Heading } from "@planningcenter/doxy-web"
import { Icon } from "source/shared/components"

interface BadgeProps {
  label: string
  theme?:
    | "success"
    | "warning"
    | "error"
    | "featured"
    | "default"
    | "confidential"
}

type BadgeProp = false | BadgeProps

type ExcludesFalse = <T>(x: T | false) => x is T

export interface CardProps {
  /** Component or tag to render card as */
  as?: React.ElementType
  /** Badge theme and label */
  badges?: BadgeProp[]
  children?: React.ReactNode
  /** Description text */
  description?: string | React.ReactNode
  /** Footnote text */
  footnote?: string
  /** Heading text */
  heading?: string
  /** Card layout */
  layout?: "column" | "row"
  /** Link URL or component props */
  link: string | { as?: React.ElementType; href?: string }
  /** Component or image URL to render as poster */
  poster: string | React.ReactNode
  /** Poster alt text */
  posterAltText?: string
  /** Poster aspect ratio */
  posterAspectRatio?: "16 / 9" | "4 / 3"
}

/**
 * Doxy card component
 */
function Card({
  as = "article",
  badges = [],
  children,
  description,
  footnote,
  heading,
  layout = "column",
  link,
  poster,
  posterAltText = "",
  posterAspectRatio = "16 / 9",
}: CardProps) {
  const Wrapper = as
  const InnerLink = (typeof link === "object" && link?.as) || "a"
  const linkProps =
    typeof link === "string" ? { as: undefined, href: undefined } : link
  const isRowLayout = layout === "row"
  if (link && typeof link === "string") {
    linkProps.href = link
  }
  return (
    <Wrapper css={[styles.card, isRowLayout && styles.cardHorizontal]}>
      <InnerLink {...linkProps}>
        <div css={[styles.poster, isRowLayout && styles.posterHorizontal]}>
          {typeof poster === "string" ? (
            <img
              loading="lazy"
              src={poster}
              alt={posterAltText}
              style={{ aspectRatio: posterAspectRatio }}
            />
          ) : (
            poster
          )}
        </div>
        <div
          css={[
            styles.cardContentWrapper,
            isRowLayout && styles.cardContentWrapperHorizontal,
          ]}
        >
          <div css={[styles.cardContent]}>
            {heading && <Card.Heading text={heading} />}
            {description && <Card.Description>{description}</Card.Description>}
            {footnote && <Card.HeadingUppercase text={footnote} />}
            {children}
          </div>
          <CardBadges badges={badges} />
        </div>
      </InnerLink>
    </Wrapper>
  )
}

function CardBadges({ badges = [] }: { badges: BadgeProp[] }) {
  const badgesWithoutFalsey = badges.filter(Boolean as any as ExcludesFalse)

  if (badgesWithoutFalsey.length === 0) return null

  return (
    <div css={[styles.cardFooter]}>
      {badgesWithoutFalsey.map(
        ({ theme = "default", label }: BadgeProps, index) => {
          if (theme === "featured") {
            return (
              <div key={index} className="badge">
                <Icon symbol="general#closed-star" css={styles.featured} />
                {label}
              </div>
            )
          } else if (theme === "confidential") {
            return (
              <div key={index} className="badge">
                <Icon symbol="general#hide-eye" css={styles.confidential} />
                {label}
              </div>
            )
          } else {
            return <Badge key={index} theme={theme} text={label} />
          }
        },
      )}
    </div>
  )
}

const CardHeading = ({ text }: { text: string }) => (
  <Heading level={3} size={4} text={text} />
)

const CardHeadingUppercase = ({ text }: { text: string }) => (
  <h4 css={styles.cardHeadingUppercase}>{text}</h4>
)

const CardDescription = ({ children }: { children: React.ReactNode }) => (
  <p css={styles.cardDescription}>{children}</p>
)

Card.Heading = CardHeading
Card.HeadingUppercase = CardHeadingUppercase
Card.Description = CardDescription

const styles = {
  card: css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    &:before {
      border-radius: 8px;
      bottom: 0;
      box-shadow: inset 0 0 0 1px var(--color-tint0);
      content: "";
      display: block;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      pointer-events: none;
      z-index: 5;
      opacity: 0.08;
    }

    > * {
      position: relative;
      display: flex;
      flex-direction: column;
      flex: 1;
      border-radius: 8px;
      overflow: hidden;
    }

    img {
      width: 100%;
      display: block;
      aspect-ratio: 16 / 9;
      object-fit: cover;
      object-position: center center;
    }
  `,

  cardHorizontal: css`
    &:before {
      display: none;
    }

    > * {
      display: grid;
      grid-template-columns: minmax(150px, 1fr) 2fr;
      grid-template-rows: auto;
      gap: 12px;
      border-radius: 0;
    }
  `,
  poster: css`
    overflow: hidden;
    > * {
      border-radius: 0;
    }
  `,
  posterHorizontal: css`
    > * {
      border-radius: 8px;
    }
  `,
  cardContentWrapper: css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 12px;
    padding: 12px;
    flex: 1;
  `,
  cardContentWrapperHorizontal: css`
    padding: 0;
    justify-content: flex-start;
  `,
  cardContent: css`
    display: flex;
    flex-direction: column;
    gap: 4px;
  `,

  cardFooter: css`
    display: flex;
    align-items: center;
    gap: 4px;
  `,

  cardDescription: css`
    color: var(--color-tint1);
    font-size: 13px;
    margin-bottom: 0;
  `,
  cardHeadingUppercase: css`
    color: var(--color-tint3);
    font-size: 12px;
    text-transform: uppercase;
  `,
  featured: css`
    color: var(--color-citrine);
    font-size: 14px;
    margin-right: 4px;
  `,
  confidential: css`
    color: var(--color-tint2);
    font-size: 14px;
    margin-right: 4px;
  `,
}

export { Card }
