import { Heading, Grid } from "@planningcenter/doxy-web"
import { useSuspenseQuery } from "@tanstack/react-query"
import moment from "moment"
import {
  EpisodeAPIRecord,
  NoteAPIRecord,
} from "source/publishing/sermons/shared/types"
import { APIResponseWithIncluded } from "source/shared/types"
import { useFlipperFeatureEnabled } from "source/shared/flipperFeatures"
import { Error404 } from "source/shared/components"
import { Link, useParams } from "react-router-dom"
import { Card } from "source/shared/components"
import { colors } from "source/shared/colors"
import { truncate } from "lodash"
import {
  Notes,
  NotesFromNotesApi,
} from "source/publishing/sermons/Episode/Notes"
import { styles as sermonNotesStyles } from "source/publishing/sermons/SermonNotes"
import NativeHeader from "source/publishing/sermons/shared/NativeHeader"
import useReactNativeBridgeComponent from "source/shared/hooks/useReactNativeBridgeComponent"

const NOTES_PER_PAGE_MAXIMUM = 250

export function MyNotes() {
  const response = useNotes()

  const notes = mergeIncludedEpisodeIntoNoteRelationship(response)

  const ROLLOUT_my_notes = useFlipperFeatureEnabled("ROLLOUT_my_notes")

  const { supported: usingNativeHeader } = useReactNativeBridgeComponent({
    component: NativeHeader.nativeComponentName,
  })

  if (!ROLLOUT_my_notes) {
    return <Error404 />
  }

  return (
    <div className="d-f g-2 fd-c">
      {usingNativeHeader ? (
        <NativeHeader title={"My notes"} />
      ) : (
        <Heading level={2} text="My notes" />
      )}
      <Grid columns={1} gap={2}>
        {notes.map((note) => (
          <Note note={note} key={`key-${note.id}`} />
        ))}
      </Grid>
    </div>
  )
}

function Note({ note }: { note: NoteAPIRecordWithEpisodeRelationship }) {
  const { created_at: createdAt, textualized_content: textualizedContent } =
    note.attributes
  const date = moment(createdAt).format("MMMM D, YYYY")
  const episodeTitle = note.relationships.episode.data.attributes.title
  const noteContent = truncate(textualizedContent, {
    length: 100,
    omission: "...",
  })

  return (
    <Card
      link={
        {
          as: Link,
          to: `/me/notes/${note.id}`,
        } as { as: React.ElementType; to: string }
      }
      heading={episodeTitle}
      description={date}
      poster={null}
    >
      <p className="fs-3 mb-0 c-tint1">{noteContent}</p>
    </Card>
  )
}

type NoteAPIRecordWithEpisodeRelationship = NoteAPIRecord & {
  relationships: {
    episode: { data: { id: string; attributes: { title: string } } }
  }
}
function useNotes() {
  const url = "/publishing/v2/notes"
  const params = {
    include: "episode",
    per_page: NOTES_PER_PAGE_MAXIMUM,
    "fields[Note]":
      "textualized_content,deleted_episode_attributes,created_at,episode",
  }

  return useSuspenseQuery<
    APIResponseWithIncluded<
      NoteAPIRecordWithEpisodeRelationship,
      EpisodeAPIRecord
    >
  >({ queryKey: [url, params] })
}

function mergeIncludedEpisodeIntoNoteRelationship({
  data: { data: notes, included },
}: {
  data: {
    data: NoteAPIRecordWithEpisodeRelationship[]
    included: EpisodeAPIRecord[]
  }
}): NoteAPIRecordWithEpisodeRelationship[] {
  return notes.map((note) => {
    const episode = included.find(
      (includedEpisode) =>
        note.relationships.episode?.data?.id === includedEpisode?.id,
    )
    if (!episode) {
      const noteWithDeletedEpisodeAttributesRelationship = {
        ...note,
        relationships: {
          episode: {
            data: {
              id: "-1",
              attributes: { ...note.attributes.deleted_episode_attributes },
            },
          },
        },
      }
      return noteWithDeletedEpisodeAttributesRelationship as NoteAPIRecordWithEpisodeRelationship
    }
    const noteWithHydratedRelationship = {
      ...note,
      relationships: { episode: { data: episode } },
    }

    return noteWithHydratedRelationship
  })
}

export function MyNote() {
  const { noteId } = useParams()

  if (!noteId) {
    return <Error404 />
  }

  return (
    <>
      <NativeHeader title={""} />
      <section css={sermonNotesStyles.container}>
        <Notes.Suspense>
          <NotesFromNotesApi
            noteId={noteId}
            notesWrapperCss={sermonNotesStyles.notesWrapper}
          />
        </Notes.Suspense>
      </section>
    </>
  )
}
