import { useReducer, useContext } from "react"
import { CurrentGroupContext } from "source/groups/my/groups"
import moment from "moment"
import { get as dig } from "lodash"
import { normalizeISODateStringWithTimezoneOffset } from "source/groups/utils/date"

export const SET_ATTRIBUTES = "SET_ATTRIBUTES"
export const SET_REPEATING_ATTRIBUTES = "SET_REPEATING_ATTRIBUTES"
export const SET_INCLUDE_REPEATING = "SET_INCLUDE_REPEATING"
export const SET_BUSY = "SET_BUSY"
export const SET_ERRORS = "SET_ERRORS"

export function reducer(state, action) {
  switch (action.type) {
    case SET_ATTRIBUTES:
      return {
        ...state,
        attributes: {
          ...state.attributes,
          ...action.payload,
        },
      }
    case SET_REPEATING_ATTRIBUTES:
      return {
        ...state,
        repeatingAttributes: {
          ...state.repeatingAttributes,
          ...action.payload,
        },
      }
    case SET_INCLUDE_REPEATING:
      return {
        ...state,
        includeRepeating: action.payload,
      }
    case SET_BUSY:
      return {
        ...state,
        isBusy: action.payload,
      }
    case SET_ERRORS:
      return {
        ...state,
        errors: action.payload.errors,
      }
  }
}

export function useEventFormReducer(currentEvent) {
  const currentGroup = useContext(CurrentGroupContext)
  const defaultAutomatedReminderOffset = currentGroup
    .automated_event_reminder_defaults.enabled
    ? currentGroup.automated_event_reminder_defaults.schedule_offset
    : undefined
  const initialState = {
    attributes: {
      name: dig(currentEvent, "event.attributes.name") || "",
      starts_at:
        normalizeISODateStringWithTimezoneOffset(
          dig(currentEvent, "event.attributes.starts_at"),
        ) || moment().startOf("hour").add(1, "hour").toISOString(true),
      ends_at:
        normalizeISODateStringWithTimezoneOffset(
          dig(currentEvent, "event.attributes.ends_at"),
        ) || moment().startOf("hour").add(2, "hour").toISOString(true),
      description: dig(currentEvent, "event.attributes.description") || "",
      location_type_preference:
        dig(currentEvent, "event.attributes.location_type_preference") ||
        currentGroup.location_type_preference,
      location_id:
        dig(currentEvent, "location.id") || currentGroup.location?.id,
      virtual_location_url:
        dig(currentEvent, "event.attributes.virtual_location_id") ||
        currentGroup.virtual_location_url,
      automated_reminder_desired_at_offset: currentEvent
        ? dig(currentEvent, "next_reminder.attributes.schedule_offset")
        : defaultAutomatedReminderOffset,
      rsvps_enabled: currentEvent
        ? dig(currentEvent, "event.attributes.rsvps_enabled")
        : true,
    },
    repeatingAttributes: {
      frequency_name:
        dig(currentEvent, "repeating_event.attributes.frequency_name") ||
        "never",
      last_occurrence_date: normalizeISODateStringWithTimezoneOffset(
        dig(currentEvent, "repeating_event.attributes.last_occurrence_date"),
      ),
      week_count: dig(currentEvent, "repeating_event.attributes.week_count"),
    },
    includeRepeating: currentEvent ? false : true,
    isBusy: false,
    errors: [],
  }

  return useReducer(reducer, initialState)
}
