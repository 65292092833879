import { useEffect } from "react"
import { string, bool, func } from "prop-types"
import { TextInput } from "@planningcenter/doxy-web"
import { LastFour } from "source/payments/components"

export const VerifyDescriptorCode = ({
  last4,
  testMode,
  orgName,
  setInputValid,
  descriptorCode,
  setDescriptorCode,
}) => {
  useEffect(() => {
    setInputValid(
      descriptorCode.toLowerCase().startsWith("sm") &&
        descriptorCode.length === 6,
    )
  }, [descriptorCode, setInputValid])

  return (
    <>
      <p>
        {orgName} &#40;showing as &quot;PLANNING CENTER&quot;&#41; deposited
        $0.01 to bank account{" "}
        <LastFour
          last4={last4}
          styles={{ display: "inline-block", color: "var(--color-tint1)" }}
        />
        .
      </p>
      <p>
        To complete the ACH verification process, please enter the 6-character
        code that starts with &quot;SM&quot; from that deposit &#40;the code can
        be found as part of the transation description field&#41;.
      </p>
      {testMode && (
        <div className="info-alert alert">
          This account is in test mode. To verify, enter <strong>SM11AA</strong>
          .
        </div>
      )}
      <TextInput
        value={descriptorCode}
        placeholder="Enter the descriptor code. EX: SM1234"
        onChange={(e) => setDescriptorCode(e.target.value)}
      />
    </>
  )
}

VerifyDescriptorCode.propTypes = {
  last4: string.isRequired,
  testMode: bool.isRequired,
  orgName: string.isRequired,
  descriptorCode: string.isRequired,
  setDescriptorCode: func.isRequired,
  setInputValid: func.isRequired,
}
