import { useState, useEffect } from "react"
import { loadStripe } from "@stripe/stripe-js"
import { api } from "source/registrations/api/client"
import { PAYMENT_URLS } from "source/payments/utils"

const stripeOptions = {
  appearance: {
    theme: "stripe",
    variables: {
      borderRadius: "4px",
    },
    rules: {
      ".Input": {
        boxShadow: "inset 0 1px 2px rgb(250, 250, 250)",
        border: "1px solid rgb(224 224 224)",
        padding: "12px",
      },
      ".Input--invalid": {
        border: "1px solid rgb(224 224 224)",
        boxShadow: "inset 0 1px 2px rgb(250, 250, 250)",
      },
      ".Label": {
        fontWeight: 500,
        marginBottom: "8px",
        fontSize: "14px",
        color: "#3d3d3d",
      },
      ".Error": {
        marginTop: "8px",
        color: "rgb(239, 80, 78)",
        fontSize: "16px",
      },
    },
  },
}

const paymentElementOptions = {
  layout: {
    type: "tabs",
    defaultCollapsed: false,
  },
  paymentMethodOrder: ["card"],
  wallets: {
    applePay: "never",
    googlePay: "never",
  },
}

export function useStripeConfig({ publishable_key, account_identifier }) {
  const [stripe, setStripe] = useState(null)
  const [clientSecret, setClientSecret] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchStripe = async () => {
      try {
        const stripeResponse = await loadStripe(publishable_key, {
          stripeAccount: account_identifier,
        })
        setStripe(stripeResponse)

        const { data } = await api.post(PAYMENT_URLS.SETUP_INTENT)
        setClientSecret(data?.clientSecret)

        setError(null)
      } catch (error) {
        setError(error)
        setStripe(null)
        setClientSecret(null)
      } finally {
        setLoading(false)
      }
    }

    fetchStripe()
  }, [publishable_key, account_identifier])

  return {
    stripe,
    stripeOptions: { clientSecret, ...stripeOptions },
    paymentElementOptions,
    loading,
    error,
  }
}
