import { useState } from "react"
import { PaymentElement, useElements } from "@stripe/react-stripe-js"
import { Button } from "@planningcenter/doxy-web"
import { object, func, bool, string } from "prop-types"
import { StripeDisclaimer } from "./StripeDisclaimer"
import { AlertMessage } from "source/shared/components"

export const AddPaymentMethodForm = ({
  paymentElementOptions,
  onSubmit,
  saving,
  error,
}) => {
  const [paymentElementsReady, setPaymentElementsReady] = useState(false)

  const elements = useElements()

  return (
    <>
      <div css={{ minHeight: paymentElementsReady ? "unset" : "443px" }}>
        <PaymentElement
          options={paymentElementOptions}
          onReady={() => setPaymentElementsReady(true)}
        />
      </div>
      {error && (
        <div className="mt-3">
          <AlertMessage type="error">{error}</AlertMessage>
        </div>
      )}
      <div className="mt-3 d-f jc-c">
        <Button
          text="Add payment method"
          variant="primary"
          onClick={() => onSubmit(elements)}
          isLoading={saving}
        />
      </div>
      <div className="mb-2 mt-3">
        <StripeDisclaimer />
      </div>
    </>
  )
}

AddPaymentMethodForm.propTypes = {
  paymentElementOptions: object.isRequired,
  onSubmit: func,
  saving: bool,
  error: string,
}
