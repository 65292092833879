export const PAYMENT_URLS = {
  /**
   * GET
   */
  PAYMENT_METHODS: "/payments/v2/me/payment_methods",

  /**
   * POST
   */
  STORE_PAYMENT_METHOD: "/payments/v2/me/payment_methods",

  /**
   * POST
   */
  SETUP_INTENT: "/payments/v2/me/stripe_setup_intent",

  /**
   * POST
   * @param {string} paymentMethodId
   */
  verfiyPaymentMethod: (paymentMethodId) =>
    `/payments/v2/me/payment_methods/${paymentMethodId}/verify`,

  /**
   * DELETE
   * @param {string} paymentMethodId
   */
  deletePaymentMethod: (paymentMethodId) =>
    `/payments/v2/me/payment_methods/${paymentMethodId}`,

  /**
   * POST
   * @param {string} paymentMethodId
   */
  checkDeletable: (paymentMethodId) =>
    `/payments/v2/me/payment_methods/${paymentMethodId}/deletable`,
}
