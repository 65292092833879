import { shape, string, bool, oneOf } from "prop-types"

export const paymentMethodProps = shape({
  brand: string.isRequired,
  createdAt: string.isRequired,
  expiration: string,
  holderName: string,
  id: string.isRequired,
  isVerified: bool,
  last4: string.isRequired,
  methodSubtype: oneOf([
    "credit",
    "debit",
    "prepaid",
    "unknown",
    "checking",
    "savings",
  ]),
  methodSupertype: oneOf(["card", "bank_account"]).isRequired,
  methodType: oneOf(["card", "us_bank_account", "au_becs_debit"]).isRequired,
  type: string,
  verificationMethod: oneOf([
    "stripe_microdeposit",
    "stripe_connections_instant",
    "stripe_connections_microdeposit_amounts",
    "stripe_connections_microdeposit_descriptor_code",
  ]),
})
