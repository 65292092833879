import moment from "moment-timezone"
import { useCallback } from "react"
import { useCurrentOrganizationTimeZone } from "source/calendar/hooks/useCurrentOrganizationTimeZone"
import { useChurchCenterConfiguration } from "./useChurchCenterConfiguration"

export function useMomentInTimeZone() {
  const timeZone = useCurrentOrganizationTimeZone()
  const { weekStartsOnInt } = useChurchCenterConfiguration()

  return useCallback(
    (date) => {
      moment.updateLocale("en", {
        week: {
          dow: weekStartsOnInt,
        },
      })

      return moment.tz(date, timeZone)
    },
    [timeZone, weekStartsOnInt],
  )
}
