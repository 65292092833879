import { Icon } from "source/shared/components"

export const StripeDisclaimer = () => (
  <div className="d-f c-tint3" css={{ gap: "4px" }}>
    <Icon symbol="general#lock" css={{ fontSize: "24px", height: "16px" }} />
    <em className="fs-5">
      Payment information is TLS encrypted and stored at{" "}
      <a
        href="//stripe.com/"
        className="fw-600"
        target="_blank"
        rel="noopener noreferrer"
        tabIndex="0"
      >
        Stripe{" "}
      </a>
      - a Level 1 PCI compliant payment processor.{" "}
      <span>
        Bank verification powered by{" "}
        <a
          href="//stripe.com"
          className="fw-600"
          target="_blank"
          rel="noopener noreferrer"
          tabIndex="0"
        >
          Stripe
        </a>
        .
      </span>
    </em>
  </div>
)
