import { useContext } from "react"
import PropTypes from "prop-types"
import { useNavigate } from "react-router-dom"
import { Button } from "@planningcenter/doxy-web"
import { CurrentGroupContext } from "source/groups/my/groups"
import { CancelButton } from "source/groups/components/CancelButton"

Buttons.propTypes = {
  state: PropTypes.object.isRequired,
}
export function Buttons({ state }) {
  const navigate = useNavigate()
  const group = useContext(CurrentGroupContext)

  const handleCancel = (e) => {
    e.preventDefault()
    const confirmText = "Are you sure you'd like to cancel?"
    if (window.confirm(confirmText)) {
      navigate(`${group.base_path}/resources`)
    }
  }

  return (
    <div className="d-f fw-w ai-c jc-c jc-fe@md mb-2 mt-4">
      <CancelButton handleCancel={handleCancel} customStyles="m-1" />
      <Button
        size="lg"
        text="Save resource"
        theme="primary"
        type="submit"
        variant="filled"
        isLoading={state.isBusy}
      />
    </div>
  )
}
