import { Fragment, useContext } from "react"
import { Route, Routes } from "react-router-dom"
import { useCacheHydrate } from "source/shared/react-cache-but-its-a-context"
import { WebBootContext } from "source/publishing/WebBoot"
import { useSession } from "source/shared/hooks/useSession"
import { MyProfileLinks } from "source/myChurchCenter/Profile/MyProfileLinks"
import {
  BreadcrumbNavigationWithMe,
  BreadcrumbPage,
} from "source/shared/components"
import {
  useApiRead,
  SessionApiResource,
} from "source/shared/SessionApiResource"
import { useOrganizationFlag } from "source/payments/hooks/useOrganization"
import { DraftModeProfilePane } from "source/publishing/DraftMode"
import { useDirectoryStatus } from "source/directory/hooks/useDirectoryStatus"
import { ProfileAvatar } from "./ProfileAvatar"
import { ContactAndInfo } from "./ContactAndInfo"
import { PaymentMethodsPage } from "../../payments/components/PaymentMethodsPage"
import { Heading } from "@planningcenter/doxy-web"
const ME_PATH =
  "/people/v2/me?include=emails,phone_numbers,addresses,marital_status,school"

export function Profile() {
  const enableSavedPaymentMethods = useOrganizationFlag(
    "useSavedPaymentMethods",
  )

  return (
    <Routes>
      <Route path="/contact-and-info/*" element={<ContactAndInfo />} />
      {enableSavedPaymentMethods && (
        <Route path="/payment-methods/*" element={<PaymentMethodsPage />} />
      )}
      <Route path="*" element={<ProfileMenu />} />
    </Routes>
  )
}

function ProfileMenu() {
  // TODO useNarrowLayout()
  useSession(true)
  const profile = useApiRead(ME_PATH)

  const reloadProfile = useCacheHydrate(SessionApiResource, ME_PATH)
  const name = profile.data.attributes.name
  const avatar =
    profile.data.attributes.avatar_url ||
    profile.data.attributes.demographic_avatar_url

  const enableSavedPaymentMethods = useOrganizationFlag(
    "useSavedPaymentMethods",
  )

  return (
    <Fragment>
      <BreadcrumbNavigationWithMe>
        <BreadcrumbPage pageText="My profile & settings" isActive />
      </BreadcrumbNavigationWithMe>
      <div className="d-f ai-c mb-2">
        <ProfileAvatar
          avatar={avatar}
          name={name}
          personPath={ME_PATH}
          reloadProfile={reloadProfile}
        />
        <Heading level={1} size={2} text={name} />
      </div>
      <MyProfileLinks
        {...useProfileBuiltFromWebBootContext(enableSavedPaymentMethods)}
      />
      <DraftModeProfilePane />
    </Fragment>
  )
}

function useProfileBuiltFromWebBootContext(enableSavedPaymentMethods) {
  const DIRECTORY_STATUS_NO_ACCESS = "no_access"

  const person = useSession(false)
  const {
    abilities: {
      people: {
        add_or_remove_household_members: addOrRemoveHouseholdMembersEnabled,
      },
    },
    builtInPages,
    myChurchCenterEnabled,
  } = useContext(WebBootContext)

  const directoryStatus = useDirectoryStatus()

  const {
    data: [household],
    meta: { total_count: numberOfHouseholds },
  } = useApiRead(
    `/people/v2/people/${person.data.id}/households?fields[Household]=id`,
  )

  if (!person.meta.authenticated) return { links: [] }

  const householdRoute =
    numberOfHouseholds === 0 && addOrRemoveHouseholdMembersEnabled
      ? "/households/new"
      : numberOfHouseholds === 1
        ? `/households/${household.id}`
        : "/households"

  const profilePages = {
    contactAndInfo: {
      to: "/me/profile/contact-and-info",
      children: "Contact & profile information",
      icon: "churchCenter#profile-info-o",
    },
    services: {
      to: "/me",
      children: "Schedule",
      icon: "churchCenter#services-o",
    },
    households: {
      to: householdRoute,
      children: "Household",
      icon: "household",
    },
    directory: {
      to: "/directory/edit",
      children: "Directory profile",
      icon: "directory",
    },
    paymentMethods: {
      to: "/me/profile/payment-methods",
      children: "Payment methods",
      icon: "churchCenter#payment-methods-o",
    },
  }

  const nativeProfilePages = !myChurchCenterEnabled ? ["services"] : []

  const defaultPages = ["contactAndInfo", "households"]

  enableSavedPaymentMethods && defaultPages.push("paymentMethods")

  const hasAccess = (page) => {
    if (page !== "directory") return true

    return directoryStatus !== DIRECTORY_STATUS_NO_ACCESS
  }

  const shouldLinkToPage = (page) =>
    (builtInPages[page] === "published" && hasAccess(page)) ||
    nativeProfilePages.includes(page) ||
    defaultPages.includes(page)

  const links = Object.keys(profilePages).reduce((acc, page) => {
    return shouldLinkToPage(page) ? [...acc, profilePages[page]] : acc
  }, [])

  return { links }
}
