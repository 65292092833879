import { useEffect } from "react"
import { string, oneOf, node } from "prop-types"
import { Button } from "@planningcenter/doxy-web"
import { Icon } from "source/shared/components"
import { useToast } from "source/shared/hooks/useToast"
import { keyframes } from "@emotion/react"

const iconLookup = {
  success: "general#check",
  info: "general#outlined-info-circle",
  warning: "general#exclamation-triangle",
  error: "general#exclamation-triangle",
}

export const Toast = ({ id, message, type = "info" }) => {
  const { removeToast } = useToast()

  const bgColor = `var(--${type}-alert--text)`

  useEffect(() => {
    const toastTimer = setTimeout(() => {
      removeToast(id)
    }, 6000)

    return () => {
      clearTimeout(toastTimer)
    }
  }, [id, removeToast])

  return (
    <div
      css={{
        padding: "1rem 4px 1rem 1rem",
        backgroundColor: bgColor,
        color: "var(--color-tint9)",
        borderRadius: "4px",
        boxShadow: "0 8px 16px #0000001f",
        display: "grid",
        columnGap: "1rem",
        gridTemplateColumns: "14px 1fr 42px",
        animation: `${fadeInAndSlide} 6s forwards`,
      }}
    >
      <Icon
        css={{
          color: `var(--color-tint9)`,
          marginTop: "4px",
        }}
        symbol={iconLookup[type]}
      />
      <div>{message}</div>
      <Button
        text={
          <Icon
            symbol="general#x"
            css={{
              color: "var(--color-tint9)",
              opacity: "0.7",
            }}
          />
        }
        variant="naked"
        size="sm"
        onClick={() => removeToast(id)}
      />
    </div>
  )
}

Toast.propTypes = {
  id: string,
  message: string.isRequired,
  type: oneOf(["success", "info", "warning", "error"]).isRequired,
}

export const ToastList = ({ toasts }) => {
  return (
    <div
      css={{
        position: "fixed",
        top: "2rem",
        left: "0",
        right: "0",
        pointerEvents: "auto",
        maxWidth: "500px",
        margin: "auto",
        zIndex: 1001,
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      {toasts.map((toast) => (
        <Toast key={toast.id} {...toast} />
      ))}
    </div>
  )
}

ToastList.propTypes = {
  toasts: node.isRequired,
}

const fadeInAndSlide = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  4%,
  96% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(0);
  }
`
