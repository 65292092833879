import { snakeCase } from "lodash"

type Record = {
  id: string
}

function toParam(record: Record) {
  // eslint-disable-next-line no-prototype-builtins
  if (record.hasOwnProperty("id")) return record.id
  else return record
}

export function channelCollectionPath() {
  return `/channels`
}

export function channelPath(channel: Record) {
  return `/channels/${toParam(channel)}`
}

type Params = {
  [key: string]: string
}

export function episodePath(episode: Record, episodeParams: Params = {}) {
  const searchParams = new URLSearchParams()
  for (const [key, value] of Object.entries(episodeParams)) {
    value && searchParams.append(snakeCase(key), value)
  }

  const hasParams = searchParams.size > 0

  return `/episodes/${toParam(episode)}${hasParams ? `?${searchParams.toString()}` : ""}`
}
