import { useState, useEffect } from "react"
import { reservationStepPropTypes } from "source/registrations/propTypes"
import { isEmpty } from "lodash"
import SectionLayout from "source/registrations/reservations/SectionLayout"
import AddOnsForm from "./AddOnsForm"
import AttendeeQuestions from "./AttendeeQuestions"
import AttendeeTypeString from "source/registrations/reservations/AttendeeTypeString"
import QuestionCompletion from "source/registrations/questions/QuestionCompletion"

const selectedAddOnToString = (attendee, addOns) =>
  Object.values(attendee.addOnSelections)
    .map((addOnSelection) => {
      const addOn = addOns.find((a) => a.id === Number(addOnSelection.addOnId))

      const addOnVariation = addOn.addOnVariations.find(
        (addOnVariation) =>
          addOnVariation.id === addOnSelection.addOnVariationId,
      )

      let addOnStr =
        addOnSelection.quantity > 1 ? ` (${addOnSelection.quantity})` : ""

      return isEmpty(addOnVariation)
        ? `${addOn.name}${addOnStr}`
        : `${addOn.name}: ${addOnVariation.name}${addOnStr}`
    })
    .join(", ")

const AddOns = ({
  attendees: reservationAttendees,
  error: reservationError,
  onReservationSave,
  addOns,
  isProcessing,
  onIsProcessing,
  onEdit,
  onCancel,
  step,
  className,
  reservation,
  attendeeQuestions,
}) => {
  const [attendees, setAttendees] = useState([])
  const [error, setError] = useState(null)

  useEffect(() => {
    setAttendees(reservationAttendees)
  }, [reservationAttendees])

  const validAnswers = () => {
    const requiredQuestions = attendeeQuestions.filter((q) => q.required)

    return attendees
      .filter((attendee) => !attendee.waitlist)
      .every((a) => {
        const requiredAttendeeQuestions = requiredQuestions.filter(
          (q) => !q.excludedAttendeeTypeIds.includes(a.attendeeTypeId),
        )

        return requiredAttendeeQuestions.every((q) => {
          return a.answers[q.id]?.answers?.length > 0
        })
      })
  }

  const validAddOnOptions = () =>
    attendees
      .filter((attendee) => !attendee.waitlist)
      .reduce(
        (array, attendee) =>
          array.concat(Object.values(attendee.addOnSelections)),
        [],
      )
      .every((attendeeSelections) => {
        const addOn = addOns.find(
          (a) => a.id === Number(attendeeSelections.addOnId),
        )

        const totalAvailable = attendeeSelections.addOnVariationId
          ? addOn.addOnVariations.find(
              (av) => av.id === Number(attendeeSelections.addOnVariationId),
            )?.totalAvailable
          : addOn?.totalAvailable

        const allowedQuantity =
          attendeeSelections.quantity > 0 &&
          attendeeSelections.quantity <= (totalAvailable || Infinity)

        return (
          (attendeeSelections.addOnableType === "AddOnVariation" &&
            attendeeSelections.addOnVariationId &&
            allowedQuantity) ||
          (attendeeSelections.addOnableType === "AddOn" &&
            attendeeSelections.addOnId &&
            allowedQuantity)
        )
      })

  const handleSave = async (_event) => {
    if (!validAddOnOptions()) {
      return setError(
        "Additional options can not be blank or less than available capacity",
      )
    }

    if (!validAnswers()) {
      return setError("Required questions can not be blank")
    }

    onIsProcessing(true)
    setError(null)

    const updatedAttendees = attendees.filter(
      (a) => !a.waitlist && a.isInfoModified,
    )

    const includedAddOns = updatedAttendees
      .filter((a) => !isEmpty(a.addOnSelections))
      .reduce((addOnSelections, attendee) => {
        const attendeeAddOns = Object.values(attendee.addOnSelections).map(
          (addOnSelection) => ({
            type: "AddOnSelection",
            attributes: {
              attendeeId: attendee.id,
              addOnableType: addOnSelection.addOnableType,
              addOnableId:
                addOnSelection.addOnVariationId || addOnSelection.addOnId,
              quantity: addOnSelection.quantity || 1,
              _destroy: addOnSelection._destroy,
              id: addOnSelection.id,
            },
          }),
        )

        return addOnSelections.concat(attendeeAddOns)
      }, [])

    const includedAnswers = updatedAttendees
      .filter((a) => !isEmpty(a.answers))
      .reduce((answers, attendee) => {
        const attendeeAnswers = Object.values(attendee.answers).map(
          (answer) => ({
            type: "Answer",
            attributes: {
              attendeeId: attendee.id,
              ...answer,
              ...(answer.id &&
                answer.answers.length == 0 && { _destroy: true }),
            },
          }),
        )

        return answers.concat(attendeeAnswers)
      }, [])

    await onReservationSave({
      data: { attributes: { add_on_selections_changed: true } },
      included: [...includedAddOns, ...includedAnswers],
    })
    onIsProcessing(false)
  }

  const handleAttendeeInfoChange = (changedAttendee, field, value) => {
    setAttendees(
      attendees.map((attendee) =>
        attendee.id === changedAttendee.id
          ? {
              ...attendee,
              [field]: value,
              isInfoModified: true,
            }
          : attendee,
      ),
    )
  }

  const standardAttendees = attendees.filter((attendee) => !attendee.waitlist)

  const addOnError = error || reservationError

  const styles = {
    attendeeTitle: {
      display: "flex",
      gap: "0 0.75rem",
      justifyContent: "space-between",
      alignItems: "flex-end",
      flexWrap: "wrap",
      borderBottom: "1px solid var(--color-tint5)",
      marginBottom: "1.5rem",
      fontSize: "16px",
      fontWeight: "500",
      paddingBottom: "4px",
    },
    attendeeCount: {
      fontSize: "14px",
      color: "var(--color-tint3)",
    },
  }

  const requiredQuestions = attendeeQuestions.filter(
    (question) => question.required,
  )

  const getAddOnsForAttendee = (attendee) =>
    addOns.filter((addOn) =>
      attendee.attendeeType.availableAddOns.includes(addOn.id),
    )

  const getQuestionsForAttendee = (attendee) =>
    attendeeQuestions.filter(
      (question) =>
        !question.excludedAttendeeTypeIds.includes(attendee.attendeeTypeId),
    )

  return (
    <SectionLayout
      heading="Additional attendee information"
      className={className}
      step={step}
      isButtonShowing={step.isEditable}
      onButtonClick={onEdit}
      error={addOnError}
      isNextDisabled={isProcessing || !validAddOnOptions() || !validAnswers()}
      onSave={handleSave}
      onCancel={onCancel}
      expiresAt={reservation.expiresAt}
      isProcessing={isProcessing}
    >
      {standardAttendees.map((attendee, index) => (
        <div className="mb-4" key={attendee.id}>
          {(step.isCurrent || step.isComplete) && (
            <div css={step.isCurrent && styles.attendeeTitle}>
              <AttendeeTypeString attendee={attendee} />
              {step.isCurrent && standardAttendees.length > 1 && (
                <div css={styles.attendeeCount}>
                  Attendee {index + 1} / {standardAttendees.length}
                </div>
              )}
            </div>
          )}

          {(step.isCurrent || step.isComplete) &&
            getAddOnsForAttendee(attendee).length === 0 &&
            getQuestionsForAttendee(attendee).length === 0 && (
              <div className="c-tint2 fs-4">
                No additional information required for this attendee type
              </div>
            )}

          {!step.isCurrent && step.isComplete && (
            <>
              {!isEmpty(attendee.addOnSelections) && (
                <div className="c-tint2 fs-4">
                  {selectedAddOnToString(attendee, addOns)}
                </div>
              )}
              {getQuestionsForAttendee(attendee).length !== 0 && (
                <QuestionCompletion
                  requiredQuestions={requiredQuestions}
                  questions={attendeeQuestions}
                  answers={attendee.answers}
                />
              )}
            </>
          )}

          <AddOnsForm
            attendee={attendee}
            addOns={addOns}
            step={step}
            onChange={handleAttendeeInfoChange}
          />
          <AttendeeQuestions
            attendee={attendee}
            step={step}
            questions={attendeeQuestions}
            onChange={handleAttendeeInfoChange}
          />
        </div>
      ))}
    </SectionLayout>
  )
}

AddOns.propTypes = reservationStepPropTypes

export default AddOns
