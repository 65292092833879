import {
  useSuspenseQuery,
  splitQueryParams,
  queryFn,
} from "source/registrations/utils/queryCache"

const FIELDS = {
  Event: [
    "allow_credit_card_payments",
    "allow_ach_payments",
    "allow_minimum_deposits",
    "allow_offline_payments",
    "allowed_registrations",
    "can_register_household_members",
    "can_register_outside_members",
    "contact",
    "currency",
    "discounts",
    "event_times",
    "free",
    "guest_mode",
    "has_addons_module",
    "has_attendee_questions_module",
    "has_attendees_module",
    "has_payments_module",
    "has_registrant_questions_module",
    "maximum_registration_attendees",
    "name",
    "recent_registration_details",
    "recent_registration_urls",
    "register_for_separate_times",
    "registrant_collection_options",
    "registration_type",
  ],
  EventTime: [
    "at_maximum_capacity",
    "available_for_registrations",
    "date",
    "time",
    "all_day",
    "starts_at",
  ],
  Discount: ["kind", "is_expired", "restricted_to"],
}

export const useEvent = (id, options = {}) => {
  const path = `/registrations/v2/events/${id}`
  const { params, queryOptions } = splitQueryParams(options)

  return useSuspenseQuery({
    queryFn,
    queryKey: [
      path,
      {
        include: ["event_times", "discounts", "contact"],
        fields: FIELDS,
        ...params,
      },
    ],
    ...queryOptions,
  })
}
