import {
  useSuspenseQuery,
  usePrefetchQuery,
  splitQueryParams,
  queryFnAll,
} from "source/registrations/utils/queryCache"

const FIELDS = {
  AddOn: [
    "add_on_variations",
    "allow_multiple",
    "available_to_attendee_types",
    "name",
    "position",
    "price_cents",
    "price_string",
    "quantity_remaining",
    "required",
    "total_available",
  ],
  AddOnVariation: [
    "name",
    "price_cents",
    "price_string",
    "quantity_remaining",
    "total_available",
  ],
}

const queryOptions = (eventId, options = {}) => {
  const path = `/registrations/v2/events/${eventId}/add_ons`
  const { params, queryOptions } = splitQueryParams(options)

  return {
    queryFn: queryFnAll,
    queryKey: [
      path,
      {
        order: "position",
        include: "add_on_variations",
        fields: FIELDS,
        ...params,
      },
    ],
    ...queryOptions,
  }
}

export const useAddOnsPrefetch = (eventId, options) =>
  usePrefetchQuery(queryOptions(eventId, options))

export const useAddOns = (eventId, options) =>
  useSuspenseQuery(queryOptions(eventId, options))
