import { useState } from "react"
import { func } from "prop-types"
import { paymentMethodProps } from "../../payment-method-prop-types"
import { Icon } from "source/shared/components"
import { Button, Badge } from "@planningcenter/doxy-web"
import { PaymentMethodIcon } from "../icons"
import { LastFour } from "../LastFour"
import { EditableNickname } from "./EditableNickname"
import { useOrganizationFlag } from "source/payments/hooks/useOrganization"

export const PaymentMethod = ({
  paymentMethod: pm,
  onDeleteClick,
  onVerifyAccountClick,
}) => {
  const [nickname, setNickname] = useState(pm.nickname)

  const isCard = pm.methodSupertype === "card"
  const addedDate = new Date(pm.createdAt)

  const useSavedPaymentMethodsNickname = useOrganizationFlag(
    "useSavedPaymentMethodsNickname",
  )

  const renderBankAccountBadge = () => {
    return pm.isVerified ? (
      <Badge theme="success" text="Verified" />
    ) : (
      <Badge theme="error" text="Unverified" />
    )
  }

  const handleEditNickname = (newNickname) => {
    setNickname(newNickname)
  }

  return (
    <div
      css={{
        padding: "1rem 1.5rem",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "2rem",
        borderRadius: "4px",
        backgroundColor: "var(--color-tint9)",
      }}
    >
      <div className="fg-1">
        <div className="d-f ai-c g-1">
          <PaymentMethodIcon brand={pm.brand} isCard={isCard} />
          <strong>{pm.brand}</strong>
          <LastFour last4={pm.last4} />
          {!isCard && renderBankAccountBadge()}
        </div>
        {useSavedPaymentMethodsNickname && (
          <EditableNickname
            nickname={nickname}
            id={pm.id}
            onEdit={handleEditNickname}
          />
        )}
        <div className="d-f ai-c g-1 ml-5 fs-4 c-tint2">
          <span>
            <strong>Added: </strong>
            {addedDate.toLocaleDateString()}
          </span>
          {isCard && (
            <>
              <span>|</span>
              <span>
                <strong>Exp: </strong>
                {pm.expiration}
              </span>
            </>
          )}
          {pm.methodSubtype && (
            <>
              <span>|</span>
              <span>
                <strong>Type: </strong>
                <span css={{ textTransform: "capitalize" }}>
                  {pm.methodSubtype}
                </span>
              </span>
            </>
          )}
        </div>
        {!isCard && !pm.isVerified && (
          <div className="ml-5 mt-1">
            <Button
              text="Verify account"
              size="sm"
              variant="outline"
              onClick={onVerifyAccountClick}
            />
          </div>
        )}
      </div>
      <div className="d-f ai-c g-1">
        <Button
          text={<Icon className="fs-3" symbol="churchCenter#trash" />}
          variant="naked"
          theme="destroy"
          size="sm"
          onClick={onDeleteClick}
        />
      </div>
    </div>
  )
}
PaymentMethod.propTypes = {
  paymentMethod: paymentMethodProps,
  onDeleteClick: func,
  onVerifyAccountClick: func,
}
