import { Suspense } from "react"
import { Link, useLocation } from "react-router-dom"
import { Loading } from "source/shared/components"
import OpenGraphTags from "source/shared/OpenGraphTags"
import { useQRCode } from "source/shared/QRCode"
import BlankState from "source/groups/BlankState"
import { ApplicantAlerts } from "source/groups/components/ApplicantAlerts"
import { About } from "./components/About"
import { MembershipBar } from "./components/MembershipBar"
import { Header, HeaderWithoutTags } from "./components/Header"
import { useCurrentGroup } from "./hooks/useCurrentGroup"

export default function Entry() {
  useQRCode()

  return (
    <Suspense fallback={<Loading />}>
      <Group />
    </Suspense>
  )
}

function Group() {
  const { data: group, errors } = useCurrentGroup()
  const { state: locationState } = useLocation()

  if (errors) {
    return (
      <BlankState icon="churchCenter#groups-o">
        <p className="mb-2">This group is currently unavailable</p>
        <Link to="/groups" className="mr-1 minor-btn secondary-btn btn">
          View all groups
        </Link>
      </BlankState>
    )
  }

  if (!group) {
    return <Loading />
  }

  const { name, description, header_image } = group.attributes

  return (
    <>
      <OpenGraphTags
        title={name}
        description={description}
        imageUrl={header_image.medium}
      />

      {locationState?.type && <ApplicantAlerts />}
      <Suspense fallback={<HeaderWithoutTags />}>
        <Header />
      </Suspense>
      <Suspense>
        <MembershipBar />
        <About />
      </Suspense>
    </>
  )
}
