import PropTypes from "prop-types"
import { useEffect } from "react"
import useReactNativeBridgeComponent from "source/shared/hooks/useReactNativeBridgeComponent"

NativeHeader.propTypes = {
  hideBottomDrawer: PropTypes.bool,
  rightType: PropTypes.oneOf(["default", "share"]),
  shareUrl: PropTypes.string,
  title: PropTypes.string.isRequired,
}
export default function NativeHeader({
  hideBottomDrawer,
  title,
  rightType,
  shareUrl,
}) {
  const { postMessage } = useReactNativeBridgeComponent({
    component: NativeHeader.nativeComponentName,
  })
  useEffect(() => {
    postMessage("set", {
      hideBottomDrawer,
      title,
      right: {
        type: rightType,
        shareUrl,
      },
    })
  }, [hideBottomDrawer, postMessage, rightType, shareUrl, title])

  return null
}
NativeHeader.nativeComponentName = "NativeNavigationOptions"
