import { sessionApiClient } from "@planningcenter/cc-api-client"
import { useSuspenseQuery } from "@tanstack/react-query"

export default function useMyChurchCenter() {
  return useSuspenseQuery({
    queryKey: ["my_church_center"],
    queryFn: () => sessionApiClient.get("/publishing/v2/me/my_church_center"),
    refetchOnWindowFocus: false,
    select: (data) => {
      const { upcoming: activityDates, pending: pendingItems } =
        data.data.attributes
      return { activityDates, pendingItems }
    },
  })
}
