import { sessionApiClient } from "@planningcenter/cc-api-client"
import { useSuspenseQuery } from "@tanstack/react-query"

const CC_CONFIG_PATH = "/calendar/v2/church_center_configuration"

type View = "month" | "list" | "gallery"
type WeekStart = "Sunday" | "Monday"

type ApiResponse = {
  data: {
    attributes: {
      default_view: View
      available_views: Array<View>
      calendar_starts_on: WeekStart
    }
  }
}

interface Configuration {
  defaultView: View
  availableViews: Array<View>
  weekStartsOn: WeekStart
}

export function useChurchCenterConfiguration(): Configuration {
  const { data } = useSuspenseQuery<ApiResponse>({
    queryKey: [CC_CONFIG_PATH],
    queryFn: () => {
      return sessionApiClient.get(CC_CONFIG_PATH)
    },
    retry: 5,
    retryDelay: (retryCount) => (retryCount + 1) * 1000,
  })

  const {
    default_view: defaultView,
    available_views: availableViews,
    calendar_starts_on: weekStartsOn,
  } = data.data.attributes

  return {
    defaultView,
    availableViews,
    weekStartsOn,
    weekStartsOnInt: weekStartsOn === "Sunday" ? 0 : 1,
  } as Configuration
}
