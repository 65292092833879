import { css } from "@emotion/react"
import { WideLayout } from "source/Layout"
import { Heading, Image, Subheading } from "@planningcenter/doxy-web"
import { usePublicPlanDetails } from "source/services/hooks/usePublicPlanDetails"
import { useSession } from "source/shared/hooks/useSession"
import { useParams, useSearchParams } from "react-router-dom"
import { noop } from "lodash"
import { array, number, string, shape, bool } from "prop-types"
import OpenGraphTags from "source/shared/OpenGraphTags"

PublicPlanPage.propTypes = {
  beforeAfter: string,
  title: string,
}

export default function PublicPlanPage({ beforeAfter }) {
  useSession(false)
  const [searchParams, _setSearchParams] = useSearchParams()
  const title = searchParams.get("title")
  const { planId, serviceTypeId } = useParams()
  const { errors, plan } = usePublicPlanDetails({
    beforeAfter,
    planId,
    serviceTypeId,
  })

  return (
    <WideLayout>
      {errors ? <BlankState title={title} /> : <PublicPlan plan={plan} />}
    </WideLayout>
  )
}

BlankState.propTypes = {
  title: string,
}
function BlankState({ title }) {
  return (
    <div css={styles.blankstate} className="action-drawer mb-1 d-f ai-c fd-c">
      <img src="/assets/three-horizontal-list-items.svg" alt="" />
      <Heading level={1} text={title || "Order of service"} />
      <Heading
        level={2}
        text="This page is not currently available. Please check back later."
      />
    </div>
  )
}

PublicPlan.propTypes = {
  plan: shape({
    planItems: shape({
      type: string,
      title: string,
      time: number,
      id: number,
      attachments: shape({
        redirect_link: string,
        id: number,
        type: string,
      }),
    }),
    planName: string,
    seriesArt: string,
    serviceTypeName: string,
    showItemDescriptions: bool,
    showItemTimes: bool,
    times: array,
    title: string,
  }),
}
function PublicPlan({ plan }) {
  const {
    times,
    serviceTypeName,
    showItemDescriptions,
    showItemTimes,
    planItems,
  } = plan

  const title = plan.title || "Order of Service"

  const formatTime = (timeInSeconds = 0) => {
    const minutes = Math.floor(timeInSeconds / 60)
    const seconds = timeInSeconds % 60

    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0",
    )}`
  }
  return (
    <>
      <OpenGraphTags title={title} />
      <div className="d-f ai-c@sm g-2 sm:g-4 fd-c fd-rr@sm">
        {plan.seriesArt && (
          <div className="f-1">
            <Image alt="series artwork" src={plan.seriesArt} rounded />
          </div>
        )}
        <div className="f-1 d-f fd-c">
          <Heading level={1} text={title} />
          {plan.planName && <Heading level={2} text={plan.planName} />}
          <text>{serviceTypeName}</text>
          {times.map((time, idx) => (
            <text className="w-400 fs-13 c-tint2" key={idx}>
              {time.date}
            </text>
          ))}
        </div>
      </div>
      {planItems.map((item, idx) =>
        item.type === "PlanHeader" ? (
          <div className="mb-1 mt-4" key={item.id}>
            <Subheading level={4} size={5} text={item.title} color="tint3" />
          </div>
        ) : (
          <div
            className={`d-f ai-b sm:ai-c g-1 mb-2 ${idx === 0 ? "mt-4" : ""}`}
            key={item.id}
          >
            {showItemTimes && (
              <div>
                <text className="w-7">{formatTime(item.time)}</text>
              </div>
            )}
            <div className="d-f fd-c g-1">
              <div className="d-f fd-c fd-r@sm sm:ai-c g-1@sm">
                <text className="fw-600">{item.title}</text>
                <div className="d-f ai-c g-1">
                  {item.attachments &&
                    item.attachments.map((attachment) => (
                      <a
                        className="badge badge-btn fs-4 fw-500 px-1"
                        href={noop}
                        key={attachment.id}
                        onClick={() =>
                          window.open(
                            attachment.redirect_link,
                            "_blank",
                            "width=600,height=400",
                          )
                        }
                      >
                        {attachment.type}
                      </a>
                    ))}
                </div>
              </div>
              {showItemDescriptions && item.description && (
                <div style={{ whiteSpace: "pre-wrap" }}>
                  <text>{item.description}</text>
                </div>
              )}
            </div>
          </div>
        ),
      )}
    </>
  )
}

const styles = {
  blankstate: css`
    padding: 80px 0;

    h1 {
      opacity: 0.8;
      font-weight: 800;
      font-size: 28px;
      letter-spacing: 0.8px;
      margin-top: 8px;
      text-transform: uppercase;
      @media (min-width: 960px) {
        font-size: 52px;
        letter-spacing: 1.5px;
        margin-top: 0;
      }
    }

    h2 {
      opacity: 0.6;
      font-size: 16px;
      margin-top: 8px;
      text-align: center;
      @media (min-width: 960px) {
        font-size: 18px;
        margin-top: 0;
      }
    }

    h3 {
      font-size: 18px;
      font-weight: 700;
      color: transparent;
      background-clip: text;
      margin-top: 8px;
      background-image: linear-gradient(to right, #41a5f5, #66bb6a);
      -webkit-background-clip: text;
      @media (min-width: 960px) {
        font-size: 30px;
        margin-top: 16px;
      }
    }
  `,
}
