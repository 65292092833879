import { Loading } from "source/shared/components"

export const PaymentElementLoading = () => {
  return (
    <div
      className="d-f fd-c jc-c c-tint3"
      css={{ textAlign: "center", height: "443px" }}
    >
      <Loading />
    </div>
  )
}
