import { useCallback, useContext, useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import { arrayOf, bool, func, node, shape, string } from "prop-types"
import { useLocation, useNavigate } from "react-router-dom"
import { css } from "@emotion/react"
import { isEmpty } from "lodash/lang"
import { sortBy } from "lodash/collection"

import { colors } from "source/shared/colors"
import { size } from "@planningcenter/system"
import { WideLayout, FullscreenLayout } from "source/Layout"
import { useEmbedded } from "source/calendar/hooks/useEmbedded"
import { LimitedInfiniteScroll, Icon, Loading } from "source/shared/components"
import MiniCalendar from "source/calendar/list/MiniCalendar"
import { useCalendarEvents } from "source/calendar/hooks/useCalendarEvents"
import { useViewPreferences } from "source/calendar/hooks/useViewPreferences"
import { useQRCode } from "source/shared/QRCode"
import { ServerRenderedProps } from "source/shared/contexts/ServerRenderedProps"
import { useCustomNavigationLabel } from "source/publishing/WebBoot"
import { useMomentInTimeZone } from "source/calendar/hooks/useMomentInTimeZone"
import CampusAndCategoryFilters from "./CampusAndCategoryFilters"
import GalleryView from "./GalleryView"
import MonthView from "./MonthView"
import ViewButtons from "./ViewButtons"
import EventsList from "./EventsList"

const featuredViewData = {
  view: "featured",
  isListView: false,
  isMonthView: false,
  isGalleryView: false,
}

export default function CalendarList() {
  const navigate = useNavigate()
  const params = new URLSearchParams(window.location.search)
  const momentInTZ = useMomentInTimeZone()
  const today = momentInTZ().startOf("day")
  const [viewingMonth, setViewingMonth] = useState(today.toISOString())
  const [selectedDay, setSelectedDay] = useState()
  const { embedded } = useEmbedded()

  const showFilterBar = !embedded || params.get("allowFiltering") === "true"

  const location = useLocation()

  const viewData = useViewPreferences()
  const {
    setViewPreference,
    isListView,
    isMonthView,
    isGalleryView,
    view,
    urlForView,
    isMobile,
  } = viewData

  const miniCalEnabled = !embedded || (!isMobile && embedded)

  const [showMiniCal, setShowMiniCal] = useState(true)
  const toggleMiniCal = function () {
    setShowMiniCal((s) => !s)
  }

  const {
    categories,
    category: selectedCategory,
    campuses,
    campus: selectedCampus,
    eventsByDay: events,
    eventsWithDetails: detailedEvents,
    hasMore,
    lastDateLoaded,
    loadingEvents,
    loadMore,
  } = useCalendarEvents(today, viewingMonth, viewData)

  const {
    eventsWithDetails: featuredEvents,
    hasMore: hasMoreFeaturedEvents,
    loadMore: loadMoreFeaturedEvents,
    loadingEvents: loadingFeaturedEvents,
  } = useCalendarEvents(today, viewingMonth, featuredViewData)

  useQRCode()

  // If we arrive with an eventId and date in the location state, scroll to that event.
  // This handles restoring scroll position when the user clicks back on the breadcrumb from the event page.
  useEffect(() => {
    const eventId = location.state && location.state.eventId
    const eventDate = location.state && location.state.eventDate
    if (eventId) {
      const eventListElement = document.querySelector(
        `[data-date="${eventDate}"][data-eventid="${eventId}"]`,
      )
      eventListElement && eventListElement.scrollIntoView({ block: "center" })
    }
  }, [location.state])

  const isoFormattedMonth = useCallback(
    (newDate) => momentInTZ(newDate).startOf("month").toISOString(),
    [momentInTZ],
  )

  const scrollTo = useCallback(
    (date) => {
      const element = document.querySelector(`[data-date="${date}"]`)
      if (!element) return

      const header = document.querySelector("header.Header")
      const headerHeight = header?.offsetHeight || 10
      const distanceToElement = element.offsetTop
      const miniCalHeight = 339
      const distanceToScroll = isMobile
        ? distanceToElement - headerHeight - miniCalHeight
        : distanceToElement - headerHeight - 10
      window.scrollTo({
        top: distanceToScroll,
        behavior: "smooth",
      })
    },
    [isMobile],
  )

  useEffect(() => {
    if (isListView) {
      selectedDay && scrollTo(momentInTZ(selectedDay).format("YYYY-MM-DD"))
      setViewingMonth(isoFormattedMonth(selectedDay))
    }
  }, [selectedDay, isListView, isoFormattedMonth, momentInTZ, scrollTo])

  const handleCalendarMonthChange = (newDate) => {
    setViewingMonth(isoFormattedMonth(newDate))
  }

  const handleMonthLabelClick = (newDate) => {
    scrollTo(momentInTZ(newDate).format("YYYY-MM"))
  }

  const clearFilters = () => {
    params.delete("category")
    params.delete("campus")

    navigate(`/calendar?${params.toString()}`)
  }

  const hasEvents = Object.keys(events).length > 0

  const filterSelected = !isEmpty(selectedCategory) || !isEmpty(selectedCampus)

  const handleViewChange = (view) => {
    setViewPreference(view)
    setViewingMonth(today.toISOString())
    const url = urlForView(view)
    return navigate(url)
  }

  useEffect(() => {
    const url = urlForView(view)
    navigate(url)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CalendarListLayout>
      <MetaInformationForDocumentHeader />
      {(!isMobile || isGalleryView) && showFilterBar && (
        <FilterBar
          campus={selectedCampus}
          campuses={campuses && campuses.data}
          categories={categories && categories.data}
          category={selectedCategory}
          onViewChange={handleViewChange}
          onSelectedViewChange={handleViewChange}
        />
      )}
      {isMonthView && (
        <MonthView
          onMonthChange={handleCalendarMonthChange}
          {...{ events, viewingMonth, loadingEvents }}
        />
      )}
      {isGalleryView && (
        <>
          {hasEvents || loadingEvents ? (
            <GalleryView
              events={detailedEvents}
              {...{
                loadingEvents,
                hasMore,
                loadMore,
                lastDateLoaded,
              }}
            />
          ) : (
            <NoEventsPlaceholder {...{ clearFilters, filterSelected }} />
          )}
        </>
      )}
      {isListView && (
        <CalendarListLayout>
          <div className="d-f@md ai-fs">
            {miniCalEnabled && (
              <div
                css={styles.secondaryCol}
                style={{ zIndex: isMobile ? 10 : 1 }}
              >
                <MiniCalendar
                  {...{
                    events,
                    selectedDay,
                    lastDateLoaded,
                    showMiniCal,
                    loadingEvents,
                  }}
                  onSelectDay={setSelectedDay}
                  onChangeMonth={handleCalendarMonthChange}
                  onMonthLabelClick={handleMonthLabelClick}
                  date={viewingMonth}
                />
                {isMobile && (
                  <button css={styles.miniCalButton} onClick={toggleMiniCal}>
                    <Icon
                      symbol={`general#${showMiniCal ? "up" : "down"}-chevron`}
                    />
                  </button>
                )}
              </div>
            )}
            <div css={styles.primaryCol}>
              {isMobile && showFilterBar && (
                <div className="mt-2">
                  <FilterBar
                    campus={selectedCampus}
                    campuses={campuses && campuses.data}
                    categories={categories && categories.data}
                    category={selectedCategory}
                    onViewChange={handleViewChange}
                    onSelectedViewChange={handleViewChange}
                  />
                </div>
              )}
              {hasEvents ? (
                <EventsList
                  autoloading={{
                    events: {
                      hasMore,
                      lastDateLoaded,
                      loading: loadingEvents,
                      loadMore,
                    },
                    featured: {
                      hasMore: hasMoreFeaturedEvents,
                      loading: loadingFeaturedEvents,
                      loadMore: loadMoreFeaturedEvents,
                    },
                  }}
                  events={events}
                  featuredEvents={featuredEvents}
                />
              ) : loadingEvents ? (
                <Loading message="Loading" />
              ) : (
                <NoEventsPlaceholder {...{ clearFilters, filterSelected }} />
              )}
            </div>
          </div>
        </CalendarListLayout>
      )}
    </CalendarListLayout>
  )
}
const categoryShape = shape({
  id: string,
  attributes: shape({
    name: string,
  }),
})

const campusShape = shape({
  id: string,
  attributes: shape({
    name: string,
  }),
})

const CalendarListLayout = ({ children }) => {
  const { embedded } = useEmbedded()

  if (embedded) {
    return <FullscreenLayout>{children}</FullscreenLayout>
  } else {
    return <WideLayout>{children}</WideLayout>
  }
}

CalendarListLayout.propTypes = {
  children: node.isRequired,
}

const FilterBar = ({
  campus,
  campuses,
  categories,
  category,
  onSelectedViewChange,
}) => {
  const navigate = useNavigate()
  const params = new URLSearchParams(window.location.search)
  const {
    view: viewPreference,
    isMobile,
    hasMultipleViews,
  } = useViewPreferences()
  const { embedded } = useEmbedded()
  const defaultCategoryFilter = "All Categories"
  const selectedCategory = !isEmpty(category)
    ? category.attributes.name
    : defaultCategoryFilter

  const defaultCampusFilter = "All Campuses"
  const selectedCampus = !isEmpty(campus)
    ? campus.attributes.name
    : defaultCampusFilter

  const handleCampusChange = (e) => {
    handleFilterChange({ campus: e, category: category })
  }

  const handleCategoryChange = (e) => {
    handleFilterChange({ campus: campus, category: e })
  }

  const updateParams = (category, campus) => {
    if (!isEmpty(category) && category !== "All Categories") {
      params.set("category", encodeURIComponent(category.attributes.name))
    } else {
      params.delete("category")
    }

    if (!isEmpty(campus) && campus !== "All Campuses") {
      params.set("campus", encodeURIComponent(campus.attributes.name))
    } else {
      params.delete("campus")
    }
  }

  const handleFilterChange = ({ category, campus }) => {
    updateParams(category, campus)
    let url = ["/calendar", params.toString()].filter(Boolean).join("?")

    return navigate(url)
  }

  const sortedCategoryTags = sortBy(categories, (c) =>
    c.attributes.name.toLowerCase(),
  )
  const sortedCampusTags = sortBy(campuses, (c) =>
    c.attributes.name.toLowerCase(),
  )

  return (
    <div
      className="action-drawer mb-2 mb-4@md d-f fd-c fd-r@sm ai-s jc-sb"
      style={{ gap: "8px" }}
    >
      <CampusAndCategoryFilters
        campuses={sortedCampusTags}
        categories={sortedCategoryTags}
        onSelectedCampusChange={handleCampusChange}
        onSelectedCategoryChange={handleCategoryChange}
        {...{
          defaultCampusFilter,
          defaultCategoryFilter,
          selectedCampus,
          selectedCategory,
        }}
      />
      {(!isMobile || hasMultipleViews) && !embedded && (
        <ViewButtons
          onSelectView={onSelectedViewChange}
          selectedValue={viewPreference}
        />
      )}
    </div>
  )
}

FilterBar.propTypes = {
  campus: campusShape,
  campuses: arrayOf(campusShape),
  categories: arrayOf(categoryShape),
  category: categoryShape,
  onCategoryChange: func,
  onSelectedViewChange: func,
  viewPreference: string,
}

const MetaInformationForDocumentHeader = () => {
  const {
    layout: {
      organization_name: organizationName,
      organization_avatar_url: avatarUrl,
    },
  } = useContext(ServerRenderedProps)
  const title = useCustomNavigationLabel("Calendar")

  return (
    <Helmet>
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta
        property="og:description"
        content={`Calendar at ${organizationName}`}
      />
      {avatarUrl && <meta property="og:image" content={avatarUrl} />}
    </Helmet>
  )
}

export const NoEventsPlaceholder = ({ clearFilters, filterSelected }) => (
  <div className="ta-c c-tint2 fs-2">
    <Icon symbol="churchCenter#calendar-events" className="mb-1" />
    {filterSelected ? (
      <>
        <div>No events found.</div>
        <div className="mt-2">
          <button
            className="compact-btn btn secondary-btn minor-btn"
            onClick={clearFilters}
          >
            Clear filters
          </button>
        </div>
      </>
    ) : (
      <div>No upcoming events</div>
    )}
  </div>
)

NoEventsPlaceholder.propTypes = { clearFilters: func, filterSelected: bool }

CalendarEventScroller.propTypes = { lastDateLoaded: string }
export function CalendarEventScroller({ lastDateLoaded, ...props }) {
  const momentInTZ = useMomentInTimeZone()
  const maxMonthsToLoadOnScroll = 1
  const futureMonthsLoaded = momentInTZ(lastDateLoaded).diff(
    momentInTZ(),
    "months",
  )
  const keepLoadingOnScroll = futureMonthsLoaded <= maxMonthsToLoadOnScroll

  return (
    <LimitedInfiniteScroll
      loadMoreButtonText="View more..."
      {...{ keepLoadingOnScroll }}
      {...props}
    />
  )
}

const header = document.querySelector("header.Header")

const initialParams = new URLSearchParams(window.location.search)
const initialEmbedded = initialParams.get("embed") == "true"

const styles = {
  miniCalButton: css`
    background: ${colors.tint9};
    border: none;
    color: ${colors.tint2};
    display: block;
    width: 100%;
    padding: ${header ? 2 : 12}px 0 ${header ? 12 : 10}px 0;
  `,
  secondaryCol: css`
    position: sticky;
    top: ${header ? 68 : 0}px;
    background: ${colors.tint10};

    @media (min-width: 720px) {
      max-width: 296px;
      display: block;
      position: sticky;
      top: ${initialEmbedded ? "0" : "6rem"};
    }

    @media (min-width: 960px) {
      max-width: 332px;
    }
  `,
  primaryCol: css`
    flex: 2;

    @media (min-width: 720px) {
      padding-left: ${size(4)}px;
    }

    @media (min-width: 960px) {
      padding-left: ${size(6)}px;
    }
  `,
  eventListMonthLabel: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: ${size(2)}px;
  `,
}
