import { sessionApiClient } from "@planningcenter/cc-api-client"
import { Menu, MenuButton, MenuList, MenuItem } from "@reach/menu-button"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { useCallback } from "react"
import { trackPendoEvent } from "source/publishing/trackPendoEvent"

import { Icon } from "source/shared/components"

function detectMacOS() {
  return /Macintosh|Mac OS X/.test(navigator.userAgent)
}

async function copyToClipboard(textPromise) {
  if (!navigator.clipboard) return

  if (!ClipboardItem || !navigator.clipboard.write)
    await navigator.clipboard.writeText(await textPromise())

  const clipboardItem = new ClipboardItem({
    ["text/plain"]: await textPromise(),
  })
  await navigator.clipboard.write([clipboardItem])
}

export default function CalendarSubscribeDropdown() {
  const queryClient = useQueryClient()
  const mccFeedQuery = {
    queryKey: ["my_church_center_feed"],
    path: "/publishing/v2/me/my_church_center_feed",
  }
  const { data: mccFeed } = useQuery({
    queryKey: mccFeedQuery.queryKey,
    queryFn: () => sessionApiClient.get(mccFeedQuery.path),
    select: (data) => data?.data.attributes,
  })
  const enableIcalMutation = useMutation({
    mutationFn: () =>
      sessionApiClient
        .patch(mccFeedQuery.path, {
          data: { attributes: { ical_enabled: true } },
        })
        .then((response) => response.data.attributes),
    onSuccess: (data) => {
      queryClient.setQueryData(mccFeedQuery.queryKey, data)
    },
    onError: () => {
      alert("Failed to generate subcription link. Please try again.")
    },
  })

  const ensureSubscriptionLinks = useCallback(async () => {
    const icalEnabled = mccFeed?.ical_enabled
    if (icalEnabled) {
      return mccFeed.subscription_links
    } else {
      const mutationData = await enableIcalMutation.mutateAsync()
      return mutationData.subscription_links
    }
  }, [enableIcalMutation, mccFeed])
  const pendoEventName = "CCW | Me | My Schedule | Feed: Subscribe"

  const fetchIcalUrl = useCallback(async () => {
    const subscription_links = await ensureSubscriptionLinks()
    return subscription_links.ical_url
  }, [ensureSubscriptionLinks])

  const handleCopyICalLink = useCallback(() => {
    copyToClipboard(fetchIcalUrl).then(() => {
      trackPendoEvent(pendoEventName, { provider: "clipboard" })
    })
  }, [fetchIcalUrl])

  const handleWebcalLink = useCallback(() => {
    ensureSubscriptionLinks().then((subscription_links) => {
      window.open(subscription_links.webcal)
      trackPendoEvent(pendoEventName, { provider: "desktop" })
    })
  }, [ensureSubscriptionLinks])

  const handleGoogleLink = useCallback(() => {
    ensureSubscriptionLinks().then((subscription_links) => {
      window.open(subscription_links.google)
      trackPendoEvent(pendoEventName, { provider: "google" })
    })
  }, [ensureSubscriptionLinks])

  const handleOutlookComLink = useCallback(() => {
    ensureSubscriptionLinks().then((subscription_links) => {
      window.open(subscription_links.outlook_com)
      trackPendoEvent(pendoEventName, { provider: "outlook_com" })
    })
  }, [ensureSubscriptionLinks])

  return (
    <Menu>
      <MenuButton className="minor-btn secondary-btn btn">
        Subscribe
        <span className="fs-6 pl-4p dropdown-trigger__icon">
          <Icon symbol="general#down-chevron" />
        </span>
      </MenuButton>

      <MenuList>
        <MenuItem onSelect={handleWebcalLink}>
          {detectMacOS() ? "Apple" : "Outlook"} Calendar
        </MenuItem>
        <MenuItem onSelect={handleGoogleLink}>Google Calendar</MenuItem>
        <MenuItem onSelect={handleOutlookComLink}>Outlook.com</MenuItem>
        <hr style={{ border: "solid 0.5px var(--color-tint5)" }} />
        <MenuItem onSelect={handleCopyICalLink}>Copy the iCal link</MenuItem>
      </MenuList>
    </Menu>
  )
}
