import { bool, object } from "prop-types"
import { useRef } from "react"
import { Link } from "react-router-dom"
import { Modal } from "source/shared/components/Modal"
import CancelRegistrationModal from "./CancelRegistrationModal"

const Help = ({ registration, isCancelation = false }) => {
  const {
    event: { contact, allowRegistrantsToCancel },
    canceledOn,
  } = registration

  const inputFocusRef = useRef()

  if (!contact) {
    return null
  }

  return (
    <div className="action-drawer">
      <strong>Need Help? </strong>
      {allowRegistrantsToCancel && !canceledOn ? (
        <>
          You can{" "}
          <Modal onOpenFocusRef={inputFocusRef}>
            <Modal.Trigger as={Link} className="link">
              cancel online{" "}
            </Modal.Trigger>
            <CancelRegistrationModal
              registration={registration}
              isCancelation={isCancelation}
              inputFocusRef={inputFocusRef}
            />
          </Modal>
          or contact{" "}
        </>
      ) : (
        "Contact "
      )}

      <a href={`mailto:${contact.primaryEmailAddress}`}>{contact.name}</a>
    </div>
  )
}

Help.propTypes = {
  registration: object,
  isCancelation: bool,
}

export default Help
