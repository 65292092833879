import { useContext } from "react"
import { Heading, Button } from "@planningcenter/doxy-web"
import { Icon } from "source/shared/components"
import { PaymentMethod, PaymentMethodLoading } from "./PaymentMethod"
import { VerifyAccountDialog } from "./VerifyAccountDialog"
import { AddPaymentMethodDialog } from "./AddPaymentMethodDialog"
import { DeleteConfirmationDialog } from "./DeleteConfirmationDialog"
import { ServerRenderedProps } from "source/shared/contexts/ServerRenderedProps"
import { PAYMENT_URLS, launchAsyncModal } from "source/payments/utils"
import { api } from "source/registrations/api/client"
import { usePaymentMethods } from "source/payments/hooks"
import { useToast } from "source/shared/hooks/useToast"
import BlankState from "source/groups/BlankState"

const deleteConfirmationMessage = (errors) => {
  if (!errors) return "Are you sure you want to delete this payment method?"
  switch (errors[0].status) {
    case "422": {
      return "This payment method is currently in use for a recurring donation and cannot be deleted."
    }
    case "404": {
      return "This payment method could not be found. Please contact support."
    }
    default: {
      return "There was a problem verifying that this payment method can be deleted. Please try again later."
    }
  }
}

export const ManagePaymentMethods = () => {
  const { stripe, current_organization, layout } =
    useContext(ServerRenderedProps)

  const { paymentMethods, loadingPaymentMethods, setLastChanged } =
    usePaymentMethods()

  const { addToast } = useToast()

  const handleDelete = async (paymentMethodId) => {
    try {
      await api.destroy(PAYMENT_URLS.deletePaymentMethod(paymentMethodId))
      setLastChanged(new Date())
      addToast({
        message: "Your payment method was successfully deleted.",
        type: "success",
      })
    } catch {
      addToast({
        message: "There was a problem deleting this payment method.",
        type: "error",
      })
    }
  }

  const confirmDelete = async (paymentMethodId) => {
    const { data: paymentMethodDeletable, errors } = await api.post(
      PAYMENT_URLS.checkDeletable(paymentMethodId),
    )
    const message = deleteConfirmationMessage(errors)
    if (
      await launchAsyncModal(DeleteConfirmationDialog, {
        message,
        paymentMethodDeletable,
      })
    ) {
      handleDelete(paymentMethodId)
    }
  }

  const handleVerifyAccountClick = async (paymentMethod) => {
    await launchAsyncModal(VerifyAccountDialog, {
      onSuccess: () => {
        setLastChanged(new Date())
        addToast({
          message: "Your bank account was successfully verified.",
          type: "success",
        })
      },
      orgName: layout.organization_name,
      paymentMethod,
      testMode: stripe.test_mode,
    })
  }

  const handleAddPaymentMethodClick = async () => {
    await launchAsyncModal(AddPaymentMethodDialog, {
      onSuccess: () => {
        setLastChanged(new Date())
        addToast({
          message: "Your payment method was successfully added.",
          type: "success",
        })
      },
      stripe,
      orgId: current_organization.id,
    })
  }

  return (
    <>
      <div className="d-f ai-c jc-sb mb-3">
        <Heading level={2} size={3} text="Payment methods" />
        <Button
          size="sm"
          text={
            <div className="d-f ai-c g-1">
              <Icon symbol="general#plus" className="fs-6" />
              <span>Add payment method</span>
            </div>
          }
          variant="outline"
          onClick={handleAddPaymentMethodClick}
        />
      </div>
      <div className="d-f fd-c g-2">
        {loadingPaymentMethods ? (
          <PaymentMethodLoading />
        ) : (
          <>
            {paymentMethods.length ? (
              paymentMethods.map((paymentMethod) => (
                <PaymentMethod
                  key={paymentMethod.id}
                  paymentMethod={paymentMethod}
                  onDeleteClick={() => confirmDelete(paymentMethod.id)}
                  onVerifyAccountClick={() =>
                    handleVerifyAccountClick(paymentMethod)
                  }
                />
              ))
            ) : (
              <BlankState icon="general#credit-card">
                <div>You don&apos;t have any payment methods yet.</div>
              </BlankState>
            )}
          </>
        )}
      </div>
    </>
  )
}
