import { createContext, useCallback, useState } from "react"
import { v4 as uuidv4 } from "uuid"
import { node } from "prop-types"
import { ToastList } from "source/shared/components"

export const ToastContext = createContext()

export const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState([])

  const addToast = useCallback(
    (props) => {
      setToasts((prevToasts) => [
        {
          id: uuidv4(),
          ...props,
        },
        ...prevToasts,
      ])
    },
    [setToasts],
  )

  const removeToast = useCallback(
    (id) => {
      setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id))
    },
    [setToasts],
  )

  return (
    <ToastContext.Provider value={{ addToast, removeToast }}>
      <ToastList toasts={toasts} />
      {children}
    </ToastContext.Provider>
  )
}

ToastProvider.propTypes = {
  children: node,
}
