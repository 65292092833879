import { Navigate, Route, Routes } from "react-router-dom"
import {
  BreadcrumbNavigationWithMe,
  BreadcrumbPage,
  BreadcrumbDividerIcon,
} from "source/shared/components"
import { ProfileAvatar } from "../../myChurchCenter/Profile/ProfileAvatar"
import { Heading } from "@planningcenter/doxy-web"
import {
  useApiRead,
  SessionApiResource,
} from "source/shared/SessionApiResource"
import { useCacheHydrate } from "source/shared/react-cache-but-its-a-context"
import { ManagePaymentMethods } from "./ManagePaymentMethods"
import { ToastProvider } from "source/shared/contexts/ToastProvider"

const ME_PATH =
  "/people/v2/me?include=emails,phone_numbers,addresses.country,marital_status,school&fields[Country]=address_fields,name"

export const PaymentMethodsPage = () => {
  const profile = useApiRead(ME_PATH)
  const name = profile.data.attributes.name
  const avatar =
    profile.data.attributes.avatar_url ||
    profile.data.attributes.demographic_avatar_url
  const reloadProfile = useCacheHydrate(SessionApiResource, ME_PATH)

  return (
    <ToastProvider>
      <BreadcrumbNavigationWithMe>
        <BreadcrumbPage
          linkUrl="/me/profile"
          pageText="My profile & settings"
        />
        <BreadcrumbDividerIcon />
        <BreadcrumbPage pageText="Payment methods" isActive />
      </BreadcrumbNavigationWithMe>
      <div className="d-f ai-c mb-2">
        <ProfileAvatar
          avatar={avatar}
          name={name}
          personPath={ME_PATH}
          reloadProfile={reloadProfile}
        />
        <Heading level={1} size={2} text={name} />
      </div>
      <Routes>
        <Route path="/" element={<ManagePaymentMethods />} />
        <Route path="*" element={<Navigate to="/me/profile" replace />} />
      </Routes>
    </ToastProvider>
  )
}
