import { useEffect, useState } from "react"
import { useSession } from "source/shared/hooks/useSession"
import { useNavigate, useParams } from "react-router-dom"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import { get as dig } from "lodash"

export default function EncryptedRsvpsEdit() {
  const navigate = useNavigate()
  const { token } = useParams()
  const { data: currentPerson } = useSession()
  const [text, setText] = useState("Saving your RSVP...")

  const updateRsvpViaToken = (token) => {
    sessionApiClient
      .post("/groups/v2/update_encrypted_rsvp", {
        data: { attributes: { token: token } },
      })
      .then(({ data }) => {
        const personId = dig(data, "relationships.person.data.id")

        if (currentPerson.id && currentPerson.id !== personId) {
          navigate(
            `/logout?return=${window.location.origin}/groups/encrypted_rsvps/result`,
          )
        } else {
          navigate(`/groups/encrypted_rsvps/result`, { state: { rsvp: data } })
        }
      })
      .catch((error) => {
        const errors = dig(error, "errors", [])
        const rsvpsDisabledError = errors.find(
          (e) => e.title === "RSVPs are disabled",
        )
        const text = rsvpsDisabledError
          ? rsvpsDisabledError.detail
          : "We couldn't record your response. Please try clicking the link in your email again, or contact your Group Leader."
        setText(text)
      })
  }

  useEffect(() => {
    updateRsvpViaToken(token)
  }, [])

  return <header className="d-f mb-4 pt-1 ai-c">{text}</header>
}
