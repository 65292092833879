import { useContext, useState } from "react"
import { func, object, string } from "prop-types"
import { useNavigate } from "react-router-dom"
import { Button, Heading, TextInput } from "@planningcenter/doxy-web"
import { ActionBar } from "source/shared/components"
import { CurrentGroupContext } from "source/groups/my/groups"
import { CancelButton } from "source/groups/components/CancelButton"

ConversationForm.propTypes = {
  conversation: object,
  onSubmit: func.isRequired,
  submitText: string.isRequired,
}

export function ConversationForm({ conversation = {}, onSubmit, submitText }) {
  const navigate = useNavigate()
  const group = useContext(CurrentGroupContext)
  const [title, setTitle] = useState(conversation?.attributes?.title || "")
  const [inFlight, setInFlight] = useState(false)

  function handleSubmit(e) {
    e.preventDefault()
    setInFlight(true)
    onSubmit({ title }).finally(() => setInFlight(false))
  }

  return (
    <form className="w-100%" onSubmit={handleSubmit}>
      <div className="d-f fd-c g-1 mb-2">
        <Heading level={3} size={4} text="To" />
        <ActionBar.Pane css={{ minHeight: 0 }}>
          <ActionBar.Column>
            <ActionBar.Description level={4}>
              All {group.memberships_count} members of {group.name}
            </ActionBar.Description>
          </ActionBar.Column>
        </ActionBar.Pane>
      </div>
      <div className="mb-2">
        <TextInput
          label="Name"
          placeholder="Title or topic of conversation"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          size="md"
          required
        />
      </div>
      <div className="d-f jc-fe ai-c mt-3">
        <CancelButton
          handleCancel={() => navigate(`${group.base_path}/conversations`)}
        />
        <Button
          size="lg"
          text={submitText}
          theme="primary"
          type="submit"
          variant="filled"
          isLoading={inFlight}
          disabled={!title}
        />
      </div>
    </form>
  )
}
