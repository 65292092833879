import { useState } from "react"
import { string, func } from "prop-types"
import { css } from "@emotion/react"
import { Icon } from "source/shared/components"

export const EditableNickname = ({
  nickname: nicknameFromProps,
  id,
  onEdit,
}) => {
  const [nickname, setNickname] = useState(nicknameFromProps)
  const [isEditing, setIsEditing] = useState(false)

  const nicknameId = `edit-nickname-${id}`

  const handleClickOutside = (e) => {
    if (e.relatedTarget && e.relatedTarget.id === "cancel-nickname-edit") {
      cancelEdit()
    } else {
      handleSave()
    }
  }

  const startEditing = () => {
    setIsEditing(true)
    setTimeout(() => {
      document.getElementById(nicknameId).focus()
    }, 10)
  }

  const cancelEdit = () => {
    setNickname(nicknameFromProps)
    setIsEditing(false)
  }

  const handleSave = () => {
    setIsEditing(false)
    onEdit(nickname)
  }

  const handleInputKeydown = (e) => {
    if (e.key === "Enter") {
      handleSave()
    }
    if (e.key === "Escape") {
      cancelEdit()
    }
  }

  return (
    <div css={nicknameStyles}>
      {isEditing ? (
        <div className="nickname-field-container">
          <input
            id={nicknameId}
            value={nickname}
            onChange={(e) => setNickname(e.target.value)}
            onKeyDown={handleInputKeydown}
            onBlur={handleClickOutside}
            style={{ width: `${nickname?.length}ch` }}
            className="nickname-field"
            placeholder="Add a nickname"
            maxLength={50}
          />
          <button id="cancel-nickname-edit" className="nickname-edit-button">
            <Icon symbol="general#x" className="cancel" />
          </button>
          <button className="nickname-edit-button">
            <Icon symbol="general#check" className="save" />
          </button>
        </div>
      ) : (
        <div
          className="nickname-button"
          role="button"
          onClick={startEditing}
          tabIndex={0}
          onKeyDown={(e) => e.key === "Enter" && setIsEditing(true)}
        >
          <span>
            {nickname || <em className=" c-tint2">Add a nickname</em>}
          </span>
          <Icon symbol="general#pencil" className="edit-icon" />
        </div>
      )}
    </div>
  )
}
EditableNickname.propTypes = {
  nickname: string,
  id: string,
  onEdit: func.isRequired,
}

const nicknameStyles = css`
  .nickname-field-container {
    margin-left: calc(2.5rem - 4px);
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 2px;
    margin-top: -4px;
  }

  .nickname-field {
    font-size: 14px;
    background-color: var(--color-tint6);
    border-radius: 4px;
    border: none;
    box-shadow: none;
    color: var(--color-tint1);
    padding: 4px;
    line-height: 21px;
    max-width: 460px;
    min-width: 120px;
  }

  .nickname-field:focus {
    outline: none;
  }

  .nickname-edit-button {
    border: none;
    background-color: transparent;
    padding: 0;
    display: flex;
    align-items: center;
  }

  .nickname-edit-button svg {
    padding: 2px;
  }

  .nickname-edit-button .cancel {
    fill: var(--color-ruby);
  }

  .nickname-edit-button .save {
    fill: var(--color-emerald);
  }

  .nickname-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-left: 2.5rem;
    margin-bottom: 6px;
    font-size: 14px;
    cursor: pointer;
  }

  .edit-icon {
    height: 10px;
    width: 10px;
    fill: var(--color-tint2);
    opacity: 0;
    transition: opacity 0.1s linear;
  }

  .nickname-button:hover .edit-icon {
    opacity: 1;
    transition: opacity 0.1s linear;
  }
`
