export const PaymentMethodLoading = () => {
  return [1, 2, 3].map((i) => (
    <div
      key={i}
      css={{
        padding: "1rem 1.5rem",
        display: "flex",
        gap: "0.5rem",
        borderRadius: "4px",
        backgroundColor: "var(--color-tint9)",
      }}
    >
      <div
        className="loading-placeholder"
        css={{
          backgroundColor: "var(--color-tint7)",
          height: "21px",
          width: "32px",
        }}
      ></div>
      <div className="d-f fd-c g-1">
        <div
          className="loading-placeholder"
          css={{
            backgroundColor: "var(--color-tint7)",
            height: "21px",
            width: "125px",
          }}
        ></div>
        <div
          className="loading-placeholder"
          css={{
            backgroundColor: "var(--color-tint7)",
            height: "21px",
            width: "250px",
          }}
        ></div>
      </div>
    </div>
  ))
}
