import { useLocalStorage } from "source/shared/hooks/useLocalStorage"
import { useEmbedded } from "source/calendar/hooks/useEmbedded"
import { useWindowDimensions } from "source/shared/hooks/useWindowDimensions"

import { useEffect } from "react"
import { useChurchCenterConfiguration } from "./useChurchCenterConfiguration"

export function useViewPreferences() {
  const { embedded } = useEmbedded()
  const listView = "list"
  const monthView = "month"
  const gridViewLegacy = "grid"
  const galleryView = "gallery"
  const viewParamKey = "view"
  const params = new URLSearchParams(window.location.search)

  // convert legacy grid view params to gallery view
  const rawViewFromParams = params.get(viewParamKey)
  const viewFromParams =
    rawViewFromParams === gridViewLegacy ? galleryView : rawViewFromParams

  const [viewFromStorage, setViewPreference] = useLocalStorage(
    "calendarViewPreference",
    "",
  )

  // convert legacy grid view local storage values to gallery view
  useEffect(() => {
    if (viewFromStorage === gridViewLegacy) {
      setViewPreference(galleryView)
    }
  }, [viewFromStorage, setViewPreference])

  const { defaultView, availableViews } = useChurchCenterConfiguration()

  const { width: winWidth } = useWindowDimensions()
  const isMobile = winWidth < 720

  const allViews = [listView, monthView, galleryView]
  const mobileFriendlyViews = [listView, galleryView]
  const availableEmbededViews = isMobile ? mobileFriendlyViews : allViews
  const defaultEmbeddedView = isMobile ? listView : monthView

  const mobileAwareAvailableViews = isMobile
    ? availableViews.filter((view) => view !== monthView)
    : availableViews

  const mobileAwareDefaultView = !isMobile
    ? defaultView
    : defaultView === monthView
      ? listView
      : defaultView

  const view = embedded
    ? availableEmbededViews.includes(viewFromParams)
      ? viewFromParams
      : defaultEmbeddedView
    : viewFromParams && mobileAwareAvailableViews.includes(viewFromParams)
      ? viewFromParams
      : viewFromStorage && mobileAwareAvailableViews.includes(viewFromStorage)
        ? viewFromStorage
        : mobileAwareDefaultView

  const isListView = view === listView
  const isGalleryView = view === galleryView
  const isMonthView = view === monthView

  const urlForView = (newView) => {
    const existingParams = params
    existingParams.set(viewParamKey, newView)
    const paramsString = Array.from(existingParams.entries())
      .map(([key, value]) => {
        return `${key}=${value}`
      })
      .join("&")

    return ["/calendar", paramsString].filter(Boolean).join("?")
  }

  return {
    availableViews: mobileAwareAvailableViews,
    isGalleryView,
    isListView,
    isMobile,
    isMonthView,
    setViewPreference,
    view,
    urlForView,
    hasMultipleViews: mobileAwareAvailableViews.length > 1,
  }
}
