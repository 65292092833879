import { useState, useEffect } from "react"
import { bool, func, string, number } from "prop-types"
import { Radio } from "source/registrations/CheckableInput"

const WAIT_INTERVAL = 700
let timeout = null

const ReviewPaymentType = ({
  onChange,
  onOtherAmountChange,
  paymentType,
  totalDueString,
  minimumDue,
  minimumDueString,
  isPaymentUpdating,
  onCanMakePayment,
  currencySymbol,
  showLaterOption = true,
  showMinimumOption = true,
  otherAmount: otherAmountFromProps,
}) => {
  const [otherAmount, setOtherAmount] = useState("")

  useEffect(() => () => clearTimeout(timeout), [])

  useEffect(() => {
    if (otherAmountFromProps && otherAmountFromProps !== otherAmount) {
      setOtherAmount(otherAmountFromProps)
    }
  }, [otherAmountFromProps, otherAmount])

  const handleChange = ({ target: { value } }) => {
    if (value !== "other") {
      setOtherAmount("")
      clearTimeout(timeout)
    }

    onChange(value)
  }

  const handleOtherAmountChange = async ({ target: { value } }) => {
    onCanMakePayment(false)
    if (timeout) clearTimeout(timeout)
    setOtherAmount(value)
    timeout = setTimeout(() => onOtherAmountChange(value), WAIT_INTERVAL)
  }

  const paymentTypes = [
    {
      id: "amountFull",
      label: `Pay in full - ${totalDueString}`,
      value: "full",
    },
  ]

  if (showMinimumOption && minimumDue > 0) {
    paymentTypes.push({
      id: "amountMinimum",
      label: `Minimum deposit - ${minimumDueString}`,
      value: "minimum",
    })
  }

  if (showLaterOption && minimumDue <= 0) {
    paymentTypes.push({
      id: "amountLater",
      label: "Pay later",
      value: "later",
    })
  }

  paymentTypes.push({
    id: "amountOther",
    label: "Other amount",
    value: "other",
  })

  return (
    <div>
      <label htmlFor="amount" className="mt-3">
        Payment amount
      </label>
      {paymentTypes.map((amount) => (
        <div key={amount.id} className="mb-4p">
          <Radio
            name="amount"
            id={amount.id}
            label={amount.label}
            value={amount.value}
            onChange={handleChange}
            checked={paymentType === amount.value}
            disabled={isPaymentUpdating}
          />
        </div>
      ))}

      {paymentType === "other" && (
        <div className="prepend-label ml-3 mt-1">
          <div className="label">{currencySymbol}</div>
          <input
            value={otherAmount}
            onChange={handleOtherAmountChange}
            aria-label="Other amount"
            type="text"
            className="label-container"
            style={{ width: 120, height: 32, paddingLeft: 42 }}
            inputMode="decimal"
            pattern="[0-9]*"
          />
        </div>
      )}
    </div>
  )
}

ReviewPaymentType.propTypes = {
  onChange: func,
  onOtherAmountChange: func,
  paymentType: string,
  otherAmount: string,
  totalDueString: string,
  minimumDue: number,
  minimumDueString: string,
  isPaymentUpdating: bool,
  onCanMakePayment: func,
  currencySymbol: string,
  showLaterOption: bool,
  showMinimumOption: bool,
  otherAmountFromProps: number,
}

export default ReviewPaymentType
