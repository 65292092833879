import { useEffect } from "react"
import { string, bool, func } from "prop-types"
import { LastFour } from "source/payments/components"

export const VerifyAmounts = ({
  last4,
  testMode,
  orgName,
  setInputValid,
  microdeposit1,
  microdeposit2,
  setMicrodeposit1,
  setMicrodeposit2,
}) => {
  useEffect(() => {
    setInputValid(
      microdeposit1 &&
        microdeposit2 &&
        microdeposit1.length === 2 &&
        microdeposit2.length === 2,
    )
  }, [microdeposit1, microdeposit2, setInputValid])

  return (
    <>
      <p>
        {orgName} &#40;showing as &quot;PLANNING CENTER&quot;&#41; has made two
        small deposits to bank account{" "}
        <LastFour
          last4={last4}
          styles={{ display: "inline-block", color: "var(--color-tint1)" }}
        />
        .
      </p>
      <p>
        To complete the ACH verification process, please enter the amounts of
        those deposits.
      </p>
      {testMode && (
        <div className="info-alert alert">
          This account is in test mode. To verify, enter <strong>32</strong> and{" "}
          <strong>45</strong>.
        </div>
      )}
      <div className="d-f ai-c g-2">
        <VerifyAmountInput
          value={microdeposit1}
          onChange={(e) => setMicrodeposit1(e.target.value)}
        />
        <VerifyAmountInput
          value={microdeposit2}
          onChange={(e) => setMicrodeposit2(e.target.value)}
        />
      </div>
    </>
  )
}

VerifyAmounts.propTypes = {
  last4: string.isRequired,
  testMode: bool.isRequired,
  orgName: string.isRequired,
  setInputValid: func.isRequired,
  microdeposit1: string.isRequired,
  microdeposit2: string.isRequired,
  setMicrodeposit1: func.isRequired,
  setMicrodeposit2: func.isRequired,
}

const VerifyAmountInput = ({ value, onChange, placeholder }) => (
  <div
    css={{
      display: "flex",
      boxShadow: "inset 0 1px 2px var(--color-tint9)",
      border: "1px solid var(--color-tint5)",
      borderRadius: "4px",
      "&:focus-within": {
        outline: "1px solid var(--color-topaz)",
        boxShadow: "0 0 2px var(--color-topaz)",
      },
    }}
  >
    <div
      css={{
        fontWeight: "600",
        backgroundColor: "var(--color-tint7)",
        padding: "7px 10px",
        borderRadius: "4px 0 0 4px",
        borderRight: "1px solid var(--color-tint5)",
        lineHeight: "30px",
        fontFamily: "monospace",
      }}
    >
      $0.
    </div>
    <input
      css={{
        padding: "12px",
        fontSize: "16px",
        borderRadius: "0 4px 4px 0",
        border: "none",
        width: "100%",
        fontFamily: "monospace",
        fontWeight: "600",
        color: "var(--color-tint1)",
        "&:focus": { outline: "none", boxShadow: "none" },
      }}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      maxLength={2}
    />
  </div>
)

VerifyAmountInput.propTypes = {
  value: string.isRequired,
  onChange: func.isRequired,
  placeholder: string,
}
