import { Fragment, useState } from "react"
import { Helmet } from "react-helmet-async"
import NoLogoPlaceholder from "./noLogoPlaceholder"
import { Link, useParams } from "react-router-dom"
import moment from "moment-timezone"
import { Date as SharedDate, Time } from "source/shared/DatetimeFmt"
import { array, string, shape, bool, object, arrayOf, number } from "prop-types"
import { useSession } from "source/shared/hooks/useSession"
import { useQRCode } from "source/shared/QRCode"
import { getRelationship } from "source/shared/getRelationship"
import EventLocation from "./EventLocation"
import { useApiRead } from "source/shared/SessionApiResource"
import { Icon, RoutingAwareDangerousHTML } from "source/shared/components"
import { useRegistrationsFeature } from "source/registrations/features_and_settings"
import Error404 from "source/registrations/Error404"
import { Heading, Subheading, Button } from "@planningcenter/doxy-web"
import { lowerCase, partition, sortBy } from "lodash"
import { useFetchEntireAttendeeTypeCollection } from "source/registrations/api/hooks"
import useQueryString from "source/shared/hooks/useQueryString"
import { useOrganizationFlag } from "source/registrations/hooks/useOrganization"
import {
  useWindowDimensions,
  BREAKPOINTS,
} from "source/shared/hooks/useWindowDimensions"

const Signup = () => {
  const { eventId } = useParams()
  useQRCode(eventId)
  let eventLocation = null

  let event = {}
  const json = useApiRead(
    `/registrations/v2/events/${eventId}?include=campuses,categories,event_location,event_times`,
    {
      version: "2020-06-16",
    },
  )

  const { width: windowWidth } = useWindowDimensions()

  const attendeeTypes = useFetchEntireAttendeeTypeCollection(eventId)
  const currentPerson = useSession(false)

  if (json.errors) {
    return <Error404 />
  } else {
    event = json.data
  }

  const eventLocationResult = getRelationship(json, "event_location")
  const allEventTimes = getRelationship(json, "event_times")

  const eventCampuses = sortBy(getRelationship(json, "campuses"), (c) =>
    lowerCase(c.attributes.name),
  )

  const eventCategories = sortBy(getRelationship(json, "categories"), (c) =>
    lowerCase(c.attributes.name),
  )

  const [eventTimes, pastEventTimes] = partition(
    allEventTimes.sort(
      (
        { attributes: { starts_at } },
        { attributes: { starts_at: other_starts_at } },
      ) => moment(starts_at).diff(moment(other_starts_at)),
    ),
    (eventTime) => moment(eventTime.attributes.starts_at).isAfter(moment()),
  )

  const {
    attributes: {
      logo_url: logoURL,
      logo_url_for_og: openGraphImageURL,
      name,
      event_time: eventTimeSummary,
      registration_state: registrationState,
      registration_type: registrationType,
      registration_available: registrationAvailable,
      registered_for_event: registeredForEvent,
      registration_status_message: registrationStatusMessage,
      description: description,
      stripped_description: strippedDescription,
      current_registration_url: currentRegistrationUrl,
      public_url: publicUrl,
      at_maximum_capacity: atMaximumCapacity,
      waitlist_available: waitlistAvailable,
      register_for_separate_times: registerForSeparateTimes,
      currency,
      free,
      archived,
    } = {},
  } = event || {}

  if (archived) {
    return <Error404 />
  }

  // If it's an error, it will have a .errors key instead of a .data key
  if (eventLocationResult) {
    const {
      attributes: {
        name,
        latitude,
        longitude,
        url,
        location_type: locationType,
        approximate_address: approximateAddress,
        formatted_address: formattedAddress,
        full_formatted_address: fullFormattedAddress,
      },
    } = eventLocationResult

    eventLocation = {
      name,
      latitude,
      longitude,
      approximateAddress,
      formattedAddress,
      fullFormattedAddress,
      url,
      locationType,
    }
  }

  const isAnnouncementOnlyEvent =
    registrationState === "none" || registrationType === "none"

  const hasCampusesOrCategories =
    eventCategories.length > 0 || eventCampuses.length > 0

  const MetaInformationForDocumentHeader = () => {
    return (
      <Helmet>
        <title>{name}</title>
        <meta property="og:title" content={name} />
        <meta property="og:description" content={strippedDescription} />
        <meta property="og:image" content={openGraphImageURL} />
      </Helmet>
    )
  }

  return (
    <Fragment>
      <MetaInformationForDocumentHeader />
      <div className="recurring">
        <div className="d-f@md ai-c fd-rr mb-3@md">
          <div
            className={`w-48%@md ${
              logoURL ? "item-show__image" : "p-r events-show__placeholder"
            }`}
            css={{ "@media (max-width: 599px)": { marginTop: "-1.5rem" } }}
          >
            {logoURL ? (
              <img className="d-b" alt={`${name} logo`} src={logoURL} />
            ) : (
              <NoLogoPlaceholder event={event} name={name} />
            )}
          </div>

          <div className="f-1 pr-6@md">
            <Heading level={1} text={name} />
            <div className="mt-2 mt-1@md">
              <span className="d-n d-b@md">{eventTimeSummary}</span>
            </div>
          </div>
        </div>

        {!isAnnouncementOnlyEvent ? (
          <RegistrationActionBar
            eventId={eventId}
            registrationStatusMessage={registrationStatusMessage}
            currentPerson={currentPerson}
            registeredForEvent={registeredForEvent}
            currentRegistrationUrl={currentRegistrationUrl}
            publicUrl={publicUrl}
            registrationState={registrationState}
            atMaximumCapacity={atMaximumCapacity}
            registrationAvailable={registrationAvailable}
            waitlistAvailable={waitlistAvailable}
            free={free}
            attendeeTypes={attendeeTypes}
            registrationType={registrationType}
          />
        ) : (
          <div
            className="mb-2 mb-3@sm p-0 action-drawer"
            style={{ height: "1px" }}
          />
        )}

        <div className="d-f@md g-3@md makeRoomForTheActionBarOnMobile">
          <div className="w-33%@md o-1@md">
            {hasCampusesOrCategories && (
              <Categories
                eventCategories={eventCategories}
                eventCampuses={eventCampuses}
              />
            )}
            {windowWidth <= BREAKPOINTS.MD && (
              <MobileDetails description={description} />
            )}
            <DatesAndTimes
              event={event}
              eventTimes={eventTimes}
              attendeeTypes={attendeeTypes}
              label={`Upcoming Dates`}
            />
            <DatesAndTimes
              event={event}
              eventTimes={pastEventTimes.reverse()}
              label={"Past Dates"}
            />
            <EventLocation
              location={eventLocation}
              registeredForEvent={registeredForEvent}
              isAnnouncementOnlyEvent={isAnnouncementOnlyEvent}
            />

            {!isAnnouncementOnlyEvent && !registerForSeparateTimes && (
              <AttendeeTypes
                eventIsFull={atMaximumCapacity}
                currency={currency}
                freeEvent={free}
                attendeeTypes={attendeeTypes}
                registrationType={registrationType}
              />
            )}
          </div>
          {windowWidth > BREAKPOINTS.MD && (
            <Details description={description} />
          )}
        </div>
      </div>
    </Fragment>
  )
}

const attendeeTypesShape = arrayOf(
  shape({
    id: string,
    attributes: shape({ name: string, price_cents: number }),
  }),
)

const currentPersonShape = shape({
  meta: shape({
    authenticated: bool.isRequired,
  }),
}).isRequired

const Categories = ({ eventCampuses = [], eventCategories = [] }) => {
  return (
    <div className="mb-3 mb-4@md">
      <Subheading level={2} text="Categories" />
      <div className="d-f fw-w g-4p mt-1">
        {eventCampuses.map((campus) => (
          <div key={campus.id} className="badge">
            {campus.attributes.name}
          </div>
        ))}
        {eventCategories.map((category) => (
          <div key={category.id} className="badge">
            {category.attributes.name}
          </div>
        ))}
      </div>
    </div>
  )
}

Categories.propTypes = {
  eventCampuses: arrayOf(
    shape({
      attributes: shape({
        name: string.isRequired,
      }),
    }),
  ),
  eventCategories: arrayOf(
    shape({
      attributes: shape({
        name: string.isRequired,
      }),
    }),
  ),
}

const DatesAndTimes = ({
  eventTimes = [],
  attendeeTypes = [],
  event,
  label,
}) => {
  const [showMoreEventTimes, setShowMoreEventTimes] = useState(false)

  if (eventTimes.length <= 0) {
    return null
  }

  return (
    <div className="mb-3 mb-4@md">
      <Subheading level={2} text={label} />
      <ul className="unstyled fs-4 mt-1">
        {eventTimes.slice(0, 3).map((et) => (
          <SignupTime
            key={et.id}
            attendeeTypes={attendeeTypes}
            signup={event}
            signupTime={et}
          />
        ))}
        {eventTimes.length > 3 && (
          <Fragment>
            {showMoreEventTimes &&
              eventTimes
                .slice(3)
                .map((et) => (
                  <SignupTime
                    key={et.id}
                    attendeeTypes={attendeeTypes}
                    signup={event}
                    signupTime={et}
                  />
                ))}
            <button
              onClick={() => {
                setShowMoreEventTimes(!showMoreEventTimes)
              }}
              className="minor-btn secondary-btn btn"
            >
              Show {showMoreEventTimes ? "less" : "all"}
            </button>
          </Fragment>
        )}
      </ul>
    </div>
  )
}

DatesAndTimes.propTypes = {
  event: shape({}),
  eventTimes: arrayOf(
    shape({
      attributes: shape({
        date: string.isRequired,
        time: string.isRequired,
      }),
    }),
  ),
  attendeeTypes: array,
  label: string,
}

const SignupTime = ({ attendeeTypes, signup, signupTime }) => {
  const [selectionsVisible, setSelectionsVisible] = useState(false)

  const {
    attributes: {
      registration_type: registrationType,
      at_maximum_capacity: eventAtMaximumCapacity,
      register_for_separate_times: registerForSeparateTimes,
      currency,
      free,
      template_attendee_type_count,
      any_attendee_types_have_capacity,
      maximum_capacity_set,
      register_for_separate_times,
    },
  } = signup

  const hideViewAvailabilityDropdown =
    template_attendee_type_count == 1 &&
    !any_attendee_types_have_capacity &&
    !maximum_capacity_set &&
    register_for_separate_times

  const {
    id,
    attributes: {
      starts_at,
      ends_at,
      all_day: allDay,
      at_maximum_capacity: atMaximumCapacity,
      available_for_registrations: availableForRegistrations,
    },
    startsAt = moment(starts_at),
    endsAt = moment(ends_at),
  } = signupTime

  return (
    <li key={id} className="mb-2">
      <div>
        {startsAt.isSame(endsAt, "day") ? (
          <>
            <SharedDate start={startsAt} style="abbreviated" year />
            {!allDay && (
              <div className="fs-5">
                <Time start={startsAt} end={endsAt} />
              </div>
            )}
          </>
        ) : (
          <>
            {allDay ? (
              <SharedDate
                start={startsAt}
                end={endsAt}
                style="abbreviated"
                year
              />
            ) : (
              <>
                <div className="d-f ai-c">
                  <div>
                    <SharedDate start={startsAt} style="abbreviated" year />
                    <div className="fs-5">
                      <Time start={startsAt} />
                    </div>
                  </div>
                  <div className="px-4">
                    <Icon
                      symbol="general#right-arrow"
                      className="c-tint3"
                      aria-label="arrow icon pointing from start date to end date"
                    />
                  </div>
                  <div>
                    <SharedDate start={endsAt} style="abbreviated" year />
                    <div className="fs-5">
                      <Time start={endsAt} />
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
        <div>
          {registerForSeparateTimes &&
            (atMaximumCapacity ? (
              <div className="c-ruby">Full</div>
            ) : (
              availableForRegistrations &&
              attendeeTypes.length > 0 &&
              !hideViewAvailabilityDropdown && (
                <div>
                  <button
                    className="text-btn mb-1 mt-2p fs-13 d-f ai-c"
                    onClick={() => {
                      setSelectionsVisible(!selectionsVisible)
                    }}
                  >
                    View availability
                    <Icon
                      symbol="general#down-chevron"
                      className="ml-4p fs-6 p-r t-1p"
                      style={{
                        transform: selectionsVisible
                          ? "rotate(180deg)"
                          : "none",
                      }}
                      aria-hidden
                    />
                  </button>
                  {selectionsVisible && (
                    <AttendeeTypes
                      eventIsFull={eventAtMaximumCapacity}
                      currency={currency}
                      freeEvent={free}
                      attendeeTypes={attendeeTypes.filter(
                        (at) =>
                          at.relationships.signup_time.data &&
                          at.relationships.signup_time.data.id == id,
                      )}
                      registrationType={registrationType}
                      registerForSeparateTimes={registerForSeparateTimes}
                    />
                  )}
                </div>
              )
            ))}
        </div>
      </div>
    </li>
  )
}

SignupTime.propTypes = {
  attendeeTypes: array.isRequired,
  signup: object.isRequired,
  signupTime: object.isRequired,
}

const RegistrationActionBar = ({
  eventId,
  registrationStatusMessage,
  currentPerson,
  registeredForEvent,
  registrationAvailable,
  currentRegistrationUrl,
  publicUrl,
  registrationState,
  atMaximumCapacity,
  waitlistAvailable,
  free,
  attendeeTypes = [],
  registrationType,
}) => {
  const [params] = useQueryString()
  const detailedWaitlistEnabled = useOrganizationFlag("detailedWaitlist")
  const disableNewRegistration = detailedWaitlistEnabled
    ? atMaximumCapacity && !waitlistAvailable
    : atMaximumCapacity

  const newReservationURL =
    useRegistrationsFeature("unified_detailed_signups_ccw", eventId) ||
    registrationType === "simple"
      ? `${publicUrl}/reservations/new${
          params.source ? `?source=${params.source}` : ""
        }`
      : `/registrations/events/${eventId}/logins/new?return=${publicUrl}/registrations/new${
          params.source ? `&source=${params.source}` : ""
        }`

  return (
    <div
      className="mb-3@sm px-2 action-drawer d-f ai-c jc-sb action-bar"
      style={{ maxWidth: "100vw" }}
    >
      <div data-turbolinks="false">
        {registrationStatusMessage && (
          <div className="c-tint2 fs-13 f-1 mr-2 lh-1.5">
            {registrationStatusMessage}
          </div>
        )}
        <div className="c-tint2 fs-13 f-1 mr-2 lh-1.5">
          {currentPerson.meta.authenticated ? (
            registeredForEvent &&
            registrationState !== "closed" &&
            !disableNewRegistration &&
            attendeeTypes.length > 0 && (
              <Fragment>
                <a href={newReservationURL} className="c-brand">
                  Register again
                </a>
              </Fragment>
            )
          ) : (
            <Fragment>
              Already registered?
              <Link
                className="c-brand"
                to={`/login?return=${window.location.href}`}
              >
                {" Log in"}
              </Link>
            </Fragment>
          )}
        </div>
      </div>
      <RegistrationButton
        eventId={eventId}
        currentPerson={currentPerson}
        registeredForEvent={registeredForEvent}
        registrationAvailable={registrationAvailable}
        currentRegistrationUrl={currentRegistrationUrl}
        newRegsitrationUrl={newReservationURL}
        registrationState={registrationState}
        atMaximumCapacity={atMaximumCapacity}
        waitlistAvailable={waitlistAvailable}
        freeEvent={free}
        hasPublicAttendeeTypes={attendeeTypes.length > 0}
      />
    </div>
  )
}

RegistrationActionBar.propTypes = {
  registrationStatusMessage: string,
  currentPerson: currentPersonShape,
  registeredForEvent: bool,
  currentRegistrationUrl: string,
  publicUrl: string,
  registrationState: string,
  registrationAvailable: bool.isRequired,
  atMaximumCapacity: bool,
  waitlistAvailable: bool,
  free: bool,
  attendeeTypes: attendeeTypesShape,
  eventId: string,
  registrationType: string,
}

const MobileDetails = ({ description }) => {
  const [showAll, setShowAll] = useState(false)
  return (
    <div className="mb-3">
      {description && (
        <>
          <Subheading level={2} text="Details" />
          <div
            css={{
              overflow: "hidden",
              maxHeight: showAll ? "none" : "96px",
              position: "relative",
              backgroundColor: "var(--color-tint9)",
              borderRadius: "4px",
              padding: "1rem 1rem 2rem 1rem",
            }}
          >
            <RoutingAwareDangerousHTML
              html={description}
              openLinksInNewTab={true}
            />
            <div
              css={{
                position: "absolute",
                bottom: "0",
                left: "0",
                right: "0",
                height: "48px",
                background:
                  "linear-gradient(to bottom, transparent, 10%, var(--color-tint9), var(--color-tint9))",
                zIndex: 1,
              }}
            ></div>
            <div
              css={{
                position: "absolute",
                bottom: "0.5rem",
                left: "3px",
                zIndex: 2,
                background: "radial-gradient(var(--color-tint9), transparent)",
              }}
            >
              <Button
                size="sm"
                variant="naked"
                onClick={() => setShowAll(!showAll)}
                text={`Show ${showAll ? "less" : "more"}`}
              />
            </div>
          </div>
        </>
      )}
    </div>
  )
}

MobileDetails.propTypes = {
  description: string,
}

const Details = ({ description }) => {
  return (
    <div className="mb-3 f-1">
      {description && (
        <>
          <Heading level={2} size={3} text="Details" />
          <div className="mt-1">
            <RoutingAwareDangerousHTML
              html={description}
              openLinksInNewTab={true}
            />
          </div>
        </>
      )}
    </div>
  )
}

Details.propTypes = {
  description: string,
}

const AttendeeTypes = ({
  eventIsFull = false,
  freeEvent = false,
  attendeeTypes = [],
  registerForSeparateTimes = false,
}) => {
  const priceFormatter = (priceCents, priceString) => {
    if (priceCents <= 0) {
      return "Free"
    }

    return priceString
  }

  return attendeeTypes.length > 0 ? (
    <div className="mb-3 mb-4@md">
      <div className="mb-3">
        {!registerForSeparateTimes && (
          <Subheading level={2} text="Selections" />
        )}
        <div
          className={`mt-1 ${registerForSeparateTimes && "action-drawer pb-1"}`}
        >
          <ul className="unstyled fs-4">
            {attendeeTypes.map((at) => {
              return (
                <li key={at.id} className="mb-1">
                  <div className="truncate">{at.attributes.name}</div>
                  <div className="fs-13 lh-1.5 c-tint2">
                    {at.attributes.at_maximum_capacity ||
                    eventIsFull ||
                    at.attributes.any_active_attendees_on_waitlist ? (
                      <Fragment>
                        {!freeEvent && (
                          <strike className="mr-4p">
                            {priceFormatter(
                              at.attributes.price_cents,
                              at.attributes.price_string,
                            )}
                          </strike>
                        )}
                        <span className="c-ruby">
                          {at.attributes.attendee_type_capacity_to_string}
                        </span>
                      </Fragment>
                    ) : (
                      <Fragment>
                        {!freeEvent && (
                          <Fragment>
                            <Fragment>
                              {at.attributes.different_price_later && (
                                <strike className="mr-4p">
                                  {priceFormatter(
                                    at.attributes.price_later,
                                    at.attributes.price_later_string,
                                  )}
                                </strike>
                              )}
                            </Fragment>
                            <Fragment>
                              {priceFormatter(
                                at.attributes.price_cents,
                                at.attributes.price_string,
                              )}
                            </Fragment>
                            <Fragment>
                              {at.attributes.different_price_later &&
                                ` until ${at.attributes.price_now_expires_at}`}
                            </Fragment>
                          </Fragment>
                        )}
                      </Fragment>
                    )}
                  </div>
                  <div className="fs-13 lh-1.5 c-tint2">
                    {!at.attributes.at_maximum_capacity &&
                      !eventIsFull &&
                      at.attributes.available_capacity &&
                      !at.attributes.any_active_attendees_on_waitlist && (
                        <span>
                          {`${at.attributes.constrained_capacity} `}
                          {registerForSeparateTimes ? "available" : "remaining"}
                        </span>
                      )}
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </div>
  ) : null
}

AttendeeTypes.propTypes = {
  attendeeTypes: attendeeTypesShape,
  currency: string,
  freeEvent: bool,
  eventIsFull: bool,
  registrationType: string,
  registerForSeparateTimes: bool,
}

const RegistrationButton = ({
  currentPerson,
  registeredForEvent,
  currentRegistrationUrl,
  newRegsitrationUrl,
  registrationState,
  atMaximumCapacity,
  waitlistAvailable,
  registrationAvailable,
  freeEvent,
  hasPublicAttendeeTypes = true,
}) => {
  let buttonLabel, registrationUrl, disabled
  const alreadyRegistered =
    currentPerson.meta.authenticated && registeredForEvent
  const registrationClosed = registrationState === "closed"
  const fullEvent = !registrationAvailable && !waitlistAvailable
  const waitlistIsAvailable = atMaximumCapacity && waitlistAvailable
  const notOnSale = !hasPublicAttendeeTypes

  if (alreadyRegistered) {
    buttonLabel = "View registration"
    registrationUrl = currentRegistrationUrl
  } else if (registrationClosed) {
    buttonLabel = "Registration closed"
    disabled = true
  } else if (fullEvent) {
    disabled = true
    buttonLabel = freeEvent ? "Full" : "Sold out"
  } else if (waitlistIsAvailable) {
    buttonLabel = "Waitlist available"
    registrationUrl = newRegsitrationUrl
  } else if (notOnSale) {
    buttonLabel = "Not on sale"
    disabled = true
  } else {
    buttonLabel = "Register"
    registrationUrl = newRegsitrationUrl
  }

  return (
    <div className="ta-r">
      <a className="btn" href={registrationUrl} disabled={disabled}>
        {buttonLabel}
      </a>
    </div>
  )
}

RegistrationButton.propTypes = {
  registeredForEvent: bool.isRequired,
  currentRegistrationUrl: string,
  newRegsitrationUrl: string.isRequired,
  registrationState: string.isRequired,
  currentPerson: currentPersonShape,
  atMaximumCapacity: bool.isRequired,
  waitlistAvailable: bool.isRequired,
  freeEvent: bool.isRequired,
  hasPublicAttendeeTypes: bool.isRequired,
  registrationAvailable: bool.isRequired,
}

export default Signup
