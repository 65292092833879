import { useState } from "react"
import { arrayOf, func } from "prop-types"
import { paymentMethodProps } from "../payment-method-prop-types"
import { css } from "@emotion/react"
import { PaymentMethodSelectorButton } from "./PaymentMethodSelectorButton"

export function SavedPaymentMethodSelector({
  paymentMethods,
  onPaymentMethodChange,
}) {
  const [selectedCard, setSelectedCard] = useState(null)

  const handlePaymentMethodChange = (paymentMethod) => {
    setSelectedCard(paymentMethod.id)
    onPaymentMethodChange(paymentMethod)
  }

  return (
    <div className="d-f fd-c g-1" css={paymentMethodStyles}>
      {paymentMethods.map((paymentMethod) => (
        <PaymentMethodSelectorButton
          key={paymentMethod.id}
          paymentMethod={paymentMethod}
          onClick={() => handlePaymentMethodChange(paymentMethod)}
          selected={selectedCard === paymentMethod.id}
        />
      ))}
    </div>
  )
}

SavedPaymentMethodSelector.propTypes = {
  paymentMethods: arrayOf(paymentMethodProps),
  onPaymentMethodChange: func,
}

const paymentMethodStyles = css`
  .paymentMethod--row {
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    border: 1px solid var(--color-tint9);
    background-color: var(--color-tint9);
    transition:
      background-color 100ms ease-in,
      border-color 100ms ease-in;
  }

  .paymentMethod--row:hover {
    cursor: pointer;
    background-color: var(--color-tint7);
    border-color: var(--color-tint7);
    transition:
      background-color 100ms ease-in,
      border-color 100ms ease-in;
  }

  .paymentMethod--row.selected {
    border-color: var(--color-topaz);
    background-color: hsl(207, 90%, 93%);
  }
`
