import { css } from "@emotion/react"
import { Icon } from "source/shared/components"
import { node } from "prop-types"

export function GenericConfidentialGroupCallout() {
  return (
    <ConfidentialGroupCallout>
      Member info for this group is only accessible to leaders and church staff
      with admin access, not other members.
    </ConfidentialGroupCallout>
  )
}

export function LeaderConfidentialGroupCallout() {
  return (
    <ConfidentialGroupCallout>
      As a leader, members are visible to you and church staff with admin
      access, but are not shown to other members.
    </ConfidentialGroupCallout>
  )
}

function ConfidentialGroupCallout({ children }) {
  return (
    <div css={confidentialStyles.container}>
      <div
        css={`
          margin-top: 2px;
        `}
      >
        <Icon symbol="general#hide-eye" css={confidentialStyles.header} />
      </div>
      <div>
        <div css={confidentialStyles.header}>Members are confidential</div>
        <div css={confidentialStyles.description}>{children}</div>
      </div>
    </div>
  )
}

const confidentialStyles = {
  container: css`
    display: flex;
    gap: 8px;
  `,
  header: css`
    color: var(--color-tint1);
    font-size: 16px;
    font-weight: 600;
  `,
  icon: css`
    color: var(--color-tint1);
    font-size: 16px;
    font-weight: 600;
    top: 4px;
  `,
  description: css`
    color: var(--color-tint2);
    font-size: 14px;
  `,
}

ConfidentialGroupCallout.propTypes = {
  children: node.isRequired,
}
