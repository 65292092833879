import { useContext, createContext, useState, useEffect, useRef } from "react"
import { useSearchParams } from "react-router-dom"
import moment from "moment"
import dayjs from "dayjs"
import PropTypes from "prop-types"
import { Grid, Button, Image } from "@planningcenter/doxy-web"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import { useQuery } from "@tanstack/react-query"
import { Menu, MenuButton, MenuList, MenuItem } from "@reach/menu-button"
import { useDebounce } from "source/shared/hooks/useDebounce"
import microphone from "source/svg/microphone.svg"

const FILTER_TYPES = ["speaker", "series", "date"]
const areFiltersActive = (searchParams) => {
  return FILTER_TYPES.some((param) => searchParams.has(param))
}

EpisodeFilterPanel.propTypes = {
  channelId: PropTypes.string.isRequired,
  episodeCount: PropTypes.number.isRequired,
}

export function EpisodeFilterPanel({ channelId, episodeCount }) {
  const [searchParams, setSearchParams] = useSearchParams()

  const filtersActive = areFiltersActive(searchParams)

  const channelHasNoEpisodes = episodeCount === 0 && !filtersActive

  const resetFilters = (e) => {
    e.preventDefault()
    setSearchParams({})
  }

  return (
    <div className="d-f fd-c ai-c g-5 as-s">
      {!channelHasNoEpisodes && (
        <div className="d-f as-s p-3 fd-c jc-c ai-s g-1 gc-tint9 br-4p">
          <SelectFilters channelId={channelId} />
        </div>
      )}
      {episodeCount === 0 && (
        <EmptyStateDisplay
          onClick={resetFilters}
          filtersActive={filtersActive}
        />
      )}
    </div>
  )
}

const FilterContext = createContext()

SelectFilters.propTypes = {
  channelId: PropTypes.string.isRequired,
}

function SelectFilters({ channelId }) {
  const { series, isLoading: seriesLoading } =
    useSeriesWithPublishedEpisodes(channelId)
  const { speakers, isLoading: speakersLoading } =
    useSpeakersforChannel(channelId)
  const { earliestYear, isLoading: yearLoading } =
    useEarliestYearForChannelEpisodes(channelId)
  const dateOptions = buildDateOptions(earliestYear)
  const loadingStatuses = {
    series: seriesLoading,
    speaker: speakersLoading,
    date: yearLoading,
  }

  const options = {
    series: [
      { id: "", name: "All series" },
      ...series.map((s) => ({
        id: s.id,
        name: s.attributes.title,
        start: s.attributes.started_at,
        end: s.attributes.ended_at,
      })),
    ],
    speaker: [
      { id: "", name: "All speakers" },
      ...speakers.map((s) => ({
        id: s.id,
        name: s.attributes.formatted_name,
        type: s.attributes.speaker_type,
      })),
    ],
    date: dateOptions,
  }

  function shouldShowFilter(filterType) {
    const alwaysDisplayedFilters = ["date"]
    return (
      alwaysDisplayedFilters.includes(filterType) ||
      (!loadingStatuses[filterType] && options[filterType].length > 1)
    )
  }

  const filterComponentMap = {
    speaker: FilterSingleSelect,
    series: FilterSingleSelect,
    date: DateFilter,
  }

  const filterContextValues = { options }

  return (
    <FilterContext.Provider value={filterContextValues}>
      <FilterGrid>
        {FILTER_TYPES.map((filterType) => {
          const FilterComponent = filterComponentMap[filterType]

          return (
            shouldShowFilter(filterType) && (
              <FilterComponent key={filterType} filterType={filterType} />
            )
          )
        })}
      </FilterGrid>
    </FilterContext.Provider>
  )
}

FilterGrid.propTypes = {
  children: PropTypes.node,
}

function FilterGrid({ children }) {
  return (
    <Grid columns={[1, { xs: 2, md: 3 }]} gap={2}>
      {children}
    </Grid>
  )
}

function useTitle(filterType, selectedItemId) {
  const { options } = useContext(FilterContext)

  if (["series", "speaker"].includes(filterType)) {
    const selectedItem = options[filterType].find(
      (option) => option.id === selectedItemId,
    )

    return selectedItem?.name || "Record Not Available"
  }

  if (filterType === "date") {
    const [year, month] = selectedItemId.split("-")
    if (!year) return "All dates"

    return month
      ? `${dayjs(`${year}-${month}-01`).format("MMMM")} ${year}`
      : year
  }
}

MenuTitle.propTypes = {
  filterType: PropTypes.string,
  selectedItemId: PropTypes.string,
}

function MenuTitle({ filterType, selectedItemId }) {
  const title = useTitle(filterType, selectedItemId)

  return <MenuButton className="select truncate ta-l">{title}</MenuButton>
}

function formatSeriesDates(startDate, endDate) {
  if (!startDate || !endDate) return null

  const format = (date) => moment(date).format("MMMM YYYY")

  return `${format(startDate)} - ${format(endDate)}`
}

FilterSingleSelect.propTypes = {
  filterType: PropTypes.string,
}

function FilterSingleSelect({ filterType }) {
  const { options } = useContext(FilterContext)
  const menuOptions = options[filterType]
  const [searchParams, setSearchParams] = useSearchParams()
  const selectedItemId = searchParams.get(filterType) || ""
  const [seriesSearchQuery, setSeriesSearchQuery] = useState("")
  const debouncedSeriesSearchQuery = useDebounce(seriesSearchQuery, 300)
  const searchInputRef = useRef(null)
  const onChange = (option) => {
    setSearchParams(
      (prev) => {
        if (option.id !== "") {
          prev.set(filterType, option.id)
          if (filterType === "speaker") {
            prev.set("speaker_type", option.type)
          }
        } else {
          prev.delete(filterType)
          if (filterType === "speaker") {
            prev.delete("speaker_type", option.type)
          }
        }
        return prev
      },
      { replace: true },
    )
  }

  const filteredMenuOptions = menuOptions.filter((option) =>
    option.name
      ?.toLowerCase()
      ?.includes(debouncedSeriesSearchQuery.toLowerCase()),
  )

  useEffect(() => {
    if (searchInputRef.current) {
      const input = searchInputRef.current
      const cursorPosition = input.selectionStart
      input.focus()
      input.setSelectionRange(cursorPosition, cursorPosition)
    }
  }, [debouncedSeriesSearchQuery, filteredMenuOptions])

  const handleKeyDown = (e) => {
    const allowedKeys = ["ArrowUp", "ArrowDown"]
    if (!allowedKeys.includes(e.key)) {
      e.stopPropagation()
    }
  }

  return (
    <Menu>
      <MenuTitle
        filterType={filterType}
        selectedItemId={selectedItemId}
      ></MenuTitle>
      <MenuList
        className="d-f fd-c jc-c ai-fs"
        style={{
          maxHeight: "456px",
          overflowY: "auto",
          boxShadow: "0px 11px 20px 0px rgba(75, 76, 78, 0.25)",
        }}
      >
        {filterType === "series" && (
          <div className="d-f p-1 m-4p fd-c as-s ai-c">
            <input
              type="text"
              placeholder="Search series"
              value={seriesSearchQuery}
              onChange={(e) => setSeriesSearchQuery(e.target.value)}
              onKeyDown={handleKeyDown}
              className="text-input sm-input"
              ref={searchInputRef}
              style={{
                paddingLeft: 32,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "8px center",
                backgroundImage: `url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M14 12.5377L10.7814 9.3996C11.5514 8.414 12.0134 7.1897 12.0134 5.859C12.0134 2.6285 9.3191 0 6.0074 0C2.695 0 0 2.6285 0 5.859C0 9.0895 2.695 11.718 6.0074 11.718C7.1897 11.718 8.2901 11.3785 9.2211 10.801L12.5006 14L14 12.5377ZM1.414 5.859C1.414 3.3887 3.4741 1.379 6.0074 1.379C8.5393 1.379 10.6008 3.3887 10.6008 5.859C10.6008 8.3293 8.5393 10.339 6.0074 10.339C3.4741 10.339 1.414 8.3293 1.414 5.859Z' fill='%23737373'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M14 12.5377L10.7814 9.3996C11.5514 8.414 12.0134 7.1897 12.0134 5.859C12.0134 2.6285 9.3191 0 6.0074 0C2.695 0 0 2.6285 0 5.859C0 9.0895 2.695 11.718 6.0074 11.718C7.1897 11.718 8.2901 11.3785 9.2211 10.801L12.5006 14L14 12.5377ZM1.414 5.859C1.414 3.3887 3.4741 1.379 6.0074 1.379C8.5393 1.379 10.6008 3.3887 10.6008 5.859C10.6008 8.3293 8.5393 10.339 6.0074 10.339C3.4741 10.339 1.414 8.3293 1.414 5.859Z' fill='%23737373'/%3E%3C/svg%3E%0A")`,
              }}
            />
          </div>
        )}
        {(filterType === "series" ? filteredMenuOptions : menuOptions).map(
          (option) => (
            <MenuItem
              key={`${filterType}-${option.id}`}
              onSelect={() => onChange(option)}
              className="d-f fd-c jc-c as-s ai-fs"
            >
              <div>{option.name}</div>
              {filterType === "series" && (
                <div className="fs-5 c-tint2">
                  {formatSeriesDates(option.start, option.end)}
                </div>
              )}
            </MenuItem>
          ),
        )}
      </MenuList>
    </Menu>
  )
}

function DateFilter() {
  const { options } = useContext(FilterContext)
  const menuOptions = options["date"]
  const [searchParams, setSearchParams] = useSearchParams()
  const selectedItemId = searchParams.get("date") || ""
  const onChange = (option) => {
    setSearchParams(
      (prev) => {
        if (option.year === "All Years") {
          prev.delete("date")
        } else if (option.month === "") {
          prev.set("date", option.year)
        } else {
          prev.set("date", `${option.year}-${option.month}`)
        }
        return prev
      },
      { replace: true },
    )
  }

  return (
    <Menu>
      <MenuTitle filterType="date" selectedItemId={selectedItemId}></MenuTitle>
      <MenuList
        className="d-f fd-c jc-fs ai-l g-1"
        style={{
          maxHeight: "456px",
          overflowY: "auto",
          overflowX: "hidden",
          boxShadow: "0px 11px 20px 0px rgba(75, 76, 78, 0.25)",
        }}
      >
        <MenuItem onSelect={() => onChange({ year: "All Years" })}>
          All dates
        </MenuItem>
        {menuOptions.map((option) => (
          <div key={option.year}>
            <h3 className="c-tint1 fs-4 mx-1 pl-4p pr-4p">{option.year}</h3>
            {option.months.map((month) => (
              <MenuItem
                key={month.id}
                onSelect={() =>
                  onChange({ year: option.year, month: month.id })
                }
              >
                {month.name}
              </MenuItem>
            ))}
          </div>
        ))}
      </MenuList>
    </Menu>
  )
}

EmptyStateDisplay.propTypes = {
  onClick: PropTypes.func,
  filtersActive: PropTypes.bool,
}

function EmptyStateDisplay({ onClick, filtersActive }) {
  return (
    <div className="d-f fd-c ai-c g-3 as-s">
      <div
        style={{
          width: "240px",
          height: "184px",
        }}
      >
        <Image alt="microphone" aspectRatio="auto" src={microphone} />
      </div>
      <div
        className="d-f fd-c jc-c ai-c g-2"
        style={{ maxWidth: "400px", width: "100%" }}
      >
        <text className="c-tint2 fs-3 fs-n fw-600">No episodes found</text>
        {filtersActive && (
          <Button
            onClick={onClick}
            size="md"
            text="Reset filters"
            theme="primary"
            type="button"
            variant="outline"
          />
        )}
      </div>
    </div>
  )
}

function useSeriesWithPublishedEpisodes(channelId) {
  const { data, isLoading } = useQuery({
    queryKey: [
      "channels",
      channelId,
      "series",
      "published,with_published_episodes",
    ],
    queryFn: ({ queryKey }) => {
      const [_, channelId] = queryKey
      return sessionApiClient
        .get(`/publishing/v2/channels/${channelId}/series`, {
          filter: "published,with_published_episodes",
          per_page: 1000,
        })
        .catch((json) => json)
    },
    select: (response) => response.data,
  })
  return { series: data || [], isLoading }
}

function useSpeakersforChannel(channelId) {
  const { data, isLoading } = useQuery({
    queryKey: ["channels", channelId, "speakers"],
    queryFn: ({ queryKey }) => {
      const [_, channelId] = queryKey
      return sessionApiClient
        .get(`/publishing/v2/channels/${channelId}/speakers`, {
          per_page: 1000,
        })
        .catch((json) => json)
    },
    select: (response) => response.data,
  })
  return { speakers: data || [], isLoading }
}

function useEarliestYearForChannelEpisodes(channelId) {
  const queryKey = [
    `/publishing/v2/channels/${channelId}/episodes`,
    {
      "fields[Episode]": "published_to_library_at",
      filter: "published,published_to_library",
      order: "published_to_library_at",
      per_page: 1,
    },
  ]
  const { data, isLoading } = useQuery({
    queryKey: queryKey,
    throwOnError: false,
    refetchOnWindowFocus: false,
    select: (response) => {
      if (!response.data.length) return null
      return new Date(
        response.data[0].attributes.published_to_library_at,
      ).getFullYear()
    },
  })
  return { earliestYear: data, isLoading }
}

function buildDateOptions(earliestYear) {
  const months = [
    { id: "", name: "All Months" },
    { id: "01", name: "January" },
    { id: "02", name: "February" },
    { id: "03", name: "March" },
    { id: "04", name: "April" },
    { id: "05", name: "May" },
    { id: "06", name: "June" },
    { id: "07", name: "July" },
    { id: "08", name: "August" },
    { id: "09", name: "September" },
    { id: "10", name: "October" },
    { id: "11", name: "November" },
    { id: "12", name: "December" },
  ]
  const currentMonth = new Date().getMonth() + 1
  const currentYear = new Date().getFullYear()
  const currentYearMonths = months.slice(0, currentMonth + 1)

  const buildYearsArray = (startYear, endYear) => {
    if (!startYear) return [currentYear]

    return Array.from(
      { length: endYear - startYear + 1 },
      (_, i) => startYear + i,
    ).sort((a, b) => b - a)
  }

  const allYears = buildYearsArray(earliestYear, currentYear)

  return allYears.map((year) => {
    const yearwithMonths = {}
    yearwithMonths["year"] = year
    yearwithMonths["months"] = year == currentYear ? currentYearMonths : months
    return yearwithMonths
  })
}
