export const fakeUnifiedPaymentObjects = [
  {
    id: "pm_123",
    methodType: "us_bank_account",
    methodSubtype: "checking",
    methodSupertype: "bank_account",
    last4: "6789",
    brand: "STRIPE TEST BANK",
    expiration: null,
    isVerified: false,
    verificationMethod: undefined,
    holderName: "PiCO Das Robot",
    createdAt: "2025-02-27T18:48:43Z",
  },
  {
    id: "pm_456",
    methodType: "card",
    methodSubtype: "credit",
    methodSupertype: "card",
    last4: "4242",
    brand: "Visa",
    expiration: "10/2027",
    isVerified: true,
    verificationMethod: undefined,
    holderName: "PiCO Das Robot",
    createdAt: "2025-02-26T18:48:43Z",
  },
  {
    id: "pm_789",
    methodType: "card",
    methodSubtype: "credit",
    methodSupertype: "card",
    last4: "0204",
    brand: "Mastercard",
    expiration: "12/2026",
    isVerified: true,
    verificationMethod: undefined,
    holderName: "PiCO Das Robot",
    createdAt: "2025-02-25T18:48:43Z",
  },
  {
    id: "pm_987",
    methodType: "card",
    methodSubtype: "credit",
    methodSupertype: "card",
    last4: "5678",
    brand: "American Express",
    expiration: "07/2027",
    isVerified: true,
    verificationMethod: undefined,
    holderName: "PiCO Das Robot",
    createdAt: "2025-02-25T18:48:43Z",
  },
]
