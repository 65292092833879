import { useState } from "react"
import { func, string } from "prop-types"
import { useNavigate } from "react-router-dom"
import { CancelButton } from "source/groups/components/CancelButton"

export function EventNoteForm({
  destroy,
  initialBody = "",
  returnTo,
  save,
  textareaLabel,
}) {
  const navigate = useNavigate()

  const [body, setBody] = useState(initialBody)

  const saveForm = (e) => {
    e.preventDefault()

    const payload = { data: { attributes: { body } } }
    save(payload)
      .then(() => navigate(returnTo))
      .catch(() => alert("Uh oh, something went wrong. Please try again."))
  }

  const formFooterClasses = destroy
    ? "d-f jc-sb ai-c mb-2 mt-2"
    : "d-f jc-sb@md ai-c jc-c mb-2 mt-2"

  return (
    <form onSubmit={saveForm} className="mt-2">
      <label className="label" htmlFor="event_note_body">
        {textareaLabel}
      </label>
      <textarea
        id="event_note_body"
        className="w-100%"
        rows={4}
        value={body}
        onChange={(e) => setBody(e.target.value)}
        placeholder="Share with other leaders & your group administrators how the event went."
      />
      <div className={formFooterClasses}>
        <div>
          {destroy && (
            <button className="btn destroy-btn" onClick={destroy} type="button">
              Delete...
            </button>
          )}
        </div>
        <div className="d-f ai-c">
          <CancelButton
            customStyles="m-1"
            handleCancel={(e) => {
              e.preventDefault()
              return navigate(returnTo)
            }}
          />
          <button type="submit" className="btn" disabled={!body}>
            Save
          </button>
        </div>
      </div>
    </form>
  )
}
EventNoteForm.propTypes = {
  destroy: func,
  initialBody: string,
  returnTo: string.isRequired,
  save: func.isRequired,
  textareaLabel: string,
}
